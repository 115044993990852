import { i18n, forageInstance, LOCALE_ALL } from "../src/i18nEntry.js"

let localeCached = false
let executed = false

// 轮询国际化
async function pollLocale() {
  await new Promise((resolve) => {
    const locale = localStorage.getItem("locale") || "EN"
    let interval = null
    const fn = () => {
      const localeMsg = window[`locale_${locale}`]
      if (localeMsg && Object.keys(localeMsg).length > 0) {
        if (interval) {
          clearInterval(interval)
          interval = null
        }
        // i18n.global.locale = locale
        if (Object.keys(i18n.global.getLocaleMessage("pacvue-i18n")).length === 0) {
          i18n.global.setLocaleMessage("pacvue-i18n", localeMsg)
        }
        // 10s 后全量缓存进 IndexDB
        if (!localeCached) {
          localeCached = true
          setTimeout(() => {
            forageInstance.setItem(locale, localeMsg)
            const localeOthers = LOCALE_ALL.filter((item) => item !== locale)
            for (const item of localeOthers) {
              if (window[`locale_${item}`] && Object.keys(window[`locale_${item}`]).length > 0) {
                forageInstance.setItem(item, window[`locale_${item}`])
              }
            }
          }, 10000)
        }
        resolve(true)
      }
    }
    fn()
    interval = setInterval(fn, 50)
    // 超时则使用 IndexDB 中缓存的值, 以防阻塞
    setTimeout(async () => {
      if (interval) {
        clearInterval(interval)
        interval = null
        const localeMsg = await forageInstance.getItem(locale)
        // i18n.global.locale = locale
        // 若缓存中还是没有, 只能无可奈何 fallback 到全 key.
        i18n.global.setLocaleMessage("pacvue-i18n", localeMsg || {})
        resolve(true)
      }
    }, 8000)
  })
}

// 配合异步事件发送的新国际化轮询缓存策略
const cacheIntoIndexDB = async () => {
  if (!localeCached) {
    localeCached = true
    setTimeout(() => {
      for (const lang of LOCALE_ALL) {
        if (window[`locale_${lang}`] && Object.keys(window[`locale_${lang}`]).length > 0) {
          forageInstance.setItem(lang, window[`locale_${lang}`])
        }
      }
    }, 10000)
  }
}

const pollAndCacheLocaleNew = () => {
  return new Promise((resolve, reject) => {
    if (!executed) {
      executed = true
      const locale = window.locale || localStorage.getItem("locale") || "EN"
      // 若 7s 后仍未加载完毕, 则使用缓存的国际化映射读入
      setTimeout(async () => {
        if (!window.localeLoad) {
          const localeMsg = await forageInstance.getItem(locale)
          // 若 IndexedDB 缓存中还是没有, 则仍等待 CDN 加载完毕
          if (Object.keys(localeMsg || {}).length > 0) {
            i18n.global.setLocaleMessage("pacvue-i18n", localeMsg)
            resolve(true)
          }
        }
      }, 5000)
      if (!window.localeLoad) {
        // 若国际化文件尚未加载完毕, 则开启对 window localeLoad 事件的监听
        window.addEventListener("localeLoad", () => {
          i18n.global.setLocaleMessage("pacvue-i18n", window[`locale_${locale}`])
          cacheIntoIndexDB()
          resolve(true)
        })
      } else {
        i18n.global.setLocaleMessage("pacvue-i18n", window[`locale_${locale}`])
        // 此时国际化文件已加载完毕, 立刻开启往 IndexedDB 缓存的定时器
        cacheIntoIndexDB()
        resolve(true)
      }
    } else {
      resolve(true)
    }
  })
}

export { pollLocale, pollAndCacheLocaleNew }
