import { defineAsyncComponent } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { app } from "./frame"

export default (route, isRoot, suffix) => {
  function enumerateFun(data) {
    data.forEach((element) => {
      if (typeof element.component === "function") {
        // element.component = element.component()
        // element.component = defineAsyncComponent({
        //   loader: element.component
        //   // // 加载异步组件时使用的组件
        //   // loadingComponent: loadingComponent <template>loading</template>,
        //   // // 展示加载组件前的延迟时间，默认为 200ms
        //   // delay: 200
        // })
        //上述防范不推荐 可以使用promise解决相关问题
      }
      if (Array.isArray(element.children)) {
        enumerateFun(element.children)
      }
    })
  }
  if (!Array.isArray(route)) {
    if (typeof route.component === "function") {
      // route.component = defineAsyncComponent({
      //   loader: route.component
      // })
      //上述防范不推荐 可以使用promise解决相关问题
    }
    if (Array.isArray(route.children)) {
      enumerateFun(route.children)
    }
  } else {
    enumerateFun(route)
  }
  if (isRoot) {
    const router = createRouter({
      history: suffix ? createWebHistory(suffix) : createWebHistory(),
      routes: route
      // sensitive: true
    })
    router.addRoute({
      path: "/:pathMatch(.*)",
      redirect: "/"
    })
    app.config.globalProperties.router = router
    window.routerObj = router
    return router
  } else {
    return route
  }
}
