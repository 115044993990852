import { defineStore } from "pinia"
export function strCapitalize(str) {
  str = str ? str : ""
  if (Object.prototype.toString.call(str) == "[object String]") {
    str = str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}
export function useStoreModule(modules) {
  var storeModule = {}
  //生成module以use+模块名称+'Store'
  for (var [key, module] of Object.entries(modules)) {
    var id = "use" + strCapitalize(key) + "Store"
    storeModule[id] = defineStore(key, module)
  }
  return storeModule
}
export function getUseStoreKey(moduleName) {
  return "use" + strCapitalize(moduleName) + "Store"
}
