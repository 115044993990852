import Main from "@pacvue/frame/layout/Main.vue"
export default {
  router: {
    path: "/Budget",
    component: Main,
    redirect: "/Budget/BudgetDashboard",
    children: [
      {
        path: "/Budget/BudgetDashboard",
        component: () => import("@BudgetManager/index.vue"),
        name: "Budget Manager",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/BudgetManager",
        component: () => import("@BudgetManager/BudgetSetting.vue"),
        name: "Budget Setting",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/BudgetPreview",
        component: () => import("@BudgetManager/BudgetDashboard.vue"),
        name: "Budget Dashboard",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/CalendarTemplateManager",
        component: () => import("@BudgetManager/CalendarTemplateManager.vue"),
        name: "Calendar Template Manager",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/ManagedCampaignsSelf",
        component: () => import("@BudgetManager/ManagedCampaignsSelf.vue"),
        name: "Managed Campaigns",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/OperationLog",
        component: () => import("@BudgetManager/OperationLog.vue"),
        name: "Operation Log",
        meta: {
          isShowTime: true,
          alias: "Budget"
        }
      },
      {
        path: "/Budget/BulkOperations",
        component: () => import("@BudgetManager/budgetBulkOperations/index.vue"),
        name: "Bulk Operations",
        meta: { alias: "Budget" }
      },
      {
        path: "/Budget/BulkOperationsLog",
        component: () => import("@BudgetManager/budgetBulkOperations/log.vue"),
        name: "Bulk Operations Log",
        meta: {
          parent: "Bulk Operations",
          link: "/Budget/BulkOperations",
          showCompareCheck: true,
          alias: "Budget"
        }
      },
      {
        path: "/Budget/CreateBudget",
        component: () => import("@BudgetManager/CreateBudgetManager.vue"),
        name: "Creating Budget Manager",
        meta: { alias: "Budget" }
      }
    ]
  }
}
