import { app } from "./frame.js"
import { PacvueNotify } from "@pacvue/element-plus"
import { renderContent, goDownload } from "@Message/components/notifyMessage.jsx"
import { useCommonStore, useUserStore } from "@/store"
import config from "@~/config.js"
import { LOCALIZED_PLATFORMS } from "../constants/platform.js"
import { productLineHandler } from "../utils/special-logic.js"

let productLine = localStorage.getItem("productline")
const { VITE_APP_PULLMESSAGE } = import.meta.env
let ws = VITE_APP_PULLMESSAGE
let listDeWeightUuid = []
window.socketErrCount = 0

function webSocketOnError(e) {
  console.log(e)
}
function webSocketOnMessage(e) {
  try {
    if (!JSON.parse(e.data).data) {
      return
    }
  } catch (err) {
    return
  }
  let msgRes = JSON.parse(e.data).data.message
  if (!msgRes) return

  const dataDownloadStartList = ["Data Download Start", $t("Data Download Start")]
  if (Array.isArray(msgRes)) {
    const listWithoutDataDownloadStart = []
    msgRes.forEach((item) => {
      if (["Data Download", $t("Data Download")].includes(item.caseType)) {
        app.config.globalProperties.$eventHub.emit("customDataDownload", item)
      }
      if (!dataDownloadStartList.includes(item.detail)) {
        listWithoutDataDownloadStart.push(item)
      }
    })
    msgRes = listWithoutDataDownloadStart // 忽略掉没有 Data Download Start 的列表
    if (msgRes.length > 5) {
      msgRes = msgRes.splice(0, 5)
    }
    msgRes.forEach((item) => {
      setTimeout(() => {
        PacvueNotify({
          title: $t(item.type),
          message: renderContent(item),
          pacType: dataDownloadStartList.includes(item.detail) ? "" : item.message.toLowerCase().indexOf("error") !== -1 || item.message.toLowerCase().indexOf("failed") !== -1 ? "error" : "success",
          icon: "PacvueIconLoading"
        })
      }, 300)
    })
  } else {
    if (["Data Download", $t("Data Download")].includes(msgRes.caseType)) {
      app.config.globalProperties.$eventHub.emit("customDataDownload", msgRes)
    }
    if (!dataDownloadStartList.includes(msgRes.detail)) {
      if (msgRes.uuid) {
        if (!listDeWeightUuid.includes(msgRes.uuid)) {
          showPacvueNotify(msgRes)
        }
        listDeWeightUuid.push(msgRes.uuid)
      } else {
        showPacvueNotify(msgRes)
      }
    }
  }
}

function showPacvueNotify(msgRes) {
  const commonStore = useCommonStore()
  const userStore = useUserStore()
  const autoDownload = (taskId) => {
    downloadTaskCreator(msgRes)
    commonStore.removeDownloadTaskId(taskId)
  }
  PacvueNotify({
    title: $t(msgRes.type),
    message: renderContent(msgRes),
    pacType: msgRes.succeed || msgRes.succeed === null ? "success" : "error",
    icon: "PacvueIconLoading"
  })
  // 用户设置了自动下载，目前仅commerce平台
  if (userStore.userSettings?.autoDownload && productLine === "commerce" && msgRes.type === "Data Download") {
    let taskId = msgRes.message.split("taskId=")
    // 中台特殊情况下消息推送会比下载接口返回的还快，小概率情况下延迟1s再次检查
    if (commonStore.downloadTaskIdList.includes(taskId[1])) {
      autoDownload(taskId[1])
    } else {
      setTimeout(() => {
        if (commonStore.downloadTaskIdList.includes(taskId[1])) {
          autoDownload(taskId[1])
        }
      }, 1000)
    }
  }
}

function downloadTaskCreator(item) {
  let slicingMessage = item.message.split("\n")
  let downloadList = slicingMessage[2] ? String(slicingMessage[2])?.split("downloadUrl=") : ["", ""]
  if (downloadList[1]) goDownload(item.fileName, downloadList[1])
}
// 关闭 web socket
let socketTimeout = null
function webSocketOnClose() {
  console.log("Msg 长连接已关闭...")
  window.pullSocket = null
  // todo 一旦失败 需要重新连接 每个5s尝试一次 一共尝试三次
  window.socketErrCount++
  // if(window.socketErrCount>3) return
  socketTimeout = setTimeout(() => {
    initwebSocket()
  }, 15000)
}

function webSocketOnOpen() {
  console.log("开启 Msg 长连接...")
}

export function initwebSocket() {
  if (!config.Message || window.pullSocket || !ws) {
    return
  }
  if (!LOCALIZED_PLATFORMS.includes(productLine)) {
    localStorage.setItem("locale", "EN")
  }
  if (!localStorage.getItem("Pacvue_token") || localStorage.getItem("Pacvue_token") == "undefined") return
  let token = null
  try {
    token = JSON.parse(localStorage.getItem("Pacvue_token")).access_token
  } catch (ex) {
    return
  }
  const webClientId = localStorage.getItem("web_client_id")
  const webClientIdStr = webClientId ? `&WebClientId=${webClientId}` : ""
  const commonStore = useCommonStore()
  productLine = productLineHandler({ commonStoreContext: commonStore, productLine })
  let socket = new WebSocket(ws + `?Language=${localStorage.getItem("locale")}&ProductLine=${productLine === "dsp" ? "amazondsp" : productLine}${webClientIdStr}`, [token.substring(2, token.length)])
  socket.onerror = webSocketOnError
  socket.onmessage = webSocketOnMessage
  socket.onclose = webSocketOnClose
  socket.onopen = webSocketOnOpen
  window.pullSocket = socket
}

// hidden（当浏览器最小化、切换tab、电脑锁屏时） visible（用户正在查看当前页面时） prerender（文档加载离屏或者不可见） unloaded（当文档将要被unload时）
document.addEventListener("visibilitychange", function () {
  const isHidden = document.hidden
  if (isHidden) {
    // 切离该页面时执行
    clearTimeout(socketTimeout)
    window.socketErrCount = 3
    if (window.pullSocket) {
      window.pullSocket.close()
      window.pullSocket = null
    }
  } else {
    // 切换到该页面时执行
    // 页面回来时的逻辑处理，此处是进行了刷新
    // location.reload(); //刷新页面
    let token = localStorage.getItem("Pacvue_token")
    if (token) {
      initwebSocket()
    }
  }
})
