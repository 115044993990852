import { useCommonStore } from "@/store"
import { DOMAIN_MAPPER_PROD } from "../../constants/platform"
// import { productLineHandler } from "../../utils/special-logic"
import config from "@~/config.js"

const productLine = localStorage.getItem("productline") || "amazon"
const productLineHandler = ({ platformAlias, productLine } = {}) => {
  return platformAlias || productLine
}
const _productLine = productLineHandler({ platformAlias: config?.platformAlias, productLine })

export default {
  oidc: {
    clientId: "0oaj7atg8fTGeRGiW697",
    issuer: "https://withassembly.okta.com",
    redirectUri: `${window.location.origin}/${DOMAIN_MAPPER_PROD[_productLine]?.pathPrefix || ""}${_productLine === "amazon" ? "callback" : "/callback"}`,
    // redirectUri: `http://localhost:3000/callback`,
    scopes: ["openid", "offline_access", "profile", "email"],
    pkce: true // 启用 PKCE
  }
}
