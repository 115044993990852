import request from "@pacvue/frame/request"
const { VITE_APP_BASEURL, VITE_APP_AUTOMATION } = import.meta.env
export function GetInteractPortfolio(data) {
  return request({
    url: VITE_APP_BASEURL + "Explorer/v3/GetInteractPortfolio",
    data,
    method: "post"
  })
}
export function GetCampaignExplorerData(data) {
  return request({
    url: VITE_APP_BASEURL + "CampaignExplorer/v3/GetCampaignExplorerData",
    data,
    method: "post"
  })
}
export function GetCampaignTagExplorerData(data) {
  return request({
    url: VITE_APP_BASEURL + "CampaignExplorer/v3/GetCampaignTagExplorerData",
    data,
    method: "post"
  })
}

export function GetProfileDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "Explorer/v3/GetProfileDetail",
    data,
    method: "post"
  })
}
export function GetPortfolioDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "Explorer/v3/GetPortfolioDetail",
    data,
    method: "post"
  })
}
export function GetExplorerCampaignDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "Explorer/v3/GetExplorerCampaignDetail",
    data,
    method: "post"
  })
}
export function GetExplorerAdgroupDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "Explorer/v3/GetExplorerAdgroupDetail",
    data,
    method: "post"
  })
}
export function GetCampaignTagExplorerDetailData(data) {
  return request({
    url: VITE_APP_BASEURL + "CampaignExplorer/v3/GetCampaignTagExplorerDetailData",
    data,
    method: "post"
  })
}

export function GetHourlyTableCampaignTypeData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableCampaignTypeData",
    data,
    method: "post"
  })
}
export function GetHourlyTableCampaignData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableCampaignData",
    data,
    method: "post"
  })
}
export function GetHourlyTableCampaignAdgroupData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableCampaignAdgroupData",
    data,
    method: "post"
  })
}
export function GetHourlyTablePlacementData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTablePlacementData",
    data,
    method: "post"
  })
}
export function GetHourlyTableAsinData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableAsinData",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordCampaignTypeData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordCampaignTypeData",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordTotal(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordTotal",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordCampaignData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordCampaignData",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordCampaignAdgroupData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordCampaignAdgroupData",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordPlacementData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordPlacementData",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordAsinData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordAsinData",
    data,
    method: "post"
  })
}
export function SearchProductAdForAsinSku(data) {
  return request({
    url: VITE_APP_BASEURL + "Asin/SearchProductAdForAsinSku",
    data,
    method: "post"
  })
}
export function SearchKeyword(data) {
  return request({
    url: VITE_APP_BASEURL + "v3/Keyword/SearchKeyword",
    data,
    method: "post"
  })
}
export function GetHourlyKeywordTrendChart(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyKeywordTrendChart",
    data,
    method: "post"
  })
}
export function GetHourlyTargetData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTargetData",
    data,
    method: "post"
  })
}
export function GetHourlyTableTargetData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableTargetData",
    data,
    method: "post"
  })
}
export function SaveCostControl(data) {
  return request({
    url: VITE_APP_BASEURL + "v3/OptimizationRule/SaveCostControl",
    data,
    method: "post"
  })
}
export function ListAutomationNegativeByCampaignTag(data) {
  return request({
    url: VITE_APP_BASEURL + `NegativeKeywordList/v3/ListAutomationNegativeByCampaignTag/${data}`,
    method: "GET"
  })
}
export function GetAutomationAutoTagging(data) {
  return request({
    url: VITE_APP_BASEURL + `CampaignTag/v3/GetAutomationAutoTagging/${data}`,
    method: "GET"
  })
}
export function GetCampaignExploreAutomation(data) {
  return request({
    url: VITE_APP_BASEURL + "NegativeKeywordList/v3/GetCampaignExploreAutomation",
    data,
    method: "post"
  })
}
export function GetHourlyCampaignAdgroupData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyCampaignAdgroupData",
    data,
    method: "post"
  })
}
export function GetHourlyTableAdgroupData(data) {
  return request({
    url: VITE_APP_BASEURL + "HourlyExplorer/GetHourlyTableAdgroupData",
    data,
    method: "post"
  })
}

export function GetTagAutomation(data) {
  return request({
    url: VITE_APP_AUTOMATION + "/automation/tag/applied",
    data,
    method: "post"
  })
}
export function GetCampaignAutomation(data) {
  return request({
    url: VITE_APP_AUTOMATION + "/automation/campaign/applied",
    data,
    method: "post"
  })
}
