// 需要埋点的页面名称
export const ANALYTICS_PAGELIST = [
  "View Login Screen",
  "Login Success",
  "Login Fail",
  "Logout Success",
  "Switch To Other Platforms Success",
  "Switch To Other Platforms Fail",
  "Authorize ads profile",
  "Authorize sp-api",
  "Manage Profile Success",
  "Cancel Manage Profile",
  "Unmanage Profile Success",
  "Cancel unmanage Profile",
  "Home",
  "PC-Dashboard",
  "PC-Listing",
  "PC-Order",
  "PC-Latitude",
  "PC-Unauthorized Page",
  "BM-List",
  "BM-Dashboard",
  "BM-Settings",
  "BM-Calendar Manager",
  "BM-Operation Log",
  "Advertising-Explorer",
  "Advertising-profile",
  "Advertising-profile details",
  "Advertising-Campaign tag",
  "Advertising-Campaign tag details",
  "Advertising-keyword tag",
  "Advertising-keyword tag details",
  "Advertising-asin tag",
  "Advertising-asin tag details",
  "Advertising-portfolio",
  "Advertising-portfolio details",
  "Advertising-campaign",
  "Advertising-campaign hourly data",
  "Advertising-campaign details",
  "Advertising-campaign auto target",
  "Advertising-adgroup",
  "Advertising-adgroup details",
  "Advertising-adgroup SB ads",
  "Advertising-SB ads details",
  "Advertising-SB ads creative",
  "Advertising-Targeting",
  "Advertising-Targeting details",
  "Advertising-ASIN",
  "Advertising-ASIN details",
  "Advertising-ST",
  "Advertising-ST details",
  "Advertising-NG Targeting",
  "Advertising-NG List",
  "Advertising-Bulk operations",
  "Advertising-Bulk operation logs",
  "Search default report",
  "Download default report",
  "My report",
  "My report history",
  "Marketplace Dashboard",
  "Analytics Dashboard",
  "Research-Keyword Research",
  "Research-PAT Research",
  "Research-Audience Research",
  "Research-Query-ASIN Graph",
  "Research-Bid-landscape Chart",
  "Research-ASIN-landscape Chart",
  "OPT-Dayparting Scheduler",
  "OPT-Dayparting Scheduler Details",
  "OPT-Budget Scheduler",
  "OPT-Budget Scheduler Details",
  "OPT-Auto Rules",
  "OPT-Manual Rules",
  "OPT-Manual Rules Record",
  "OPT-Commerce Rules",
  "OPT-Rule Library",
  "OPT-Campaign AI",
  "OPT-Product AI",
  "OPT-Product AI Detals",
  "OPT-Paid Search Audit",
  "OPT-Paid Search Audit Details",
  "OPT-Bid Explorer",
  "OPT-Bid Explorer Overview",
  "SD Dashboard",
  "SOV List",
  "Keyword SOV Dashboard",
  "PAT SOV Dashboard",
  "SOV Insight",
  "SOV Brand",
  "SOV Keyword",
  "SOV Keyword Detail",
  "SOV ASIN",
  "SOV ASIN Details",
  "SOV Brands Mapping",
  "SOV Keyword Tag",
  "Product Tracking",
  "Product Tracking Details",
  "Brand Audit",
  "Brand Audit ASIN List",
  "CI-Search Term",
  "CI-Product",
  "CI-Brand Sales",
  "Advertising Event",
  "Task Center Event",
  "Task Center Event Log",
  "Retail Dashboard",
  "Tag Share Center",
  "Keyword Opportunity",
  "Synchronization",
  "Notification",
  "Message",
  "My Account",
  "User Management",
  "Settings",
  "Incrementality",
  "Incrementality Product Page",
  "Incrementality Campaign Page",
  "Research-Holiday Keyword",
  "View Anomaly Alert",
  "Delivery Rate Analysis",
  "Replacement ASIN",
  "CI-Product-ASIN Search Term",
  "Download Center",
  "View Download Center",
  "Brand List on My Account",
  "Profile Modified Logs",
  "Favorite ASIN",
  "Add keyword to",
  "Live Ad Momentum",
  "AutomationDashboard"
]

// 路由名称与页面名称的映射
export const ANALYTICS_PAGE_MAPPER = {
  ProductDashboard: "PC-Dashboard",
  "Product Listing": "PC-Listing",
  Order: "PC-Order",
  "Eligibility Insight": "PC-Latitude",
  ProductNonVC: "PC-Unauthorized Page",
  "Product Detail": "PC-Listing-Detail",
  "Budget Manager": "BM-List",
  "Budget Dashboard": "BM-Dashboard",
  "Budget Setting": "BM-Settings",
  "Operation Log": "BM-Operation Log",
  "Calendar Template Manager": "BM-Calendar Manager",
  Setting: "Settings",
  UserManagement: "User Management",
  MyAccount: "My Account",
  AdvertisingExplore: "Advertising-Explorer",
  CampaignProfile: "Advertising-profile",
  // ProfileDetail: "Advertising-profile details",
  CampaignTagging: "Advertising-Campaign tag",
  AdgroupTagging: "Advertising-adgroup",
  KeywordTagging: "Advertising-keyword tag",
  ASINTagging: "Advertising-asin tag",
  AmazonPortfolio: "Advertising-portfolio",
  // PortfolioDetail: "Advertising-portfolio details",
  Campaign: "Advertising-campaign",
  "Hourly Trend Detail": "Advertising-campaign hourly data",
  CampaignAsin: "Advertising-ASIN",
  "Negative Targeting": "Advertising-NG Targeting",
  CampaignBulkOperations: "Advertising-Bulk operations",
  CampaignBulkOperationsLog: "Advertising-Bulk operation logs",
  CampaignSearchTerm: "Advertising-ST",
  Targeting: "Advertising-Targeting",
  CampaignAdGroup: "Advertising-adgroup",
  SbAds: "Advertising-adgroup SB ads",
  CreateSBAd: "Advertising-SB ads creative",
  "Keyword Research": "Research-Keyword Research",
  PATResearch: "Research-PAT Research",
  "Audience Research": "Research-Audience Research",
  QueryAsinGraph: "Research-Query-ASIN Graph",
  "Bid LandScape": "Research-Bid-landscape Chart",
  "Asin LandScape": "Research-ASIN-landscape Chart",
  "Dayparting Scheduler": "OPT-Dayparting Scheduler",
  "Dayparting Apply Detail": "OPT-Dayparting Scheduler Details",
  "Budget Scheduler": "OPT-Budget Scheduler",
  "Budget Detail": "OPT-Budget Scheduler Details",
  "Manual Rule Run Record": "OPT-Manual Rules Record",
  "Paid Search Audit": "OPT-Paid Search Audit",
  "Audit Detail": "OPT-Paid Search Audit Details",
  Bidexplorer: "OPT-Bid Explorer",
  BidexplorerOverview: "OPT-Bid Explorer Overview",
  SearchTerm: "CI-Search Term",
  Product: "CI-Product",
  BrandAndSales: "CI-Brand Sales",
  "Task Center": "Task Center Event",
  PortfolioDetail: "Advertising-profile details",
  "My Report": "My report",
  "View History": "My report history",
  "Audited ASIN": "Brand Audit ASIN List",
  "Sponsored Display Dashboard": "SD Dashboard",
  Competitive: "SOV List",
  "SOV Dashboard": "Keyword SOV Dashboard",
  "ASIN SOV Analysis": "SOV ASIN Details",
  "Manage SOV Keyword Tag": "SOV Keyword Tag",
  "Product AI": "OPT-Product AI",
  "Analytics DashBoard": "Analytics Dashboard",
  // SOVInsight: "SOV Insight",
  AsinTag: "Advertising-asin tag",
  Products: "Incrementality Product Page",
  Campaigns: "Incrementality Campaign Page",
  IncrementalityEmpty: "Incrementality",
  // "Holiday Keywords": "Research-Holiday Keyword",
  ASINQuery: "CI-Product-ASIN Search Term",
  ASINHistory: "CI-Product-ASIN Search Term",
  Download: "Download Center",
  "Campaign AI": "OPT-Campaign AI"
}

// 不需要路由守卫自动触发的页面
export const IGNORE_ANALYTICS_PAGELIST = [
  "Manage Negative List",
  "CampaignSearchTerm",
  "Targeting",
  "CampaignTargetSetting",
  "Rule",
  "Campaign AI",
  "Brand Audit",
  "Advertising Event",
  "TaskCenter",
  "AdvertisingExplore",
  "Default Report",
  "Profile Report",
  "Campaign Report",
  "Portfolio&Campaign Tag Report",
  "J&J Report",
  "Keyword Report",
  "Account Profiles Spends Report",
  "Product Eligibility Report",
  "ASIN Brand Halo Report",
  "Product Report",
  "Brand Metrics Report",
  "Megads - Account Audit Report",
  "Notification",
  "AccountBinding",
  "login",
  "CampaignDetail",
  "Message",
  "AI Detail",
  "TaskDetail",
  "TaskOtherDetail",
  "Task Center Event",
  "PC-Listing",
  "Advertising-ST",
  "Advertising-Targeting",
  "Anomaly Alert",
  "CI-Product-ASIN Search Term",
  "Keyword SOV Dashboard",
  "SOV Brand",
  "SOV Keyword",
  "SOV ASIN",
  "SOV ASIN Details",
  "SOV Insight",
  "OPT-Campaign AI",
  "Add keyword to",
  "AutomationDashboard"
]

// 域名对应的region
export const hostRegionMapper = {
  "product.pacvue.com": "COM",
  "product.pacvue.cn": "CN",
  "product-eu.pacvue.com": "EU",
  "producteurope.pacvue.com": "EU",
  "v3-dev6.pacvue.com": "COM"
}
