import { app, InitApp } from "./frame"
import { useUserStore, useAppStore, useCommonStore } from "@/store"
import { navigationGuard as oktaNavGuard } from "@okta/okta-vue"
import useFrameStore from "./frameStore"
import { pollLocale, pollAndCacheLocaleNew } from "../utils/locale.js"
import { commonJS } from "@pacvue/utils"
import { analyticsIdentify, useAnalyticsTrack } from "./analytics/index.js"
import { loadAppcues } from "./appcues"
import config from "@~/config.js"
import { logAndTrackLoginDuration, writeLoginInfoAndNavigate } from "../utils/login.js"
import { handleAuthCodeAndReloadPage, logAndTrackNavDuration } from "../utils/nav.js"
import { keepCurrentPersistedData } from "../store/cache-restore.js"
import { getPltAnnouncement } from "./common"
import { shouldReload } from "./timeout"
import { DOMAIN_MAPPER_PROD } from "../constants/platform.js"
import { isCN } from "../constants/env.js"

const shortcutNames = ["OktaProfile", "Auth", "ActiveEmail"] // 捷径路由名称
const loadingKeptNames = ["OktaCallback", "OktaCallbackIntermediate"] // 始终保持全局加载的路由

function endRequest() {
  window.$httpArray.forEach((item) => {
    item()
  })
  window.$httpArray = []
}

export default function (router) {
  // router.beforeEach(oktaNavGuard)

  router.beforeEach(async (to, from, next) => {
    console.log(`from: ${from.path} navigate to: ${to.path}`)
    const _this = this || {}
    const productLine = window.productline || localStorage.getItem("productline")
    const isNewLocaleAsync = config.isNewLocaleAsync || (productLine === "walmart" && isCN) || productLine === "retailer"

    const nextFn = async ({ localePollEnabled = true } = {}) => {
      if (isNewLocaleAsync && localePollEnabled) {
        // 国际化轮询及缓存
        await pollAndCacheLocaleNew()
      }
      sessionStorage.removeItem("perDataKept")
      next()
    }

    // okta 认证回调后的调用逻辑在 Callback 组件中处理 (以下两个 route name 都是对应 Callback 组件, 只是路由 params 不同)
    if (["OktaCallback", "OktaCallbackIntermediate"].includes(to.name)) {
      await nextFn()
      return
    }

    // 特殊平台的跳转路径预处理拦截
    if (InitApp.pathPreprocessor) {
      const isDifferentPath = await InitApp.pathPreprocessor(to, from, next)
      if (isDifferentPath) return
    }

    if (to.query.authcode) {
      await handleAuthCodeAndReloadPage(from, to)
      next()
      return
    }

    // 内部站点跳转逻辑处理
    if (to.query.crossNav || (from.path === "/" && sessionStorage.getItem("perDataKept") != 1)) {
      await keepCurrentPersistedData()
      if (to.query.crossNav) {
        window.isCrossNav = true
      }
      // 登录跨域跳转而来 (groupM), 需要向当前域写入登录标志
      if (to.query.crossDomainLogin) {
        localStorage.setItem("SignFlag", true)
      }
      // 保证此 if block 不会再次进入
      sessionStorage.setItem("perDataKept", "1")
      const destPath = to.query.redirect || to.path
      const extraParams = to.query.payload ? { query: { query: to.query.payload } } : {}
      // 通过路由过程中的 query 信息告知后续 userInfo 及菜单请求不能使用缓存
      if (to.query.noCache) {
        if (!extraParams.query) {
          extraParams.query = {}
        }
        extraParams.query.noCache = "1"
      }
      // 不开放登录页的平台: HQ, 乐天 还需要记录跳转而来的源平台
      if (config.noLogin && to.query.query) {
        localStorage.setItem("navSrcPlt", to.query.query)
      }
      // 直接进入下一钩子，并顺带清除 path query
      next(to.query.crossNav ? { path: destPath, ...extraParams } : { path: to.path, query: to.query })
      return
    }

    // 假如有前置的清除操作
    if (_this.clearingFn && typeof _this.clearingFn === "function") {
      _this.clearingFn()
    }

    // 新国际化 CDN 方案、非新异步策略
    if (!isNewLocaleAsync && !["commerce-walmart"].includes(productLine)) {
      await pollLocale()
    }

    if (to.fullPath.lastIndexOf("/login") != -1 || to.fullPath.lastIndexOf("loginoauth") != -1 || shortcutNames.includes(to.name)) {
      document.getElementById("pacvue-loading").style.display = "none"
      await nextFn()
      return
    }

    const userStore = useUserStore()
    const frameStore = useFrameStore() // identical to appStore
    frameStore.SET_activePath(to.path)

    try {
      if (!to.query.shareId) {
        if ((!userStore.user || !userStore.user.userName) && !to.meta.noRefreshToken) {
          let phase = 0
          let currPhaseInterceptors = []
          while ((currPhaseInterceptors = InitApp.userInfoInterceptors[`phase${phase++}`] || []).length > 0) {
            const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(to, from, next)))
            await Promise.all(promises)
          }
          app.config.globalProperties.$eventHub.emit("getUserInfo", userStore.user)
        }
        if (!to.meta.noRefreshToken) {
          const requestList = []
          if (frameStore.menu.length === 0) {
            requestList.push(frameStore.meueAction({ to, from }))
          }
          if (!userStore.userSettings) {
            requestList.push(userStore.userSetting({ to, from }))
          }
          if (requestList.length > 0) {
            await Promise.all(requestList)
            // 保留 window.HomePage 字段, 来源由本地存储变为后端偏好返回
            window.HomePage = window.menuIdItemMap[window.HomePageId]?.url ?? ""
          }
        }
        // 根据偏好切换主题
        if (to.meta.noRefreshToken || userStore.userSettings) {
          if (userStore?.userSettings?.themeColour == "white") {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
          } else if (userStore?.userSettings?.themeColour == "black") {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
          } else {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
          }
        }
        // 偏好语言与当前环境不同, 则需要重刷页面, 拿对应语言最新的语言包
        if (window.prefLangDiffered) {
          if (from.path.includes("login") || from.path === "/Auth") {
            const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
            window.location.replace(`/${pathPrefix}${to.fullPath}`.replace("//", "/"))
          } else {
            window.location.reload()
          }
          await nextFn({ localePollEnabled: false })
          return
        }
        /* 偏好相关 End */
        for (let i in InitApp.routerInterceptors) {
          await InitApp.routerInterceptors[i](to, from, next)
        }
      } else {
        // Share Link 相关路由拦截器
        let phase = 0
        let currPhaseInterceptors = []
        while ((currPhaseInterceptors = InitApp.shareLinkRouterInterceptors[`phase${phase++}`] || []).length > 0) {
          const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(to, from, next)))
          await Promise.all(promises)
        }
      }
    } catch (err) {
      next("/login")
    }
    app.config.globalProperties.$eventHub.emit("routerBeforeHook", { to, from, next })

    if (!_this.hasPermissionProxy && to.meta?.alias && window.NoPermissionPage?.[to.meta.alias]) {
      next({ name: "NoPermission" })
    }

    /* 登录最终步骤 Start */
    if ((from.path.includes("login") || from.path === "/Auth" || from.path === "/") && localStorage.getItem("SignFlag") == "true") {
      localStorage.setItem("SignFlag", false)
      await analyticsIdentify()
      const clientSelect = localStorage.getItem("isMultiClient") ? "Select Client" : "No need to select"
      useAnalyticsTrack("Login Success", {
        category: "Login",
        "login type": "username",
        "Client Select": clientSelect
      })
      if (userStore.user && !userStore.user.isEmailValidated && userStore.userSettings && !userStore.userSettings.mailValidatePop) {
        localStorage.setItem("EmailVerifyPrompt", true)
      }
      localStorage.removeItem("isMultiClient")
      let autoLogoutPath
      if (from.query.Rurl) {
        autoLogoutPath = isNaN(from.query.Rurl) ? from.query.Rurl : localStorage.getItem(`pacvue_logout_path_${from.query.Rurl}`)
      }
      // 若路由的某次钩子进行到最后一步仍是去往欢迎页, 则直接放行
      if (to.path === "/Guide") {
        await nextFn()
        endRequest()
      } else {
        const homePage = window.HomePage && window.menuUrlItemMap?.[window.HomePage]?.permissionType > 0 ? window.HomePage : ""
        next(autoLogoutPath || homePage || window.firstRouter)
        return
      }
    }
    /* 登录最终步骤 End */

    await nextFn()
    endRequest()
  })

  router.afterEach((to, from, next) => {
    const userInfo = useUserStore().user
    // 路由改造前, 向 Segment 记录登录总时长，分为当前产品线直接登录 与 跨产品线登录，以及跨平台跳转总时长
    logAndTrackLoginDuration({ userInfo, to })
    logAndTrackNavDuration({ userInfo, to })
    if (!loadingKeptNames.includes(to.name)) {
      document.getElementById("pacvue-loading").style.display = "none"
    }
    if (!config.noAppcues && !window.Appcues && window.AppcuesSettings && !to.path.includes("login") && !shortcutNames.includes(to.name)) {
      loadAppcues()
    }
    !config.noGlobalAnnouncement && getPltAnnouncement(userInfo)
    app.config.globalProperties.$eventHub.emit("routerAfterEachHook", { to, from, next })
  })

  router.onError((error, to) => {
    const productLine = window.productline || localStorage.getItem("productline")
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      if (shouldReload()) {
        // 存储当前时间戳
        sessionStorage.setItem("lastReloadTime", new Date().getTime())
        // 刷新页面
        const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
        window.location.replace(`/${pathPrefix}${to.fullPath}`.replace("//", "/"))
      }
    }
  })
}
