const productline = localStorage.getItem("productline") || "default"

// error报错规则
function errorTrackingRule(event, context) {
  if (event.type === "error") {
    if (event.error?.message.includes("ResizeObserver") || event.error?.message.includes("Warning: [@surely-vue/table")) {
      // console.log("ResizeObserver error", event.error)
      return false
    }
  }
  // 过滤掉walmart平台的特殊邮件服务超链接
  if (productline === "walmart" && event.type === "view") {
    if (event.view.url.includes("/walmart/Campaign/Campaign") && event.view.url.includes("source=widget")) {
      return false
    }
  }
}

export default {
  errorTrackingRule
}
