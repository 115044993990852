import { forageInstance, regExp } from "./cache-restore.js"

const getProductLine = () => window.productline || localStorage.getItem("productline")

const { setItem: rawSessionSetter, getItem: rawSessionGetter, removeItem: rawSessionRemover } = sessionStorage
const { setItem: rawLocalSetter, getItem: rawLocalGetter, removeItem: rawLocalRemover } = localStorage
const ignoreKeys = ["locale", "Pacvue_token", "SignFlag", "EmailVerifyPrompt", "loginUserName", "recentLoginQueue", "isMultiClient", "sso_platform_sel", "sso_mc_info", "customOktaState"]

const sessionIgnoreKeys = ["navStartTime", "loginStartTime", "loginNavStartTime"]

if (navigator.userAgent.indexOf("Firefox") > -1) {
  // Firefox 存在浏览器兼容问题，需要修改 Storage 原型上的方法
  window.Storage.prototype.getItem = function (key, useOrigin) {
    if (this === window.sessionStorage) {
      return rawSessionGetter.call(this, `${getProductLine()}_${key}`)
    } else if (this === window.localStorage) {
      if (key === "productline") {
        return window.productline || rawLocalGetter.call(this, key)
      } else if (ignoreKeys.includes(key) || key?.startsWith("okta-") || useOrigin) {
        return rawLocalGetter.call(this, key)
      } else {
        return rawLocalGetter.call(this, `${getProductLine()}_${key}`)
      }
    }
  }
  window.Storage.prototype.setItem = function (key, value) {
    if (this === window.sessionStorage) {
      rawSessionSetter.call(this, `${getProductLine()}_${key}`, value)
    } else if (this === window.localStorage) {
      if (["productline", "locale"].includes(key)) {
        window[key] = value
        rawLocalSetter.call(this, key, value)
      } else if (ignoreKeys.includes(key) || key?.startsWith("okta-")) {
        rawLocalSetter.call(this, key, value)
      } else {
        rawLocalSetter.call(this, `${getProductLine()}_${key}`, value)
        if (regExp.test(key)) {
          forageInstance.setItem(`${getProductLine()}_${key}`, value)
        }
      }
    }
  }
  window.Storage.prototype.removeItem = function (key) {
    if (this === window.sessionStorage) {
      rawSessionRemover.call(this, `${getProductLine()}_${key}`)
    } else if (this === window.localStorage) {
      if (ignoreKeys.includes(key) || key?.startsWith("okta-")) {
        return rawLocalRemover.call(this, key)
      } else {
        if (regExp.test(key)) {
          forageInstance.removeItem(`${getProductLine()}_${key}`)
        }
        return rawLocalRemover.call(this, `${getProductLine()}_${key}`)
      }
    }
  }
} else {
  /* Other Browsers */
  // session
  sessionStorage.getItem = function (key) {
    if (sessionIgnoreKeys.includes(key)) {
      return rawSessionGetter.call(this, key)
    } else {
      return rawSessionGetter.call(this, `${getProductLine()}_${key}`)
    }
  }
  sessionStorage.setItem = function (key, value) {
    if (sessionIgnoreKeys.includes(key)) {
      rawSessionSetter.call(this, key, value)
    } else {
      rawSessionSetter.call(this, `${getProductLine()}_${key}`, value)
    }
  }
  sessionStorage.removeItem = function (key) {
    if (sessionIgnoreKeys.includes(key)) {
      rawSessionRemover.call(this, key)
    } else {
      rawSessionRemover.call(this, `${getProductLine()}_${key}`)
    }
  }
  // local
  localStorage.getItem = function (key, useOrigin) {
    if (key === "productline") {
      return window.productline || rawLocalGetter.call(this, key)
    } else if (ignoreKeys.includes(key) || key?.startsWith("okta-") || useOrigin) {
      return rawLocalGetter.call(this, key)
    } else {
      return rawLocalGetter.call(this, `${getProductLine()}_${key}`)
    }
  }
  localStorage.setItem = function (key, value) {
    if (["productline", "locale"].includes(key)) {
      window[key] = value
      rawLocalSetter.call(this, key, value)
    } else if (ignoreKeys.includes(key) || key?.startsWith("okta-")) {
      rawLocalSetter.call(this, key, value)
    } else {
      rawLocalSetter.call(this, `${getProductLine()}_${key}`, value)
      if (regExp.test(key)) {
        forageInstance.setItem(`${getProductLine()}_${key}`, value)
      }
    }
  }
  localStorage.removeItem = function (key) {
    if (ignoreKeys.includes(key) || key?.startsWith("okta-")) {
      return rawLocalRemover.call(this, key)
    } else {
      if (regExp.test(key)) {
        forageInstance.removeItem(`${getProductLine()}_${key}`)
      }
      return rawLocalRemover.call(this, `${getProductLine()}_${key}`)
    }
  }
}

export { rawLocalGetter, rawLocalSetter, rawLocalRemover, rawSessionGetter, rawSessionSetter, rawSessionRemover }
