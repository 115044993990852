import { useUserStore, useAppStore, useCommonStore } from "@/store"
import { judgeClientAndRedirect } from "../utils/nav"

export default {
  userInfoInterceptors: [
    // return thenable object
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const userStore = useUserStore()
          const appStore = useAppStore()
          const commonStore = useCommonStore()
          await userStore.userAction({ to, from })
          const shouldRedirect = await judgeClientAndRedirect({ userStoreContext: userStore })
          // 需要重定向到其他域名的情况下, 令 promise 持续 pending
          if (!shouldRedirect) {
            appStore.setUserPersist()
            commonStore.setUserPersist()
            onFulfill(true)
          }
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  settingInterceptors: []
}
