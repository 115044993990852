import Main from "@pacvue/frame/layout/Main.vue"
import { dayjs } from "@pacvue/element-plus"
let startDate = dayjs().subtract(1, "year").format("YYYY/MM/DD")
let endDate = dayjs().format("YYYY/MM/DD")
if (localStorage.getItem("lifeTimeStartDate")) {
  startDate = localStorage.getItem("lifeTimeStartDate")
}
if (localStorage.getItem("lifeTimeEndDate")) {
  endDate = localStorage.getItem("lifeTimeEndDate")
}
export default {
  router: {
    path: "/",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/ASINAI",
        component: () => import("@AsinCenterAIv3/indexAI.vue"),
        name: `Product AI`,
        meta: {
          title: `Product AI`,
          id: 2,
          isShowTime: false,
          showCompareCheck: false,
          identicalPath: "/Optimization/ASINAI/#/AI/index"
        }
      },
      {
        path: "/Optimization/createAI",
        component: () => import("@AsinCenterAIv3/IntroductoryAI.vue"),
        name: "CreateSuperAI",
        meta: {
          title: `Product AI`,
          id: 33,
          showCompareCheck: false
        }
      },
      {
        path: "/Optimization/AI/AiDetail",
        component: () => import("@AsinCenterAIv3/detail/index.vue"),
        name: "AI Detail",
        meta: {
          title: "AI",
          id: 4,
          isShowTime: false,
          showCompareCheck: false,
          parent: `Product AI`
        }
      },
      {
        path: "/Optimization/AI/LaunchSingleAI",
        component: () => import("@AsinCenterAIv3/createAI/SinglelaunchAINew.vue"),
        name: `Launch AI for Product`,
        meta: {
          title: "Launch AI for Product",
          id: 7,
          isShowTime: false,
          showCompareCheck: false,
          parent: `Product AI`
        }
      },
      {
        path: "/Optimization/AI/LaunchBatchAI",
        component: () => import("@AsinCenterAIv3/createAI/BatchlaunchAI.vue"),
        name: `Create super AI by ASIN`,
        meta: {
          title: "Create super AI by ASIN",
          id: 6,
          isShowTime: false,
          showCompareCheck: false,
          parent: `Product AI`
        }
      }
    ]
  }
}
