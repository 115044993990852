import {
  GetWarRooms,
  GetWarRoomStrategys,
  GetWarRoomSalesTrend,
  GetWarRoomFeadTrendData,
  GetWarRoomFeadTrendCompareData,
  GetWarRoomInsightData,
  GetStrategicCenterHolidays,
  GetCampaignLabels
} from "@/api/Optimization/strategic"
import { PacvueKeepDigit } from "@/utils/common-util"
import { GetMaxDateTime } from "@/api/Advertising/advertising"
import { useCommonStore } from "@/store"
import { dayjs } from "@pacvue/element-plus"
import { getStrategeDateTimePos } from "./util"
var timeHandler = null
var cancelReqCamLabel = null
export default {
  deleteWarRoom(WarRoomId) {
    var newWarRoomList = []
    var activeWarRoom = this.activeWarRoom ?? {}
    this.warRoomList.forEach((item) => {
      if (item.WarRoomId != WarRoomId) {
        newWarRoomList.push(item)
      }
    })
    if (activeWarRoom?.WarRoomId == WarRoomId) {
      this.activeWarRoom = newWarRoomList[0] ?? {}
    }
    this.warRoomList = newWarRoomList
  },
  refreshCampaignLabel({ profileIds, dateTime } = {}) {
    //刷新获取cmapaignLabel数据
    clearTimeout(timeHandler)
    timeHandler = setTimeout(async () => {
      cancelReqCamLabel && cancelReqCamLabel()
      await this.getCampaignDetectionLabel({ profileIds, dateTime })
      this.refreshCampaignLabel({ profileIds, dateTime })
    }, 5 * 60 * 1000)
  },
  isVirtualHour(hour) {
    //判断当前是否是虚拟时间
    var self = this
    var currentHour = self.currentHour //MM/DD/YYYY HH:mm:ss
    var maxDate = dayjs(currentHour, "MM/DD/YYYY HH:mm:ss")
    var curDateTime = self.dateTime //YYYY/MM/DD
    var latestMaxHour = maxDate.get("hour")
    var maxDate2 = maxDate.format("YYYY/MM/DD")

    if (maxDate2 == curDateTime) {
      //当前时间在当天
      if (hour <= latestMaxHour) {
        return false
      }
      return true
    } else if (dayjs(maxDate2, "YYYY/MM/DD").isBefore(dayjs(curDateTime, "YYYY/MM/DD"))) {
      return true
    }
    return false
  },
  async refreshData(type = "strategy") {
    var activeWarRoom = this.activeWarRoom ?? {}
    if (type == "strategy") {
      var activeStrategy = this.activeStrategy ?? {}
      var strategyList = await this.getWarRoomStrategyInfo({ warRoomIds: [activeWarRoom.WarRoomId] })
      var activeStrategyInfo = strategyList.find((item) => item.StrategyId == activeStrategy?.StrategyId)
      if (!activeStrategyInfo) {
        this.activeStrategy = strategyList[0] ?? {}
      }
      this.strategyList = strategyList
    }
    //刷新整个warRoom方法
    this.getWarRoomList()
  },
  getWarRoomList() {
    var activeWarRoom = this.activeWarRoom ?? {}
    this.allLoading = true
    GetWarRooms({}).then((res) => {
      var warRoomList = res || []
      this.allLoading = false
      this.warRoomList = warRoomList
      var activeWarRoomInfo = warRoomList.find((item) => item.WarRoomId == activeWarRoom.WarRoomId)
      if (activeWarRoomInfo) {
        this.setActiveWarRoom(activeWarRoomInfo)
      } else {
        this.setActiveWarRoom(warRoomList[0] ?? {})
      }
    })
  },
  async setActiveWarRoom(warRoom, { isFirst = false } = {}) {
    this.allLoading = true
    var self = this
    if (warRoom.ProfileId) {
      var info = await this.PacvueGetMaxDateTime({ profileIds: [warRoom.ProfileId] })
      self.dateTime = isFirst && self.queryDateTime ? self.queryDateTime : dayjs(info.start, "MM/DD/YYYY").format("YYYY/MM/DD")
      self.curToday = info.start
    }
    this.allLoading = false
    this.activeWarRoom = warRoom
  },
  async getCampaignDetectionLabel({ dateTime, profileIds }) {
    var params = {
      start: dateTime,
      end: dateTime,
      profileIds: profileIds ?? [],
      campaignIds: []
    }
    var self = this
    var campagignLabelStore = self.campagignLabelStore
    var uniqueKey = profileIds.join("-") + "_" + dateTime
    if (campagignLabelStore[uniqueKey]) {
      self.campaginIdLabelMap = campagignLabelStore[uniqueKey]
      return
    }
    self.campaginIdLabelMap = {} //清空列表
    var fetchData = GetCampaignLabels(params)
    cancelReqCamLabel = fetchData.cancel
    var data = await fetchData
    data = data || []
    var map = data.reduce(
      (result, item) => {
        var { campaignId, isLowComp, isLowClick, isHighClick, isBudgetShort } = item
        if (isLowComp) {
          result.LowComp.push(campaignId)
        }
        if (isLowClick) {
          result.LowClick.push(campaignId)
        }
        if (isHighClick) {
          result.HighClick.push(campaignId)
        }
        if (isBudgetShort) {
          result.BudgetShort.push(campaignId)
        }
        return result
      },
      { LowComp: [], LowClick: [], HighClick: [], BudgetShort: [] }
    )
    campagignLabelStore[uniqueKey] = map
    self.campaginIdLabelMap = map
  },
  getStrategicHolidays() {
    //获取节假日
    GetStrategicCenterHolidays().then((res) => {
      this.isHolidayLoaded = true
      var dataList = (res ?? []).map((item) => {
        //因moment对时区会有特殊处理，需要把时区截掉
        let index = item.StartDate.indexOf("T")
        let StartDate = item.StartDate.substring(0, index)
        return { ...item, showDate: dayjs(StartDate).format("YYYY-MM-DD") }
      })
      this.holidays = dataList
    })
  },
  computeChange({ curVal, comparVal } = {}) {
    return comparVal ? PacvueKeepDigit(((curVal - comparVal) / comparVal) * 100, 2) : 0
  },
  getWarRoomInsight(query) {
    return new Promise((resolve, reject) => {
      GetWarRoomInsightData(query)
        .then((res) => {
          resolve(res)
        })
        .catch((ex) => {
          resolve({})
        })
    })
  },
  async getWarRoomTrendDataNew(query, { hasTargetTrend } = {}) {
    var trendQuery = { ...query, campareQuery: undefined }
    var trendComparQuery = query.campareQuery ?? {}
    this.trendData.hourlTrendData = []
    this.trendData.totalTrendData = []
    this.trendData.total = {}
    this.trendData.loading = true
    var showProIndexList = this.showProIndexList
    var computeChange = this.computeChange
    var isVirtualHour = this.isVirtualHour
    let reqList = [GetWarRoomFeadTrendData(trendQuery), GetWarRoomFeadTrendCompareData(trendComparQuery)]
    let activeStrategy = this.activeStrategy
    let StrategyId = activeStrategy?.StrategyId
    let strategyPerformancesCode = this.strategyPerformancesCode
    let hasTarget = hasTargetTrend && StrategyId != -1
    let dateTimePos = this.dateTimePos
    let self = this
    if (hasTarget && dateTimePos == "between") {
      let profileIds = query.profileIds ?? []
      let todayTargetInfo = self.getStrategyTodayTarget({ strategyItem: activeStrategy })
      let targetTrendQuery = {
        profileId: profileIds[0] ?? 0,
        campaignIds: query.campaignIds,
        currentDate: this.dateTime,
        ...todayTargetInfo,
        trendType: this.stateMode == "Hourly" ? 0 : 1
      }
      reqList.push(GetWarRoomSalesTrend(targetTrendQuery))
    }
    return Promise.all(reqList)
      .then(([trendRes, trendComparRes, targetTrendRes]) => {
        let targetShadow = {}
        var trendData = trendRes?.trend ?? []
        //获取当前有效时间值
        // var isSupplementIndex = trendData.findIndex((item) => item.isSupplement)
        // let maxHour = 23
        // if (isSupplementIndex > 0) {
        //   maxHour = isSupplementIndex - 1
        // }
        if (hasTarget) {
          //有targetTrend
          let proShadow = {
            Sales: "Sale",
            Orders: "Order",
            SaleUnits: "Saleunit",
            Spend: "Cost"
          }
          strategyPerformancesCode.forEach((code) => {
            let preProName = `preSales` //`pre${code}`
            let preList = targetTrendRes?.[preProName] ?? []
            preList.forEach((item) => {
              let propCode = proShadow[code] ?? code
              let targetVal = item[`total${propCode}`] ?? 0
              let hour = item.hour
              if (!targetShadow[hour]) {
                targetShadow[hour] = {}
              }
              let targetKey = `target${code}`
              targetShadow[hour][targetKey] = targetVal
              if (code == "Orders") {
                targetShadow[hour][`targetConversion`] = targetVal
              }
            })
          })
        }

        var trendCamparData = trendComparRes?.trend ?? []
        var trendTotalChartData = trendRes?.trendTotal ?? []
        var trendTotalChartCamparData = trendComparRes?.trendTotal ?? []
        //如果实际数据已经存在
        var hourlyTrendData = formatTrendFeadData({ trendData: trendData, trendCamparData: trendCamparData, showProIndexList, isVirtualHour, targetShadow })

        var totalTrendData = formatTrendFeadData({ trendData: trendTotalChartData, trendCamparData: trendTotalChartCamparData, showProIndexList, isVirtualHour, targetShadow })

        //处理total数据
        var trendTotal = trendRes?.total ?? {}
        var trendTotalData = trendComparRes?.total ?? {}
        for (var propKey in trendTotal) {
          var curVal = trendTotal[propKey]
          if (showProIndexList.includes(propKey)) {
            var newPropKey = propKey + "Compar"
            var comparVal = trendTotalData[propKey]
            trendTotal[newPropKey] = comparVal
            trendTotal[propKey + "Change"] = computeChange({ curVal, comparVal })
          }
        }
        this.trendData.hourlTrendData = hourlyTrendData
        this.trendData.totalTrendData = totalTrendData
        this.trendData.total = trendTotal
      })
      .finally(() => {
        this.trendData.loading = false
      })
  },
  getWarRoomTrendData({ query, reqList = ["trend", "trendCompare"] }) {
    if (reqList.length == 2) {
      this.trendData.data = []
      this.trendData.total = {}
    }
    var showProIndexList = this.showProIndexList
    reqList.forEach((reqType) => {
      if (reqType == "trend") {
        GetWarRoomFeadTrendData({
          ...query
        })
          .then((res) => {
            var { trend = [], total = {} } = res ?? {}
            if (this.trendData.data.length) {
              //如果对比数据已经存在了，则需要处理数据
              this.trendData.data = this.trendData.data.map((item, index) => {
                return { ...item, ...trend[index] }
              })
              //total需要计算
              this.total = {
                ...this.total,
                ...total
              }
            } else {
              this.trendData = trend
              this.total = total
            }
          })
          .finally(() => {})
      } else {
        GetWarRoomFeadTrendCompareData({ ...query }).then((res) => {
          var { trend = [], total = {} } = res ?? {}
          if (!this.trendData.length) {
            this.trendData = trend.map((item) => {
              var newItem = { hour: item.hour, countryCode: item.countryCode }
              showProIndexList.forEach((proKey) => {
                newItem[proKey + "Compar"] = item[proKey]
              })
              return newItem
            })
            //处理total数据
            var totalData = {}
            for (var propKey in total) {
              if (showProIndexList.includes(propKey)) {
                var newPropKey = propKey + "Compar"
                totalData[newPropKey] = total[propKey]
              }
            }
            this.trendData.total = totalData
          } else {
            //如果实际数据已经存在
            var trendCamparMap = trend.reduce((result, item) => {
              var hour = item.hour
              result[hour] = item
              return result
            }, {})
            this.trendData = this.trendData.map((item) => {
              var hour = item.hour
              var campareData = trendCamparMap[hour] || {}
              var newItem = { ...item }
              showProIndexList.forEach((proKey) => {
                var ComparVal = campareData[proKey] ?? 0
                newItem[proKey + "Compar"] = ComparVal
                //newItem[proKey + "Change"] = this.computeChange({ curVal, comparVal: ComparVal })
              })
              return newItem
            })
          }
        })
      }
    })
  },
  deleteWarRoomStrategy(StrategyId) {
    var newList = []
    var activeStrategy = this.activeStrategy ?? {}
    this.strategyList.forEach((item) => {
      if (item.StrategyId != StrategyId) {
        newList.push(item)
      }
    })
    if (activeStrategy?.StrategyId !== StrategyId) {
      this.activeStrategy = newList[0] ?? {}
    }
    this.strategyList = newList
  },
  async getWarRoomStrategyInfo(query) {
    const strategyList = await this.getWarRoomStrategys(query)
    var self = this
    strategyList.forEach((strategyItem) => {
      // let children = []
      // let StrategyId = strategyItem.StrategyId
      // strategyPerformances.forEach(({ code }) => {
      //   let proName = `Target${code}`
      //   let proVal = strategyItem?.[proName] ?? null
      //   let TargetValPercentage = strategyItem?.[proName + "Percentage"] ?? null
      //   if (proVal !== null) {
      //     let childItem = {
      //       StrategyId,
      //       [proName]: proVal,
      //       [proName + "Percentage"]: TargetValPercentage
      //     }
      //     children.push(childItem)
      //   }
      // })
      // //需要生成
      // strategyItem.children = children
      self.formatStrategyInfo({ strategyItem })
    })
    return strategyList
  },
  updateStrategyShowData({ totalInfo = {}, strategyInfo, todayInfo = {}, todayTargetInfo = {} }) {
    //更新stragegy中total数据
    let strategyPerformances = this.strategyPerformances
    todayInfo = todayInfo || {}
    let updateStrategyTodayInfo = ({ code }) => {
      let todayVal = todayInfo[`total${code}`]
      //需要覆盖匹配当前日期是否有targetSales

      let todayTargetVal = todayTargetInfo[`target${code}`] ?? null
      strategyInfo[`todayTarget${code}`] = todayTargetVal
      strategyInfo[`${code}TrendChart`] = formatSalesTrendData({ todayInfo })
      strategyInfo[`today${code}`] = todayVal
      todayVal = todayVal ?? 0
      strategyInfo[`today${code}Percentage`] = PacvueKeepDigit(todayTargetVal ? (todayVal / todayTargetVal) * 100 : 0, 2)
      strategyInfo[`today${code}IsHeath`] = todayVal >= todayTargetVal
      //var lastestRealVal = realList[currentHour]?.[propKeyShadow[code].real] ?? 0
      //var lastestTargetVal = preList[currentHour]?.[propKeyShadow[code].target] ?? 0
      //strategyInfo[`today${code}IsHeath`] = lastestRealVal && lastestRealVal >= lastestTargetVal ? true : false //realSales[realSales.length - 1]?.isHeath || false
    }
    //计算targetTotal
    // let dateTimePos = this.dateTimePos
    // let dateDay = this.dateDay
    strategyPerformances.forEach(({ code }) => {
      //let TargetValPercentageStr = strategyInfo?.[`target${code}Percentage`] ?? null
      let customTotalTargetVal = strategyInfo[`target${code}`]

      let totalTargetVal = customTotalTargetVal //getTargetTotalInfo({ dateTimePos, TargetValPercentageStr, totalTargetVal: customTotalTargetVal, curDateDay: dateDay })

      let totalVal = totalInfo?.[code]
      if (code == "Orders") {
        totalVal = totalInfo?.["Conversion"]
      }
      strategyInfo[`totalTarget${code}`] = totalTargetVal
      strategyInfo[`total${code}`] = totalVal
      totalVal = totalVal ?? 0
      totalTargetVal = totalTargetVal ?? 0
      strategyInfo[`total${code}Percentage`] = PacvueKeepDigit(totalTargetVal ? (totalVal / totalTargetVal) * 100 : 0, 2)
      //更新todayInfo
      updateStrategyTodayInfo({ code })
    })
  },
  GetWarRoomStrategyFeadTrendData({ strategyItem }) {
    let activeWarRoom = this.activeWarRoom
    let ProfileId = strategyItem.ProfileId || activeWarRoom.ProfileId
    let campaignIds = []
    try {
      campaignIds = JSON.parse(strategyItem.Campaigns)
    } catch (ex) {
      campaignIds = []
    }
    let TargetTimeStart = strategyItem.TargetTimeStart
    let TargetTimeEnd = strategyItem.TargetTimeEnd
    let toMarket = getCountryCodeByProfile({ profileId: ProfileId })
    let dateTimePos = getStrategeDateTimePos({ TargetTimeStart, TargetTimeEnd, dateTimeVal: this.dateTime })
    if (dateTimePos == "before") {
      return Promise.resolve({})
    } else if (dateTimePos == "between") {
      TargetTimeEnd = this.dateTime
    }
    let query = { campaignTagIds: [], profileIds: ProfileId ? [ProfileId] : [], campaignIds, start: TargetTimeStart, end: TargetTimeEnd, toMarket, isYesterday: 0 }
    return GetWarRoomFeadTrendData(query)
  },
  getStrategyTodayTarget({ strategyItem }) {
    //var TargetSales = strategyItem.TargetSales ?? 0
    let strategyPerformances = this.strategyPerformances
    let result = {}
    let dateDay = this.dateDay
    strategyPerformances.forEach(({ code }) => {
      var TargetPercentage = strategyItem[`target${code}Percentage`]
      //解析每一个时间点的targetSales
      var TargetPercentageJson = {}
      try {
        var TargetPercentageList = JSON.parse(TargetPercentage)
        TargetPercentageJson = TargetPercentageList.reduce((result, item) => {
          result[item.date] = item
          return result
        }, {})
      } catch (ex) {
        TargetPercentageJson = {}
      }
      var todayTargetVal = TargetPercentageJson[dateDay]?.value ?? null
      if (todayTargetVal !== null) {
        result[`target${code}`] = todayTargetVal
      }
    })
    return result
  },
  formatStrategyInfo({ strategyItem, resolve }) {
    strategyItem.isLoading = true
    var self = this
    let GetWarRoomStrategyFeadTrendData = this.GetWarRoomStrategyFeadTrendData

    ;(function (strategyItem) {
      // var TargetSalesPercentage = strategyItem.TargetSalesPercentage
      // //解析每一个时间点的targetSales
      // var TargetSalesPercentageJson = {}
      // try {
      //   var TargetSalesPercentageList = JSON.parse(TargetSalesPercentage)
      //   TargetSalesPercentageJson = TargetSalesPercentageList.reduce((result, item) => {
      //     result[item.date] = item
      //     return result
      //   }, {})
      // } catch (ex) {
      //   TargetSalesPercentageJson = {}
      // }
      // var todayTargetSales = TargetSalesPercentageJson[self.dateDay]?.value ?? null
      let todayTargetInfo = self.getStrategyTodayTarget({ strategyItem })
      Promise.all([
        GetWarRoomStrategyFeadTrendData({ strategyItem }),
        self.getWarRoomSalesTrend({
          currentDate: self.dateTime,
          profileId: self.activeWarRoom?.ProfileId ?? 0,
          campaignIds: strategyItem.Campaigns ? JSON.parse(strategyItem.Campaigns) : [],
          //targetSales: todayTargetSales ?? 0
          ...todayTargetInfo
        })
      ]).then(([totalRes, res]) => {
        //处理total
        var realStrategyItem = self.strategyList.find((item) => item.StrategyId == strategyItem.StrategyId)
        if (!realStrategyItem) {
          resolve && resolve()
          return
        }

        //更新total信息
        self.updateStrategyShowData({ totalInfo: totalRes?.total, strategyInfo: realStrategyItem, todayTargetInfo, todayInfo: res ?? {} })
        //更新total信息END
        realStrategyItem.isLoading = false
        resolve && resolve(realStrategyItem)
      })
      // self
      //   .getWarRoomSalesTrend({
      //     currentDate: self.curToday,
      //     profileId: self.activeWarRoom?.ProfileId ?? 0,
      //     campaignIds: strategyItem.Campaigns ? JSON.parse(strategyItem.Campaigns) : [],
      //     targetSales: todayTargetSales ?? 0
      //   })
      //   .then((res = {}) => {
      //     var realStrategyItem = self.strategyList.find((item) => item.StrategyId == strategyItem.StrategyId)
      //     if (!realStrategyItem) {
      //       resolve && resolve()
      //       return
      //     }
      //     realStrategyItem.isLoading = false
      //     var { realSales = [], totalSales, currentHour = 0, histroySales = [], preSales = [] } = res ?? {}
      //     //需要覆盖匹配当前日期是否有targetSales
      //     realStrategyItem.todayTargetSales = todayTargetSales
      //     // self.currentHour = currentHour //设置当前小时
      //     //totalSales = totalSales ?? 0
      //     realStrategyItem.SalesTrendChart = formatSalesTrendData({ res })
      //     realStrategyItem.todaySales = totalSales
      //     totalSales = totalSales ?? 0
      //     realStrategyItem.percentage = PacvueKeepDigit(todayTargetSales ? (totalSales / todayTargetSales) * 100 : 0, 2)
      //     var lastestRealSales = realSales[realSales.length - 1]?.sales ?? 0
      //     var lastestTargetSales = preSales[realSales.length - 1]?.sales ?? 0
      //     realStrategyItem.isHeath = lastestRealSales >= lastestTargetSales //realSales[realSales.length - 1]?.isHeath || false
      //     resolve && resolve(realStrategyItem)
      //   })
    })(strategyItem)
  },
  getRealTargetSales() {},
  async getWarRoomStrategys(query) {
    try {
      const strategyList = await GetWarRoomStrategys(query)
      return strategyList
    } catch (ex) {
      return []
    }
  },
  async getWarRoomSalesTrend(query) {
    try {
      const res = await GetWarRoomSalesTrend(query)
      return res
    } catch (ex) {
      return {}
    }
  },
  async PacvueGetMaxDateTime(query) {
    var self = this
    var profileIds = query.profileIds || []
    var params = {
      profileIds: profileIds,
      shareId: "",
      toMarket: "US"
    }
    var isFeadTodayId = "isFeadToday_" + JSON.stringify(profileIds)
    var isFeadTodayCache = this.feadTodayCache[isFeadTodayId]
    var startDate = query.start
    var endDate = query.end
    if (isFeadTodayCache) {
      startDate = isFeadTodayCache.startDate ?? dayjs().format("YYYY/MM/DD")
      endDate = isFeadTodayCache.endDate ?? dayjs().format("YYYY/MM/DD")
      return { start: startDate, end: endDate }
    }
    var date = await GetMaxDateTime(params)
    var todayDate = dayjs().format("MM/DD/YYYY HH:mm:ss")
    try {
      var dateObj = dayjs(date, "MM/DD/YYYY HH:mm:ss") //01/01/1970 00:00:00
      if (dateObj.isBefore(dayjs().subtract(365, "day"))) {
        date = dayjs().format("MM/DD/YYYY HH:mm:ss")
      }
    } catch (ex) {
      date = todayDate
    }
    self.currentHour = date //设置当前小时
    var time = new Date(date).getTime()
    var start = new Date(dayjs().format("YYYY/MM/DD")).getTime()
    if (start - 24 * 60 * 60 * 1000 < time || startDate === undefined || endDate === undefined) {
      startDate = date.split(" ")[0]
      endDate = date.split(" ")[0]
    }
    this.feadTodayCache[isFeadTodayId] = { startDate, endDate }
    return { start: startDate, end: endDate }
  }
}
function formatSalesTrendData({ res } = {}) {
  var { realSales = [], currentHour = 0, preSales = [] } = res ?? {}
  //currentHour = 7
  return preSales.map((preSaleInfo, hour) => {
    var realSaleInfo = realSales[hour]
    return {
      ...preSaleInfo,
      Sales: preSaleInfo.sales ?? 0,
      realSales: realSaleInfo?.realSale ?? (hour > currentHour ? null : 0),
      isSupplement: hour == currentHour + 1
    }
  })
}
function formatTrendFeadData({ trendData = [], trendCamparData = [], showProIndexList, isVirtualHour, targetShadow }) {
  //如果实际数据已经存在
  var trendCamparMap = trendCamparData.reduce((result, item) => {
    var hour = item.hour
    result[hour] = item
    return result
  }, {})

  return trendData.map((item) => {
    var hour = item.hour
    var campareData = trendCamparMap[hour] || {}
    let targetData = targetShadow[hour] || {}
    var newItem = { ...item, ...targetData }

    var isVirtual = isVirtualHour(hour)
    newItem.isSupplement = isVirtual
    showProIndexList.forEach((proKey) => {
      var ComparVal = campareData[proKey] ?? 0
      newItem[proKey + "Compar"] = ComparVal
      if (newItem.isSupplement) {
        newItem[proKey] = null
      }
      //newItem[proKey + "Change"] = this.computeChange({ curVal, comparVal: ComparVal })
    })
    return newItem
  })
}
function getCountryCodeByProfile({ profileId } = {}) {
  const commonStore = useCommonStore()
  const profileOptions = commonStore.curStore.profileList ?? []
  var curry = profileOptions.filter((x) => {
    return x.id == profileId
  })[0]?.countryCode
  return curry ?? "US"
}
