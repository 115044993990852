import axios from "axios"

const { VITE_APP_USERURL } = import.meta.env

const _axios = axios.create({
  baseURL: VITE_APP_USERURL,
  timeout: 30000
})

_axios.interceptors.request.use(async function (config) {
  config.headers["productline"] = config.headers?.productline || localStorage.getItem("productline")
  config.headers["language"] = window.locale || localStorage.getItem("locale") || "EN"
  return config
})

export default _axios
