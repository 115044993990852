export const insightTabList = [
  {
    label: $t("Campaign"),
    value: "Campaign",
    acitveTabCode: "Campaign",
    children: [
      {
        label: $t("Campaign"),
        value: "Campaign",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnableCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" },
          { name: $t("Low Bid"), value: 0, color: "#FF9F43", code: "LowBidCount" },
          { name: $t("Low Budget"), value: 0, color: "#EA5455", code: "BudgetShortCount" }
        ]
      }
    ]
  },
  {
    label: $t("Targeting"),
    value: "Targeting",
    acitveTabCode: "Keyword",
    children: [
      {
        label: $t("Keyword"),
        value: "Keyword",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnabledCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" }
        ]
      },
      {
        label: $t("PAT"),
        value: "PAT",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnabledCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" }
        ]
      },
      {
        label: $t("Audience"),
        value: "Audience",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnabledCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" }
        ]
      },
      {
        label: $t("Automatic"),
        value: "Automatic",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnabledCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" }
        ]
      }
    ]
  },
  {
    label: $t("ASIN"),
    value: "ASIN",
    acitveTabCode: "ASIN",
    children: [
      {
        label: $t("ASIN"),
        value: "ASIN",
        top5Data: [],
        loading: true,
        stateList: [
          { name: $t("Enabled"), value: 0, code: "EnabledCount" },
          { name: $t("Paused"), value: 0, code: "PausedCount" }
        ]
      }
    ]
  }
]
