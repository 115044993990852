import { getHave1p3pProfile } from "@/api/ProductCenter/EligibilityInsight"

export default {
  state: () => {
    return {
      channelType: undefined,
      defaultChannel: "",
      channelOption: [],
      currentChannel: ""
    }
  },

  getters: {
    getDefaultChannel: (state) => {
      return state.channelOption?.[0]?.value
    }
  },

  actions: {
    SET_CHANNELTYPE(val) {
      this.channelType = val
    },
    SET_CURRENT_CHANNEL(val) {
      this.currentChannel = val
    },
    async GetHave1p3pProfile() {
      const list = []
      const data = await getHave1p3pProfile()
      this.defaultChannel = (data["1p"] && "Vendor") || "Seller"
      if (data["1p"]) list.push({ label: "Vendor", value: "Vendor" })
      if (data["3p"]) list.push({ label: "Seller", value: "Seller" })
      this.channelOption = list
    }
  }
}
