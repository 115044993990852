import request from "../request"

let { VITE_APP_GPT, VITE_APP_MENU_API, VITE_APP_APIENV, VITE_APP_NODEENV, VITE_APP_BASEURL, VITE_APP_META, VITE_APP_SOV2, VITE_APP_TAGURL, VITE_APP_WALMART_BASE_URL } = import.meta.env
const productLine = window.productline || localStorage.getItem("productline")

/**
 * @deprecated Copilot 1.0 GPT 接口
 */
export function queryfilter(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/queryfilter`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function feedbackApi(data) {
  return request({
    url: `${VITE_APP_MENU_API}user/feedback`,
    method: "post",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function downloadApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/Download/DownloadData`,
    method: "post",
    responseType: "blob",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function CommentQuestionQuery(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/CommentQuestionQuery`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function checkOpenAIkey() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/appkey`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function verifySelfOpenAIkey(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/verifykey`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

// confirm insight
export function confirmInsight() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/enableInsight`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function getSessionId() {
  return request({
    url: `${VITE_APP_GPT}api/id_generator/GetSessionId`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function queryKnowledgeBase(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/kb`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function queryData(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/querydata`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insight`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightTag(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insightTag`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function saveFreshmanGuidanceAction(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/FirstCopilotFeedback`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function getAWSKnowledgeBaseToken() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/getAWSLoginInfo`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function postSaveKnowledgeBaseLog(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/logKb`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

// 获取自然语言补全建议
export function getNaturalLangSuggestion(query, material) {
  return request({
    url: `${VITE_APP_GPT}api/SuggestionWord/GetSuggestions`,
    method: "get",
    params: { query, ...(material ? { material } : {}) },
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

// 获取物料建议
export function getMaterialSuggestion(serviceName) {
  return request({
    url: `${VITE_APP_GPT}api/SuggestionWord/${serviceName}`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function dynamicRequest(urlSuffix, data) {
  let apiPrefix = VITE_APP_GPT
  if (productLine === "dsp") {
    apiPrefix = VITE_APP_BASEURL
  } else if (productLine === "walmart" && !urlSuffix.toLowerCase().includes("sov")) {
    // SOV 相关绩效接口使用 gpt 服务, PD Center、Advertising 使用 walmart 业务服务
    apiPrefix = VITE_APP_WALMART_BASE_URL.replace("/api/", "/")
  }
  return request({
    url: `${apiPrefix}${urlSuffix}`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    cancelToken: true,
    data
  })
}

export function getCampaignTagsAllAmazon(data = {}) {
  return request({
    url: `${VITE_APP_NODEENV !== "production" ? "https://api-test.pacvue.com/meta-api-amazon-dev/" : VITE_APP_META}apply/campaignTagsByProfile`,
    method: "post",
    data: { profileIds: [], ...data },
    cancelToken: true
  })
}

export function getCampaignTagsAllWalmart() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}CampaignTags`,
    method: "get",
    cancelToken: true
  })
}

export function getAdGroupTagsAllAmazon(params) {
  return request({
    url: `${VITE_APP_BASEURL}/Tagging/v3/GetAdGroupTag`,
    method: "get",
    params,
    cancelToken: true
  })
}

export function getKeywordTagsAllAmazon(data = {}) {
  return request({
    url: `${VITE_APP_NODEENV !== "production" ? "https://api-test.pacvue.com/meta-api-amazon-dev/" : VITE_APP_META}apply/keywordTags`,
    method: "post",
    data: { toMarket: "US", ...data },
    cancelToken: true
  })
}

export function getKeywordTagsAllWalmart() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}KeywordTags`,
    method: "get",
    cancelToken: true
  })
}

export function getAsinTagsAllAmazon(params = {}) {
  return request({
    url: `${VITE_APP_SOV2}/AdObject/GetAllAsinTags`,
    method: "get",
    params: { isCheckEdit: undefined, toMarket: undefined, ...params },
    cancelToken: true
  })
}

export function getItemTagsAllWalmart() {
  return request({
    url: `${VITE_APP_WALMART_BASE_URL}ItemTags`,
    method: "get",
    cancelToken: true
  })
}

export function getAdvertisersByProfileIdsDSP(data) {
  return request({
    url: `${VITE_APP_BASEURL}api/DSP/GetDspUserAndProfileAdvertiser`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getCreativeTagsAllDsp(data) {
  return request({
    url: `${VITE_APP_TAGURL}api/permissionCreativeTags`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getLineItemTagsAllDsp(data) {
  return request({
    url: `${VITE_APP_TAGURL}api/permissionLineItemTags`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getOrderTagsAllDsp(data) {
  return request({
    url: `${VITE_APP_TAGURL}api/permissionCampaignTags`,
    method: "post",
    data,
    cancelToken: true
  })
}
