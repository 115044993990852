import request from "@pacvue/frame/request"
import { requestData } from "@/utils/common-util"
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_EventEventUrl = import.meta.env.VITE_APP_EventEventUrl
const VITE_APP_RULEURL = import.meta.env.VITE_APP_RULEURL
const isDev = import.meta.env.VITE_APP_NODE_ENV
export function GetWarRooms(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRooms",
    method: "post",
    data
  })
}
export function InsertAndUpdateWarRoom(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/InsertAndUpdateWarRoom",
    method: "post",
    data
  })
}
export function DeleteWarRoom(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/DeleteWarRoom",
    method: "post",
    data
  })
}
export function GetWarRoomStrategys(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomStrategys",
    method: "post",
    data
  })
}
export function InsertWarRoomStrategy(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/InsertWarRoomStrategy",
    method: "post",
    data
  })
}
export function UpdateRoomStrategy(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/UpdateRoomStrategy",
    method: "post",
    data
  })
}
export function DeleteWarRoomStrategy(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/DeleteWarRoomStrategy",
    method: "post",
    data
  })
}
export function GetWarRoomComparativeData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomComparativeData",
    method: "post",
    data
  })
}
export function InsertAndUpdateWarRoomComparativeData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/InsertAndUpdateWarRoomComparativeData",
    method: "post",
    data
  })
}

export function DeleteWarRoomComparativeData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/DeleteWarRoomComparativeData",
    method: "post",
    data
  })
}
export function GetWarRoomSalesTrend(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomSalesTrend",
    method: "post",
    data
  })
}
export function GetWarRoomFeadTrendData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomFeadTrendData",
    method: "post",
    data
  })
}
export function GetWarRoomFeadTrendCompareData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomFeadTrendCompareData",
    method: "post",
    data
  })
}
export function GetStrategicCenterHolidays() {
  return request({
    url: VITE_APP_BASEURL + "HolidayManager/GetStrategicCenterHolidays",
    method: "get"
  })
}
export function GetWarRoomInsightData(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomInsightData",
    method: "post",
    data
  })
}
export function GetCampaignLabels(data) {
  return requestData({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetCampaignLabels",
    method: "post",
    data
  })
}

export function GetRuleCountByCampaignIds(data) {
  return request({
    url: `${VITE_APP_RULEURL}` + "event/getRuleCountByCampaignIds",
    method: "post",
    data
  })
}
export function GetAutomationTaskLogs(data) {
  return request({
    url: `${VITE_APP_EventEventUrl + "amazon-api"}${isDev.indexOf("production") == -1 ? "-dev" : ""}` + "/getAutomationTaskLogs",
    method: "post",
    data
  })
}
export function GetCampaignAutomationStatisticsInfo(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetCampaignAutomationStatisticsInfo",
    method: "post",
    data
  })
}
export function GetWarRoomCount(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetWarRoomCount",
    method: "post",
    data
  })
}
export function GetTop5AnalysisCampaign(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetTop5AnalysisCampaign",
    method: "post",
    data
  })
}
export function GetTop5Analysis(data) {
  return request({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetTop5Analysis",
    method: "post",
    data
  })
}
export function GetAnalysisInsight(data) {
  return requestData({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetAnalysisInsight",
    method: "post",
    data
  })
}
export function GetHourlyAnalysisInsight(data) {
  return requestData({
    url: VITE_APP_BASEURL + "WarRoom/v3/GetHourlyAnalysisInsight",
    method: "post",
    data
  })
}
