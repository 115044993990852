export default {
  mounted(el, binding) {
    if (binding.value == "Read") {
      el.parentNode && (el.style.display = "none")
      // setTimeout(() => {
      //   el.parentNode && el.parentNode.removeChild(el)
      // }, 2000)
    }
  }
}
