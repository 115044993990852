export var toThousandsxFields = [
  "impression",
  "viewimpression",
  "inbound",
  "reserved",
  "unfulfiable",
  "unfulfillable",
  "impressions",
  "glanceviews",
  "click",
  "clicks",
  "conversion",
  "saleunits",
  "adsaleunits",
  "totalsaleunit",
  "totalorders",
  "reviews",
  "orderedunits",
  "availableinvenroty",
  "unfilledunits",
  "weeksonhand",
  "weeks4onhand",
  "newtobrandorders",
  "clickthroughs",
  "dpv",
  "atc",
  "purchases",
  "unitssold",
  "newtobrandpurchases",
  "newtobrandunitsordered",
  "orders",
  "units",
  "avaliable",
  "sc_orders",
  "sc_orderedunits",
  "ams_impressions",
  "ams_clicks",
  "sc_avaliable",
  "sc_inbound",
  "sc_reserved",
  "kindleeditionnormalizedpagesread",
  "attributed_conversion",
  "attributed_units_ordered",
  "adsaleunitssamesku",
  "adorders",
  "totalpageview",
  "redemptionsUsed",
  'abarank'
]
export var formatPercentFields = [
  "margin",
  "organicoftotal",
  "adsalesoftotal",
  "ctr",
  "cvr",
  "acos",
  "tacos",
  "shareofshelf",
  "organicsov",
  "page1rate",
  "topthreerate",
  "totalspendpercent",
  "newtobrandorderspercentage",
  "newtobrandorderrate",
  "newtobrandsalespercentage",
  "newtobrandunitsorderedpercentage",
  "dpvr",
  "atcr",
  "purchaserate",
  "conversionrate",
  "percentofpurchasesnewtobrand",
  "spendofsales",
  "returnrate",
  "othersalespercent",
  "asoas",
  "adsalessameskuoftotal",
  "shareoftotalrevenue",
  "adrate",
  "adRate"
]
export var fixd2Fields = ["spend", "adspend", "totalrevenue", "profit", "amsrevenue", "organicrevenue", "avgsaleprice", "campaignspend", "sc_orderedrevenue", "avg_attributed_sales", "avg_cost"]
export var fixd2NumFields = ["avg_attributed_units_ordered", "avg_attributed_conversion", "avg_clicks", "avg_impressions"]
Object.defineProperty(Object.prototype, "pacvueDeepCopy", {
  enumerable: false,
  writable: true,
  value: function (isWithFunction) {
    var result = {}
    if (this instanceof Array) {
      result = []
    }
    var keys = Object.keys(this)
    for (var index in keys) {
      var key = keys[index]
      var item = this[key]
      if (item instanceof Date) {
        result[key] = new Date(item)
      } else if (typeof item == "object" && item != null) {
        result[key] = item.pacvueDeepCopy(isWithFunction)
      } else if (typeof item == "function") {
        if (isWithFunction !== false) {
          result[key] = item
        }
      } else {
        result[key] = item
      }
    }
    return result
  }
})
Array.prototype.pacvueDiff = function (compareList, config) {
  config = config || {}
  compareList = compareList || []
  var size = this.length
  var removeItems = [] //移除的
  var newItems = [] //新增的
  var keepItems = [] //仍然存在的
  var compareSize = compareList.length
  var equalFn = config.equalFn
  var isContainNew = config.isContainNew
  for (var i = 0; i < size; i++) {
    var item = this[i]
    var isExist = false
    for (var j = 0; j < compareSize; j++) {
      var compareItem = compareList[j]
      if (typeof equalFn == "function") {
        if (equalFn(item, compareItem)) {
          isExist = true
        }
      } else if (compareItem == item) {
        isExist = true
      }
    }
    if (!isExist) {
      removeItems.push(item)
    } else {
      keepItems.push(item)
    }
  }
  if (isContainNew !== false) {
    config.isContainNew = false
    newItems = compareList.pacvueDiff(this, config).removeItems
  }
  return {
    removeItems: removeItems,
    newItems: newItems,
    keepItems: keepItems
  }
}
function translateProIndex(dataList) {
  let list = []
  dataList.forEach((item) => {
    let lowerProIndex = item.toLowerCase()
    if (!list.includes(lowerProIndex)) {
      list.push(lowerProIndex)
    }
  })
  return list
}
