/* eslint-disable no-undef */
import { datadogRum } from "@datadog/browser-rum"
import { app } from "./frame"
import { datadogRule } from "@pacvue/utils"
import config from "@~/config.js"

const { VITE_APP_NODE_ENV, VITE_APP_APIENV } = import.meta.env
const { ddConfig } = config

// 根据平台变量获取对应的datadog配置信息，Pacvue中只记录正式环境的配置信息
// 测试实例，在业务模块的config文件中单独配置
const applicationInfo = {
  amazon: ["4f2f5d44-ae82-4d2d-9f05-98b5ec4b2cd1", "pubf7f6228b6b3de6a0c63d11e15492d546", "amazon_production"],
  walmart: ["6179d827-2678-4fa2-a2a2-6ae86ef0778a", "pub7189774f881b9c2bcdf9e127c74d2d18", "walmart_production"],
  instacart: ["d3a589c5-c94c-430f-9a42-44aae3e38719", "pub7c507cc540c33778fa957e1434142284", "instacart_production"],
  dsp: ["7e0007fa-6a64-49a2-8e26-e7256590dbd7", "pubc070e53b3b41038f4eecd1c772f6a481", "dsp_production"],
  criteo: ["5ba6d862-1cf5-4efc-ae3f-1c072487686f", "pub23626ecd68b30fcce5e859f8798d987f", "criteo_production"],
  citrus: ["d505da33-8633-4724-87f0-b9e66bd0ee4f", "pube20144d19c4617d0bb850b73625a04b4", "citrus_production"],
  kroger: ["25efe9be-f9b0-4d06-b921-b4714596fe47", "pub9714f040fe89376439e7a815b8e7d425", "kroger2.0_production"],
  samsclub: ["8d0c5d24-31ec-4202-ae10-1d2b17bb2059", "pub17ab0628f4d78929fdbaf4613c819932", "samsclub_production"],
  commerce: ["89d19513-b11a-46de-a703-af5e6e1a5b50", "pubb4b7eac1acb3e8f56eb0026209d18e76", "commerce_production", "23000"]
}

export const initDataDog = (env) => {
  const productline = localStorage.getItem("productline")
  // 测试实例为空，则默认取用正式环境配置
  const configInfo = ddConfig.length === 0 ? applicationInfo[productline] : ddConfig
  if (configInfo?.length >= 3) {
    datadogRum.init({
      applicationId: configInfo[0],
      clientToken: configInfo[1],
      site: "datadoghq.com",
      service: configInfo[2],
      env: `${VITE_APP_NODE_ENV}-${VITE_APP_APIENV}`,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: `${APP_MESSAGE?.version}-${env}` || "Undefined",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      enablePrivacyForActionName: true,
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (event, context) => {
        return datadogRule.errorTrackingRule(event, context)
      }
    })
    // 获取本次会话用户信息
    app.config.globalProperties.$eventHub.on("getUserInfo", (data) => {
      console.log("new user info")
      // 事件总线里过滤掉黑名单用户
      if (configInfo[3]?.includes(data?.userId)) {
        datadogRum?.stopSessionReplayRecording()
        datadogRum?.clearUser()
        return
      }
      datadogRum?.setUser({
        id: data?.userId,
        name: data?.userName,
        email: data?.mail,
        clientId: data?.clientId,
        byPacvuer: data?.byPacvuer || "Real User"
      })
    })

    // 最新版本的datadog默认开启，不需要显示调用
    // datadogRum.startSessionReplayRecording()
  }
}
