import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/AMC/AMCReport",
    component: Main,
    meta: { auth: true, id: -1 },
    // meta: { menu: "AMC", subMenu: "AMC", alias: "AMC_Report", title: ["AMC Console"] },
    redirect: "/AMC/AMCReport",
    // redirect: "/AMC",
    children: [
      {
        path: "/AMC/AMCReport",
        component: () => import("@AMC/views/AMCReport/index.vue"),
        name: "AMC Report",
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          menu: "AMC Report",
          type: "1",
          menuMeta: [{ menuName: "AMC Report" }]
        }
      },
      {
        path: "/AMC/AMCAudience",
        component: () => import("@AMC/views/AMCReport/index.vue"),
        name: "AMC Audience",
        meta: {
          alias: "AMCAudience",
          isShowTime: false,
          showCompareCheck: false,
          menu: "AMC Audience",
          type: "3",
          menuMeta: [{ menuName: "AMC Audience" }]
        }
      },
      {
        path: "/AMC/AMCAccount",
        component: () => import("@AMC/views/AMCReport/index.vue"),
        name: "AMC Account",
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          menu: "AMC Account",
          type: "2",
          menuMeta: [{ menuName: "AMC Account" }]
        }
      },
      {
        path: "/AMC/AMCExploration",
        component: () => import("@AMC/views/AMCReport/AMCExploration.vue"),
        name: "AMC Exploration",
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          menu: "AMC Exploration",
          menuMeta: [{ menuName: "AMC Exploration" }]
        }
      },
      {
        path: "/AMC/AMCHistory",
        name: "AMCHistory",
        meta: {
          alias: "AMC_Report",
          menu: "AMC History",
          subMenu: "AMCHistory",
          title: ["AMC Console", "View History"]
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/viewHistory.vue")
      },
      {
        path: "/AMC/createAMCAudience",
        name: "createAMCAudience",
        meta: {
          menu: "create AMC Audience",
          subMenu: "createAMCAudience",
          title: ["Create AMC Audience"],
          noBreadcrumb: true
        },
        component: () => import("@AMC/views/AMCReport/createAMCAudience.vue")
      },
      {
        path: "/AMC/CreateAMCReport",
        name: "CreateAMCReport",
        meta: {
          menu: "AMC Console",
          alias: "AMC_Report",
          subMenu: "CreateAMCReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateOverviewReport.vue")
      },
      {
        path: "/AMC/CreatePeriodReport",
        name: "CreatePeriodReport",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreatePeriodReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreatePeriodReport.vue")
      },
      {
        path: "/AMC/CreateAudienceReport",
        name: "CreateAudienceReport",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreateAudienceReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/createAudienceReport.vue")
      },
      {
        path: "/AMC/CreateLegoReport",
        name: "CreateLegoReport",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreateLegoReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateLegoReport.vue")
      },
      {
        path: "/AMC/CreateAmazonShoppingInsightsReport",
        name: "CreateAmazonShoppingInsightsReport",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreateLegoReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateAMCAmazonShoppingInsights.vue")
      },
      {
        path: "/AMC/CreateCLTVAnalysis",
        name: "CreateCLTVAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreateAmazonShoppingInsightsReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateAMCAmazonShoppingInsights.vue")
      },
      {
        path: "/AMC/AMCReportResult",
        name: "AMCReportResult",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMC Console",
          title: ["AMC Console"]
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/createReportResult.vue")
      },
      {
        path: "/AMC/AMCReportChart",
        name: "AMCReportChart",
        alias: "/AMC/AMCReportChart",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/chartPage.vue")
      },
      {
        path: "/AMC/AMCPeriodChart",
        name: "AMCPeriodChart",
        alias: "/AMC/AMCPeriodChart",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/PeriodChart.vue")
      },
      {
        path: "/AMC/AMCAudienceChart",
        name: "AMCAudienceChart",
        alias: "/AMC/AMCAudienceChart",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/AudienceChart.vue")
      },
      // {
      //   path: '/AMC',
      //   meta: {
      //     alias: 'AMC_Report',
      //     menu: 'AMC Console',
      //     subMenu: 'AMCReport',
      //     icon: 'PacvueIconLineChart'
      //   },
      //   component: VIEW,
      //   children: [
      {
        path: "/AMC/FunnelDashboard",
        name: "LegoAMCReport",
        alias: "/AMC/AMCReport/LegoAMCReport",
        meta: {
          alias: "AMC_Report",
          menu: "Funnel Trend Report",
          subMenu: "AMCReport",
          noBreadcrumb: true,
          title: ["AMC", "AMCReport", "Funnel Trend Report"]
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/LegoAMCReport.vue")
        //   }
        // ]
      },
      {
        path: "/AMC/AmazonShoppingInsights",
        name: "AmazonShoppingInsights",
        alias: "/AMC/AmazonShoppingInsights",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/AmazonShoppingInsights.vue")
      },
      // {
      //   path: '/AMCConsole',
      //   name: 'AMCConsole',
      //   meta: {
      //     menu: 'AMC Console',
      //     subMenu: 'AMCConsole',
      //     title: ['AMC Console'],
      //   },
      //   component: () =>
      //     import(
      //       /*  webpackChunkName: "AMCConsole" */ '@AMC/views/AMCReport/AMCConsole.vue'
      //     ),
      // },
      // {
      //   path: '/AMCNewInstance',
      //   name: 'AMCNewInstance',
      //   meta: {
      //     menu: 'New Instance',
      //     subMenu: 'NewInstance',
      //     title: ['New Instance'],
      //   },
      //   component: () =>
      //     import(
      //       /*  webpackChunkName: "AMCConsole" */ '@AMC/views/AMCReport/NewInstance.vue'
      //     ),
      // },
      {
        path: "/AMC/AMCInstanceDetail",
        name: "AMCInstanceDetail",
        meta: {
          alias: "AMC_Report",
          menu: "Instance detail",
          subMenu: "NewInstance",
          title: ["New Instance"]
        },
        component: () => import(/*  webpackChunkName: "AMCConsole" */ "@AMC/views/AMCReport/InstanceDetail.vue")
      },
      {
        path: "/AMC/CreateAMCAudience/defaultType",
        name: "CreateAMCAudienceDefaultType",
        meta: {
          menu: "Create AMC Audience",
          subMenu: "CreateAMCAudienceDefaultType",
          title: ["Create AMC Audience"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/CreateAMCAudienceDefaultType.vue")
      },
      {
        path: "/AMC/CreateAMCAudience/:amctype",
        name: "CreateAMCAudienceOverAndTraffic",
        meta: {
          menu: "Create AMC Audience",
          subMenu: "CreateAMCAudienceDefaultType",
          title: ["Create AMC Audience"],
          noBreadcrumb: true
        },
        props: true,
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/CreateAMCAudienceOverAndTraffic.vue")
      },
      {
        path: "/AMC/CreateAMCPaidAudience",
        name: "CreateAMCPaidAudience",
        meta: {
          menu: "Create AMC Audience",
          subMenu: "CreateAMCPaidAudience",
          title: ["Create AMC Audience"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/CreateAMCPaidAudience.vue")
      },
      {
        path: "/AMC/AMCAccount/DataUpload",
        name: "DataUpload",
        meta: {
          alias: "AMC_Report",
          menu: "1P Data Upload",
          subMenu: "NewInstance",
          title: ["New Instance"]
        },
        component: () => import(/*  webpackChunkName: "AMCConsole" */ "@AMC/views/AMCReport/OnePDataUpload.vue")
      },
      {
        path: "/AMC/AMCAccount/DataUpload/index",
        name: "DataUpload/index",
        redirect: "/AMC/AMCAccount/DataUpload",
        meta: {
          alias: "AMC_Report",
          menu: "1P Data Upload",
          subMenu: "NewInstance",
          title: ["New Instance"]
        },
        component: Main
      },
      {
        path: "/AMC/AMCAccount/CreateDataUpload",
        name: "CreateDataUpload",
        meta: {
          icon: "PacvueIconCommerceCatalog",
          parent: "DataUpload/index",
          alias: "AMC_Report",
          menu: "Data Upload Setting",
          subMenu: "Data Upload Setting",
          title: ["AMC Account"]
        },
        component: () => import(/*  webpackChunkName: "AMCConsole" */ "@AMC/views/AMCReport/CreateDataUpload.vue")
      },
      {
        path: "/AMC/AMCAccount/DataUploadLog",
        name: "DataUploadLog",
        meta: {
          parent: "DataUpload/index",
          icon: "PacvueIconCommerceCatalog",
          alias: "AMC_Report",
          menu: "Data Upload Log",
          subMenu: "Data Upload Log",
          title: ["AMC Account"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCConsole" */ "@AMC/views/AMCReport/DataUploadLog.vue")
      },
      {
        path: "/AMC/CreateOnePDataAnalysis",
        name: "CreateOnePDataAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreateOnePDataAnalysis",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateOnePDataAnalysis.vue")
      },
      {
        path: "/AMC/OnePDataAnalysis",
        name: "OnePDataAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/OnePDataAnalysisReport.vue")
      },
      {
        path: "/AMC/GroupMChart",
        name: "GroupMChart",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/GroupMReport.vue")
      },
      {
        path: "/AMC/CLTVAnalysis",
        name: "CLTVAnalysisReport",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/*  webpackChunkName: "AMCReport" */ "@AMC/views/AMCReport/CLTVAnalysisReport.vue")
      },
      {
        path: "/AMC/CreateGroupMAMCInsights",
        name: "CreateGroupMAMCInsights",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreatePeriodReport",
          title: ["AMC Console"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreatePeriodReport.vue")
      },
      {
        path: "/AMC/oauth2/redirect",
        name: "oauth",
        meta: {
          alias: "AMC_oauth",
          menu: "AMC oauth",
          subMenu: "oauth",
          title: ["AMC oauth"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/Oauth.vue")
      },
      {
        path: "/AMC/AMCReport/OnePDemographicAnalysis",
        name: "OnePDemographicAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"]
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/OnePDemographicAnalysis.vue")
      },
      {
        path: "/AMC/AMCReport/CreateSTVEffectAnalysis",
        name: "CreateSTVEffectAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "CreatePeriodReport",
          title: ["AMCReport"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/CreateOverviewReport.vue")
      },
      {
        path: "/AMC/AMCReport/STVEffectAnalysis",
        name: "STVEffectAnalysis",
        meta: {
          alias: "AMC_Report",
          menu: "AMC Console",
          subMenu: "AMCReport",
          title: ["AMCReport"]
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/STVEffectAnalysisCharts.vue")
      },
      {
        path: "/AMC/instancecreation",
        name: "instancecreation",
        meta: {
          alias: "AMC_Instancecreation",
          menu: "AMC Instancecreation",
          subMenu: "AMCInstancecreation",
          title: ["AMCInstancecreation"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/Instancecreation/index.vue")
      },
      {
        path: "/AMC/CreateInstance",
        name: "CreateInstance",
        meta: {
          alias: "AMC_CreateInstance",
          menu: "AMC CreateInstance",
          subMenu: "AMCCreateInstance",
          title: ["AMCCreateInstance"],
          noBreadcrumb: true
        },
        component: () => import(/* webpackChunkName:"AMCReport" */ "@AMC/views/AMCReport/Instancecreation/CreateInstance.vue")
      }
    ]
  }
}
