<template>
  <a class="logo" aria-label="site logo" accesskey="1">
    <svg class="main-part" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 130 68" width="520px" height="272px" xml:space="preserve">
      <path
        fill="#00078F"
        d="M67.1 33.2c0 3.8-1.3 5.5-4.2 5.5-2.9 0-4.2-1.7-4.2-5.5V22.7h-3.9v10.9c0 5.6 2.9 8.7 8 8.7s8-3.1 8-8.7V22.7H67v10.5zM88.5 32.2c0 4.6-3 6.7-5.9 6.7-3.8 0-5.8-3.3-5.8-6.7 0-4.3 2.9-6.6 5.8-6.6 3.5 0 5.9 2.7 5.9 6.6m-5.2-10.1c-2.6 0-4.9 1.2-6.5 3.5v-2.9H73V50h3.8V38.9c1.6 2.2 3.8 3.4 6.3 3.4 2.7 0 5.1-1.1 6.7-3.1 1.5-1.8 2.3-4.4 2.3-7.1 0-2.6-.9-5-2.4-6.9-1.5-2-3.9-3.1-6.4-3.1M35.7 23.1c-1-.6-2-1-3.1-1-2.3 0-4.1 1.2-5.1 3.4v-2.8h-3.8v18.9h3.9v-9.3c0-3 .5-6.5 4.2-6.5.5 0 1 .1 1.5.3l2.4-3zM17.8 22.7v3c-1.6-2.3-3.8-3.6-6.5-3.6-2.5 0-4.9 1.1-6.5 3-1.5 1.8-2.4 4.3-2.4 6.9 0 2.8.8 5.3 2.3 7.1 1.6 2 4 3.1 6.7 3.1 2.5 0 4.7-1.2 6.3-3.4v2.8c0 3.3-.6 6.7-5.2 6.7-3.4 0-5.5-2-5.5-5.1v-.1H3.3v.1c.1 5.1 3.8 8.6 9.1 8.6 8.2 0 9.2-6 9.2-11.2v-18h-3.8zm-6 16.1C8 38.8 6 35.5 6 32.1c0-4.3 2.9-6.6 5.8-6.6 3.5 0 5.9 2.7 5.9 6.6.1 4.7-2.9 6.7-5.9 6.7zM52.7 28.1c-.4-1.2-1-2.3-1.9-3.2-.9-.9-1.9-1.6-3-2.1-2.6-1-5.6-1-8.2 0-1.1.5-2.2 1.2-3 2.1-.8.9-1.5 2-1.9 3.2-.4 1.3-.7 2.7-.6 4.1 0 1.4.2 2.8.7 4.1.4 1.2 1 2.3 1.9 3.2.9.9 1.9 1.6 3 2.1 2.6 1 5.6 1 8.2 0 1.1-.5 2.2-1.2 3-2.1.8-.9 1.5-2 1.9-3.2.4-1.3.7-2.7.7-4.1-.1-1.4-.4-2.8-.8-4.1zm-6.5 10.5c-1.6.8-3.6.8-5.2 0-.7-.4-1.3-1-1.8-1.6-.5-.7-.8-1.5-1-2.3-.4-1.7-.4-3.4 0-5.1.2-.8.6-1.6 1-2.3.5-.7 1.1-1.2 1.8-1.6 1.6-.8 3.6-.8 5.2 0 .7.4 1.3 1 1.8 1.6.5.7.8 1.5 1 2.3.4 1.7.4 3.4 0 5.1-.2.8-.6 1.6-1 2.3-.4.7-1 1.2-1.8 1.6z"
      ></path>
    </svg>
    <div class="secondary-part">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.7 17.5" width="74.8" height="70">
        <path d="M12.8 17.5l.6-2V7.8l.1-1-.4 1-4 9.8H9L4.6 6.8l.1 1v7.8l.6 2H0l.6-2V2L0 0h6.3l2.9 7.1.1.6.1-.6L12.3 0h6.4L18 2v13.5l.6 2h-5.8z" fill="#0A2FFF"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.5 17.6" width="74" height="70.4">
        <path d="M2.4 15.2h13.8V5.1L9.3 11 2.4 5.1v10.1zm16.1 2.4H0V0l9.3 7.9L18.5 0v17.6z" fill="#0A2FFF"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.8 16.9" width="79.2" height="67.6">
        <path
          d="M7.9.4C6.2-.1 4.6-.2 3.5.4c-1.4.8-4.9 8-2.9 13.8.2.7.6 1.2 1.3 1.4.6.2 1.2.3 1.9.3 1.2-.1 3.6-.5 3.7-1.8s-1-5.1 1.4-.3c.3.5 1.2.4 1.7.2s.3-2.7 2.1-2.6c.2 0-1.9 2.9-.2 4 1 .7 2.8 1.7 4.1 1.4 2.9-.8 3.2-4.5 3.1-6.9-.1-2.5-.2-5.9-2.4-7.7-1.1-.9-2.9-1-3.9-.2-.6.5-2.8 2-3.2 2 0 0-.6-3.1-2.3-3.6"
          fill="#0A2FFF"
        ></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.7 16.8" width="74.8" height="67.2">
        <path
          d="M18.7.5v15.7c0 .2 0 .3-.2.4s-.2.2-.4.2h-3.5c-.1 0-.3 0-.4-.2s-.2-.2-.2-.4V8.8c0-.1-.1-.2-.2-.3s-.3 0-.3.2L9.7 15c-.1.1-.2.2-.4.2s-.3-.1-.4-.2L5.1 8.8c-.1-.2-.2-.2-.3-.2s-.2.1-.2.3v7.4c0 .2-.1.3-.2.4s-.3.1-.4.1H.6c-.1 0-.3 0-.4-.2-.1-.1-.2-.2-.2-.4V.5A.76.76 0 0 1 .2.1C.3 0 .4 0 .6 0h3.2c.1 0 .3 0 .5.1a1.38 1.38 0 0 1 .4.3L9 6.9c.1.1.2.2.3.2s.2-.1.3-.2L14 .4c.1-.1.2-.2.4-.3s.4-.1.5-.1h3.2c.2 0 .3 0 .4.2.1.1.2.2.2.3z"
          fill="#0A2FFF"
        ></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 18" width="102" height="72"><path d="M19.2 0l-6.4 7.1L6.4 0 0 7.1V18l6.4-7.2 6.4 7.2 6.4-7.2 6.3 7.2V7.1z" fill="#0A2FFF"></path></svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 16.5" width="82" height="66">
        <path d="M20.5 0h-3.9l-6.1 7-3.4-7H3.2L0 16.5h3.9l1.8-9.2 2.6 5.3.8 1.7 6-7-1.8 9.2h4z" fill="#0A2FFF"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.7 17.3" width="126.8" height="69.2">
        <path
          d="M30.3 12.7c-1.6.7-3.2 1.1-4.4 1.1-1 0-1.7-.2-2-.7-.4-.5-.5-1.3-.2-2.5l.9-4.3c.5-2.1.3-3.7-.6-4.8-.8-1-2-1.4-3.6-1.4-1.8 0-3.4.6-5 1.9C14.7.7 13.5.1 11.6.1c-1.5 0-3 .5-4.4 1.6C6.6.7 5.1 0 3.4 0 2.5 0 1.6.2.8.5L.6.6 0 3.7l.8-.3c1.2-.5 2.1-.5 2.5.1.3.4.4 1 .2 1.8l-2.2 9.3h3.8l1.5-6.5c.8-3.2 2.2-4.7 4.2-4.7.5 0 .9.2 1.2.5.3.4.4 1.2.1 2.3l-1.9 8.3H14l1.8-7.6c.5-2.1 2.1-3.5 3.9-3.5.6 0 1 .2 1.3.5.3.4.4 1.2.1 2.3L20 10.9c-.4 1.9-.2 3.5.8 4.6s2.5 1.8 4.5 1.8c1.8 0 3.5-.5 5.1-1.2l.2-.1.7-3.6-1 .3z"
          fill="#0A2FFF"
        ></path>
      </svg>
    </div>
  </a>
</template>

<style lang="scss" scoped>
/* svg:not(:root) {
  overflow: hidden;
  overflow-clip-margin: content-box;
} */
.logo {
  position: relative;
  color: rgba(10, 39, 86, 0.8);
  display: inline-block;
  vertical-align: top;
  width: 520px;
  height: 280px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  .main-part {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
  }
  .secondary-part {
    position: absolute;
    right: 56px;
    top: 44px;
    width: 88px;
    height: 100%;
    svg {
      position: absolute;
      top: 18px;
      left: 0;
      width: 80px;
      opacity: 0;
      transform: translateY(5px);
      will-change: transform;
    }
    @keyframes fade-up-1 {
      0%,
      15.5%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }

      2.5% {
        opacity: 1;
      }
      12.5% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    @keyframes fade-up-2 {
      0%,
      12.5%,
      28%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      15% {
        opacity: 1;
      }
      25% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    @keyframes fade-up-3 {
      0%,
      25%,
      40.5%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      27.5% {
        opacity: 1;
      }
      37.5% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    @keyframes fade-up-4 {
      0%,
      37.5%,
      53%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      40% {
        opacity: 1;
      }
      50% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @keyframes fade-up-5 {
      0%,
      50%,
      65.5%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      52.5% {
        opacity: 1;
      }
      62.5% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @keyframes fade-up-6 {
      0%,
      62.5%,
      78%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      65% {
        opacity: 1;
      }
      75% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @keyframes fade-up-7 {
      0%,
      75%,
      100% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
      }
      77.5% {
        opacity: 1;
      }
      87.5%,
      97.5% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    svg:nth-of-type(1) {
      animation: fade-up-1 5s ease infinite;
    }
    svg:nth-of-type(2) {
      animation: fade-up-2 5s ease infinite;
    }
    svg:nth-of-type(3) {
      animation: fade-up-3 5s ease infinite;
    }
    svg:nth-of-type(4) {
      animation: fade-up-4 5s ease infinite;
    }
    svg:nth-of-type(5) {
      width: 100px;
      animation: fade-up-5 5s ease infinite;
    }
    svg:nth-of-type(6) {
      animation: fade-up-6 5s ease infinite;
    }
    svg:nth-of-type(7) {
      width: 142.8px;
      animation: fade-up-7 5s ease infinite;
    }
  }
}
</style>
