import useAppStore from "@pacvue/frame/frameStore"
import useUserStore from "@pacvue/frame/user"
import PDcenter from "@/views/ProductCenter/PDcenterStore"
import common from "./common/index"
import amazon from "./amazon/index"
import walmart from "./walmart/index"
import event from "./event/index"
import { useStoreModule } from "./util"

const modules = { common, amazon, walmart, event, PDcenter }
var { useCommonStore, useAmazonStore, useWalmartStore, useEventStore, usePDcenterStore } = useStoreModule(modules)

export { useAppStore, useUserStore, useCommonStore, useAmazonStore, useWalmartStore, useEventStore, usePDcenterStore }
