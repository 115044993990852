import { dayjs } from "@pacvue/element-plus"
export function getStrategeDateTimePos({ TargetTimeStart, TargetTimeEnd, dateTimeVal, dateFormat = "YYYY/MM/DD" } = {}) {
  if (dateTimeVal && TargetTimeStart && TargetTimeEnd) {
    let timeStart = dayjs(TargetTimeStart, dateFormat)
    let timeEnd = dayjs(TargetTimeEnd, dateFormat)
    let curDate = dayjs(dateTimeVal, dateFormat)
    if (curDate.isBefore(timeStart)) {
      return "before"
    } else if (curDate.isAfter(timeEnd)) {
      return "after"
    }
  }
  return "between"
}
export function getTargetTotalInfo({ dateTimePos = "between", TargetValPercentageStr, totalTargetVal = 0, curDateDay }) {
  if (dateTimePos == "before") {
    return null
  } else if (dateTimePos == "after") {
    return totalTargetVal
  } else {
    let total = 0
    try {
      let TargetPercentageList = JSON.parse(TargetValPercentageStr)
      if (Array.isArray(TargetPercentageList) && TargetPercentageList.length) {
        for (var i = 0, size = TargetPercentageList.length; i < size; ++i) {
          let item = TargetPercentageList[i]
          let date = item.date
          let value = item.value ?? 0
          total += value
          if (date == curDateDay) {
            break
          }
        }
      }
    } catch (ex) {
      return 0
    }
    return total
  }
}
