import { useUserStore } from "@/store"
import { isCN, isEU, isProd } from "../constants/env"

const AppcuesPage = () => {
  if (sessionStorage.getItem("useInfo")) {
    const userStore = useUserStore()
    let userInfo = JSON.parse(sessionStorage.getItem("useInfo"))
    const setting = userStore?.userSettings
    const baseInfo = {
      Userroles: userInfo.userRole,
      preference: setting?.randA,
      Theme: setting?.themeColour,
      UserName: userInfo.userName,
      accessPlatforms: userInfo.availablePlatforms,
      serverRegion: isCN ? "CN" : isEU ? "EU" : "US"
    }
    userInfo = Object.assign(userInfo, baseInfo)
    if (userInfo.userId) {
      window.Appcues?.identify(userInfo.userId, userInfo)
      window.Appcues?.group(userInfo.clientId, { name: userInfo.mail ? userInfo.mail.split("@")[1] : String(userInfo.clientId) })
    }
    window.Appcues?.page()
  } else {
    // window.Appcues?.anonymous()
  }
}

const loadAppcues = () => {
  if (isProd && !isCN) {
    const script = document.createElement("script")
    script.src = `https://fast.appcues.com/145456.js`
    script.type = "text/javascript"
    script.async = true
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(script, firstScript)
    script.onload = () => {
      setTimeout(() => {
        for (const el of document.getElementsByTagName("script")) {
          if (el.getAttribute("integrity") && el.getAttribute("crossorigin")) {
            el.addEventListener("load", () => AppcuesPage())
          }
        }
      }, 200)
    }
  }
}

export { AppcuesPage, loadAppcues }
