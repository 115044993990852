import { usePDcenterStore } from "@/store"
export function useRouterInterceptor({ mode = "Dashboard" } = {}) {
  return {
    beforeEnter(to, from, next) {
      const PDcenterStore = usePDcenterStore()
      if (PDcenterStore.channelType == 0) {
        next("/ProductCenter/ProductNonVC")
      } else {
        next()
      }
    }
  }
}
