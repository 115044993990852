import { GetWarRoomCount } from "@/api/Optimization/strategic"
import useWarRoomStore from "@/views/Optimization/WarRoom/store"
import { commonJS } from "@pacvue/utils"
export function useRouterInterceptor() {
  return {
    beforeEnter(to, from, next) {
      var warRoomStore = useWarRoomStore()
      handleToPageQuery({ to, warRoomStore })
      if (!warRoomStore.warRoomList?.length) {
        GetWarRoomCount({})
          .then((res) => {
            if (!res) {
              next("/Optimization/LiveMomentumCreate")
            } else {
              next()
            }
          })
          .catch((ex) => {
            next("/Optimization/LiveMomentumCreate")
          })
      } else {
        next()
      }
    }
  }
}
function handleToPageQuery({ to, warRoomStore }) {
  let routerQuery = commonJS.PacvueGetPageQuery({
    query: to?.query?.query,
    isCompression: true
  })
  if (routerQuery) {
    let tabCode = routerQuery.tabCode
    let kindType = routerQuery.kindType
    warRoomStore.defaultTabCode = tabCode ?? "Analysis"
    warRoomStore.defaultStrategyTabCode = kindType ?? "Campaign"
    warRoomStore.defaultActiveStrategyId = routerQuery.StrategyId
    warRoomStore.activeStrategy = {
      StrategyId: routerQuery.StrategyId,
      StrategyName: routerQuery.StrategyName,
      isVirtual: true
    }
    warRoomStore.includeZero = true
    warRoomStore.selectedCampLabels = routerQuery.campaignLableIds ?? []
    if (routerQuery.SelectedDateTime) {
      warRoomStore.queryDateTime = routerQuery.SelectedDateTime
    }
  }
}
