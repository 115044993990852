import request from "@pacvue/frame/request"
import { PacvueCreateFilterStoreKey } from "@/utils/common-util"
const { VITE_APP_PRODUCT_CENTER, VITE_APP_PRODUCT_3P, VITE_APP_SOV2, VITE_APP_META } = import.meta.env

export function getRevenuePerformanceAndSearchAdROI(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getRevenuePerformanceAndSearchAdROI`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getRevenueAnalysis(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getRevenueAnalysis`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getAllBrand(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getAllBrand`,
    method: "post",
    data: data
  })
}

export function getAllCategory(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getAllCategory`,
    method: "post",
    data: data
  })
}

export function getAllProfile(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getAllProfile`,
    method: "post",
    data: data
  })
}

export function getAllVendorAccount(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getAllVendorAccount`,
    method: "post",
    data: data
  })
}

export function getAsinEfficiency(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getAsinEfficiency`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getTagRepeatPurchaseRoas(data) {
  return request({
    url: `${VITE_APP_SOV2}Product/GetTagRepeatPurchaseRoasChart`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

// https://productcenterapitest.pacvue.com/api/Product/GetTagRepeatPurchaseRoasChart

// getAsinEfficiency

export function getVendorAndSellerProfile(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}profile/getVendorAndSellerProfile`,
    method: "post",
    data: data
  })
}

export function getAllAsinTags() {
  return request({
    url: `${VITE_APP_SOV2}AdObject/GetAllAsinTags?toMarket=US`,
    method: "get"
  })
}

export function getTop50Asin(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getTop50Asin`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTop50AsinChart(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getTop50AsinChart`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getNetPpm(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getNetPpm`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTop5Movers(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getTop5Movers`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTagRepeatPurchaseRoasChart(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getTagRepeatPurchaseRoasChart`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getCampaignTags(data) {
  return request({
    url: `${VITE_APP_META}apply/campaignTagsByProfile`,
    method: "post",
    data
  })
}

export function getInventoryAndForecast(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getInventoryAndForecast`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getPerformanceChart(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getPerformanceChart`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getAllocationData(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getAllocationData`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getRealTimeFor1P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getRealTime`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getHourlySalesFor1P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboard/getHourlySales`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getAllocationDataFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getAllocationByCategoriesTotal`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getOrdersFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getOrders`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getHourlySalesFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getHourlySales`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getBrandPerformanceFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getBrandPerformance`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getAsinEfficiencyFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getAsinEfficiency`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getAsinsByAsinTagFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getAsinsByAsinTag`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getRealTimeFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getRealTime`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getSalesByMarketFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getSalesByMarket`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getDashboardRevenueAnalysisFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getDashboardRevenueAnalysis`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTopAsinChartFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getTopAsinChart`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function revenuePerformanceSearchROIFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/revenuePerformanceSearchROI`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTopAsinFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getTopAsin`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getTopFiveMoversFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getTopFiveMovers`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getInventoryFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getInventory`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function dashboardDownload(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboardDownload`,
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function downloadDashboardFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/downloadDashboard`,
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

//
export function getUserPermission(data) {
  const listStoreKey = PacvueCreateFilterStoreKey({ storeKey: "ProductDashboardChannel" })
  const dashboardStoreKey = PacvueCreateFilterStoreKey({ storeKey: "ProductListChannel" })
  return new Promise((resolve) => {
    request({
      url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getUserPermission`,
      method: "post",
      data
    }).then((res) => {
      const { type } = res ?? {}
      let listChannel = localStorage.getItem(listStoreKey)
      let dashboardChannel = localStorage.getItem(dashboardStoreKey) //1,3,4
      let channels = []
      if (type === 1 || type === 2) {
        channels =
          type === 1
            ? [
                {
                  label: $t("key846"),
                  value: "Vendor",
                  channel: 1
                }
              ]
            : [
                {
                  label: $t("key846"),
                  value: "Vendor",
                  channel: 1
                },
                {
                  label: $t("key702"),
                  value: "Seller",
                  channel: 3
                },
                {
                  label: $t("amskey2310"),
                  value: "Hybrid",
                  channel: 4
                }
              ]
      } else if (type === 3) {
        channels = [
          {
            label: $t("key702"),
            value: "Seller",
            channel: 3
          }
        ]
      }
      let config = {
        list: false,
        dashboard: false
      }
      for (let i = 0, size = channels.length; i < size; i++) {
        let { value, channel } = channels[i]
        if (listChannel == channel) {
          config.list = true
        }
        if (dashboardChannel == value) {
          config.dashboard = true
        }
      }
      if (!config.list) {
        localStorage.setItem(listStoreKey, type == 1 || type == 2 ? "Vendor" : "Seller")
      }
      if (!config.dashboard) {
        localStorage.setItem(listStoreKey, JSON.stringify(type == 1 || type == 2 ? 1 : 3))
      }
      resolve(res)
    })
  })
}

export function getProfileFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}profile/getProfile`,
    method: "post",
    data
  })
}

export function getBrandFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}profile/getBrand`,
    method: "post",
    data
  })
}

export function getShareLink(data) {
  return request({
    url: `${VITE_APP_SOV2}ProductShare/GetShareLink`,
    method: "post",
    data
  })
}

export function getShareLinkList(data) {
  return request({
    url: `${VITE_APP_SOV2}ProductShare/GetShareLinkList`,
    method: "post",
    data
  })
}

export function getAsinsByAsinTag(data) {
  return request({
    url: `${VITE_APP_SOV2}Product/GetAsinsByAsinTag`,
    method: "post",
    data
  })
}

export function getSalesByAdType(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/getSalesByAdType`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getSalesByAdTypeFor3P(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getTotalADSalesByType`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getVendorProfile(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getVendorProfile`,
    method: "post",
    data,
    cancelToken: true
  })
}

// https://api.pacvue.com/commerce-admin-3p-product-center/productCenterDashboard/getTotalADSalesByType

// VITE_APP_SHARE
// getBrand
// VITE_APP_SOV2
// https://productcenterapitest.pacvue.com/api/Product/GetAsinsByAsinTag

// downloadDashboard

// dashboardDownload

// getInventory
// getTopFiveMovers
// getTopAsin
// revenuePerformanceSearchROI
// getTopAsinChart

// getDashboardRevenueAnalysis

// getSalesByMarket
// getRealTime

// getAsinsByAsinTag
// getAsinEfficiency
// getBrandPerformance
// getAllocationData
// getPerformanceChart
// /productCenterDashboard/getOrders

// getInventoryAndForecast
// VITE_APP_SOV2

export function getHybirdProfile(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getHybridProfile`,
    method: "post",
    data,
    cancelToken: true
  })
}

export function getHybirdBrand(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcCommon/getHybridBrand`,
    method: "post",
    data,
    cancelToken: true
  })
}
export function getHybridRevenuePerformanceAndSearchAdROI(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridRevenuePerformanceAndSearchAdROI`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridRevenueAnalysis(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridRevenueAnalysis`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridSalesByAdType(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridSalesByAdType`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridTop50Asin(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridTop50Asin`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridTop50AsinChart(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridTop50AsinChart`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridTop5Movers(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridTop5Movers`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridAllocationData(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridAllocationData`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridPerformanceChart(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridPerformanceChart`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function getHybridAsinEfficiency(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/getHybridAsinEfficiency`,
    method: "post",
    data: data,
    cancelToken: true
  })
}

export function hybriddashboardDownload(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/dashboardDownload`,
    method: "post",
    data: data,
    responseType: "blob",
    cancelToken: true
  })
}

export function dashboardDownloadShare(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}pcDashboard/dashboardDownload/share`,
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function downloadDashboardFor3PShare(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/downloadDashboard/share`,
    method: "post",
    data,
    responseType: "blob",
    isOnlyData: false
  })
}

export function hybriddashboardDownloadShare(data) {
  return request({
    url: `${VITE_APP_PRODUCT_CENTER}hybrid/dashboard/dashboardDownload/share`,
    method: "post",
    data: data,
    responseType: "blob",
    cancelToken: true
  })
}
