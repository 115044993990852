import { isRef, unref, watchEffect } from "vue"
import { useRoute } from "vue-router"
import { isFunction, isEmpty } from "lodash-es"
import config from "@~/config.js"
import { useUserStore } from "@/store"
import * as analytics from "@~/analytics"
import { isCN } from "../../constants/env"

function getProductLine() {
  const productline = localStorage.getItem("productline")
  return productline
}

// see https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars
//1. Imports must start with ./ or ../.
//2. Imports must end with a file extension
//3. Imports to your own directory must specify a filename pattern
/**
 *
 * @returns 当前productLine对应的埋点配置
 */
export function getConfig() {
  return analytics
}

const getServerRegion = () => {
  const config = getConfig()
  const host = window.location.host
  return config.hostRegionMapper?.[host] ?? "COM"
}

const getByPacvuer = () => {
  const productline = getProductLine()
  if (productline === "retailer") {
    const byPacvuer = localStorage.getItem("byPacvuer")
    if (byPacvuer === "null" || byPacvuer === "undefined" || byPacvuer === null) {
      return "NO"
    }
    return localStorage.getItem("byPacvuer") || "NO"
  } else {
    const userStore = useUserStore()
    const userInfo = userStore?.user
    if (userInfo?.byPacvuer === "null" || userInfo?.byPacvuer === "undefined" || userInfo?.byPacvuer === null) {
      return "NO"
    }
    return userInfo?.byPacvuer || "NO"
  }
}

/**
 * 用户属性的埋点方法
 */
export const analyticsIdentify = async () => {
  const profileList = JSON.parse(sessionStorage.getItem(`profileList`) || "{}")
  const userStore = useUserStore()
  const userInfo = userStore?.user
  const setting = userStore?.userSettings
  const region = await getServerRegion()
  if (userInfo?.userId) {
    let userID = String(userInfo?.userId).padStart(5, "0")
    const hostArr = ["CN", "EU"]
    if (hostArr.includes(region)) {
      userID = region + userID
    }
    const byPacvuer = getByPacvuer()
    useAnalyticsIdentify(userID, {
      byPacvuer,
      Email: userInfo?.mail ?? null,
      Userid: userID,
      "User Name": userInfo?.userName,
      "User roles": userInfo?.userRole,
      "access platforms": userInfo?.availablePlatforms,
      "Managered profiles": profileList?.length, // commerce 没有 Managed profiles
      "Server Region": region,
      preference: setting?.randA,
      Theme: setting?.themeColour
    })
    if (!isCN) {
      useAnalyticsGroup(userInfo.clientId, {
        name: userInfo.mail ? userInfo.mail.split("@")[1] : String(userInfo.clientId)
      })
    }
    window.__analyticsIdentify__ = true
  }
}

/**
 * @deprecated
 * @param {boolean} drawerExplorerModel
 * @param {string[]} dateDetailProfileIds
 */
export const useWatchAdvertisingTagDetail = (drawerExplorerModel, dateDetailProfileIds) => {
  const config = getConfig()
  const route = useRoute()
  const request = () => {
    const name = route.name
    if (name && unref(drawerExplorerModel)) {
      const pageName = `${config.ANALYTICS_PAGE_MAPPER[name] ?? name} details`
      useAnalyticsPage(pageName, dateDetailProfileIds ? { "profile count": unref(dateDetailProfileIds).length } : {})
      if (config.analytics) {
        window?.analytics?.page("Browse", pageName, dateDetailProfileIds ? { "profile count": unref(dateDetailProfileIds).length } : {})
      }
    }
  }
  isRef(drawerExplorerModel) ? watchEffect(request) : request()
}

/**
 * 用于自定义事件的埋点
 * @param {string | import("@vue/reactivity").Ref<string>} name - 事件的名称
 * @param {object | import("@vue/reactivity").Ref<object>} properties - 事件的属性
 * @param {function} callback - 埋点请求完成后回调函数
 */
export const useAnalyticsTrack = async (name, properties, callback = () => {}) => {
  const productline = getProductLine()
  const request = async () => {
    try {
      if (!isFunction(window?.analytics?.track)) {
        callback()
        return
      }
      if (name && config.analytics) {
        let commonProperties = { ...unref(properties) }
        // 特殊处理 retailer
        if (["retailer"].includes(productline)) {
          const srcProductLine = localStorage.getItem("navSrcPlt")
          if (srcProductLine) {
            commonProperties = { ...commonProperties, "sourcing platform": srcProductLine }
          }
        } else if (!isCN) {
          if (productline) {
            commonProperties = { ...commonProperties, platform: productline }
          }
        }
        if (productline && productline !== "retailer") {
          window.analytics.track(productline.charAt(0).toUpperCase() + productline.slice(1) + " " + unref(name), commonProperties)
        } else {
          window.analytics.track(unref(name), commonProperties)
        }
        setTimeout(() => {
          callback()
        }, 200)
      } else {
        callback()
        return
      }
    } catch (e) {
      console.error(e)
      callback()
    }
  }
  isRef(name) || isRef(properties) ? watchEffect(request) : request()
}

/**
 * 用于自定义页面的埋点
 * @param {string | Ref<string> } name - 页面的名称
 * @param {object | Ref<object> } properties - 页面属性
 * @param {boolean} force - 是否强制触发，使用强制触发会不考虑IGNORE_ANALYTICS_PAGELIST
 * @param {function} callback - 埋点请求完成后回调函数
 * @returns 埋点是否成功
 */
export const useAnalyticsPage = async (name, properties = {}, force = false, callback = () => {}) => {
  const analyticsConfig = getConfig()
  const ignorePageList = analyticsConfig.IGNORE_ANALYTICS_PAGELIST
  const productline = getProductLine()
  let response
  const request = async () => {
    try {
      const filterPageList = analyticsConfig.ANALYTICS_PAGELIST.map((item) => (item.startsWith("View") ? item.replace(/^View /, "") : item))
      if (!isFunction(window?.analytics?.page)) {
        callback()
        return
      }
      if (!unref(name) || !config.analytics) {
        callback()
        return
      }

      if (force && isEmpty(unref(properties))) {
        callback()
        return
      }

      if (!force && ignorePageList.includes(name)) {
        callback()
        return
      }
      if (filterPageList.includes(unref(name))) {
        let commonProperties = { ...unref(properties) }
        // 特殊处理 Omni-Channel
        if (["retailer"].includes(productline)) {
          const srcProductLine = localStorage.getItem("navSrcPlt")
          if (srcProductLine) {
            commonProperties = { ...commonProperties, "sourcing platform": srcProductLine }
          }
        }
        window.analytics.page("Browse", unref(name), commonProperties, {})
        setTimeout(() => {
          callback()
        }, 200)
      }
    } catch (e) {
      console.error(e)
      callback()
    }
  }
  if (isRef(name) || isRef(properties)) {
    watchEffect(request, {})
    return response
  } else {
    request()
  }
}

/**
 * 注意使用此方法是个高级函数
 * 用于页面带有自定义属性的埋点
 * demo:
 * const properties = computed(() => {
 *    return { 'page business type': commonStore.commerceType === 'Vendor' ? '1p' : '3p' }
 * })
 * useAnalyticsPageWithProperties()(properties)
 * @returns {(properties: object , callback: function): Promise<void>}
 */
export const useAnalyticsPageWithProperties = () => {
  const route = useRoute()
  return function (properties = {}, callback = () => {}) {
    const analyticsConfig = getConfig()
    const pageMapper = analyticsConfig.ANALYTICS_PAGE_MAPPER
    const routeName = route?.name || route?.meta?.title
    const name = `${pageMapper[routeName] ?? routeName}`
    if (name) {
      useAnalyticsPage(name, properties, true, callback)
    }
  }
}

/**
 * 页面埋点在路由守卫里面使用
 * @param {string} routeName - 路由名称
 * @param {object | import("@vue/reactivity").Ref<object>} properties - 页面自定义的属性
 */
export const useAnalyticsPageWithRouterName = (routeName, properties = {}) => {
  const config = getConfig()
  const pageMapper = config.ANALYTICS_PAGE_MAPPER
  const name = `${pageMapper[routeName] ?? routeName}`
  useAnalyticsPage(name, properties)
}

/**
 * 通用的用户属性埋点
 * @param {string | import("@vue/reactivity").Ref<string>} userId - 用户id
 * @param {object  | import("@vue/reactivity").Ref<object>} traits - 用户属性
 */
export const useAnalyticsIdentify = (userId, traits) => {
  const request = () => {
    if (config.analytics) {
      window?.analytics?.identify(String(unref(userId)).padStart(5, "0"), unref(traits))
    }
  }
  isRef(userId) || isRef(traits) ? watchEffect(request) : request()
}

/**
 * group属性埋点
 * @param {string | import("@vue/reactivity").Ref<string>} clientId - 客户id
 * @param {object  | import("@vue/reactivity").Ref<object>} traits - 用户属性
 */
export const useAnalyticsGroup = (clientId, traits) => {
  const request = () => {
    if (config.analytics) {
      window?.analytics?.group(String(unref(clientId)), unref(traits))
    }
  }
  isRef(clientId) || isRef(traits) ? watchEffect(request) : request()
}

/**
 * 埋点的用户注销
 */
export const useAnalyticsReset = async () => {
  if (config.analytics) {
    await window?.analytics?.reset()
  }
}

export const useAnalytics = () => {
  if (config.analytics) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    const { VITE_APP_SEGMENT_KEY, VITE_APP_APIENV } = import.meta.env
    if (VITE_APP_APIENV !== "cn") {
      script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];
        if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
      var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${VITE_APP_SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
      analytics.load("${VITE_APP_SEGMENT_KEY}");
      }}();`
    } else {
      script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];
        if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://segment.pacvue.com/analytics.js/v1/" + key + "/analytics.min.js";
      var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${VITE_APP_SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
      analytics.load("${VITE_APP_SEGMENT_KEY}",  { integrations: { "Segment.io": { apiHost: "api-segment.pacvue.com/v1" } } });
      }}();`
    }

    document.body.appendChild(script)
  }
}

export const analyticsPage = ({ to, from }) => {
  const name = to?.name || to?.meta?.title
  if (!window.__analyticsIdentify__ && name !== "login") {
    analyticsIdentify()
  }
  if (config.analytics && name && to.path !== from.path) {
    useAnalyticsPageWithRouterName(name)
  }
}
