const SENDER = {
  MODEL: "NLP_GPT",
  SELF: "Human"
}

const CONVERSATION_TYPE = {
  FEATURE_INSTRUCTION: "Feature Instruction",
  DATA_QUERY: "Data Query",
  INSIGHT: "Insight"
}

const DISPLAY_MODE = {
  FLOAT: "float",
  FIXED_RIGHT: "fixed_right"
}

const STATUS_CODE = {
  TAG_NOT_FOUND: 404,
  ERROR: 500,
  INSIGHT_FORBIDDEN: 4031,
  QUOTA_EXCEEDS: 4032,
  DATA_QUERY_FORBIDDEN: 5001
}

const FRESHMAN_GUIDANCE_ACTION = {
  ACCEPT: 1,
  REJECT: 2
}

const INTENTION_TYPE = {
  DATA_QUERY: 1,
  INSIGHT: 2,
  NAVIGATION: 3,
  KNOWLEDGE_BASE: 4
}

export { SENDER, CONVERSATION_TYPE, DISPLAY_MODE, STATUS_CODE, FRESHMAN_GUIDANCE_ACTION, INTENTION_TYPE }
