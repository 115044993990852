<template>
  <el-popover
    :width="220"
    trigger="hover"
    :visible="popperVisible"
    placement="bottom-start"
    :popper-options="{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-16, 8]
          }
        }
      ]
    }"
  >
    <template #reference>
      <div class="relative w-6 h-6 cursor-pointer pacvue-downloadCenter-wrap" id="pacvue-downloadCenter-wrap">
        <el-progress v-if="popperVisible && showProgressbar" type="circle" :percentage="processVal" :show-text="false" :width="20" :stroke-width="2" :indeterminate="true" />
        <PacvueTooltip v-else placement="top" trigger="hover" effect="dark" :content="$t('key2782')">
          <el-icon class="cursor-pointer" :size="24" @click="handleDownloadCenterClick"><PacvueIconDownload /></el-icon>
        </PacvueTooltip>
      </div>
    </template>
    <template #default>
      <div class="break-keep">{{ $t("You have {0} download task in progress.", [downloadingCount]) }}</div>
    </template>
  </el-popover>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from "vue"
// utils
import { debounce } from "lodash-es"

const props = defineProps({
  downloadingCount: {
    //正在下载中的数目
    type: Number,
    default: 0
  },
  timeout: {
    //定时器时间，默认5秒
    type: Number,
    default: 5000
  },
  showProgressbar: {
    //是否显示进度条
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(["onClick"])

let processTimeHandler = null
const popperVisible = ref(false)
const processVal = ref(0)
const handlerStart = computed(() => {
  return debounce(() => {
    popperVisible.value = false
  }, props.timeout)
})

const startProcess = () => {
  processTimeHandler && clearInterval(processTimeHandler)
  processVal.value = 0
  processTimeHandler = setInterval(() => {
    processVal.value = processVal.value + 5
  }, 5000 / 20)
}

watch(
  () => props.downloadingCount,
  (newVal, oldVal) => {
    if (newVal) {
      popperVisible.value = true
      startProcess()
      handlerStart.value()
    } else {
      popperVisible.value = false
    }
  },
  {
    immediate: true
  }
)

const handleDownloadCenterClick = () => {
  emits("onClick")
}
</script>
