import request from "@pacvue/frame/request"
const { VITE_APP_BASEURL } = import.meta.env

export function GetSyncInfoDatas(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetSyncInfoDatas`,
    method: "post",
    data: data
  })
}

export function GetSyncDatas(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetSyncDatas`,
    method: "post",
    data: data
  })
}

export function GetLastAMSSyncData(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetLastAMSSyncData`,
    method: "post",
    data: data
  })
}

export function GetLastDSPSyncData(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetLastDSPSyncData`,
    method: "post",
    data: data
  })
}

export function GetLastEligibilitySyncData(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetLastEligibilitySyncData`,
    method: "post",
    data: data
  })
}

export function GetDspSettingSyncDatas(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetDspSettingSyncDatas`,
    method: "post",
    data: data
  })
}

export function GetDspPerformanceSyncDatas(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/GetDspPerformanceSyncDatas`,
    method: "post",
    data: data
  })
}

export function BulkSyncData(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/BulkSyncData`,
    method: "post",
    data: data
  })
}

export function BulkSyncDspData(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/BulkSyncDspData`,
    method: "post",
    data: data
  })
}

export function SyncEligibility(data) {
  return request({
    url: `${VITE_APP_BASEURL}SyncData/v3/SyncEligibility`,
    method: "post",
    data: data
  })
}
