import { tabTitle } from "./env"

const { VITE_APP_APIENV } = import.meta.env

const isV3Domain = window.location.origin.includes("v3-domain")

const DOMAIN_MAPPER_PROD = {
  amazon: { subDomain: "product", pathPrefix: "", isReleased: true },
  dsp: { subDomain: "product", pathPrefix: "amazon-dsp", isReleased: true },
  walmart: { subDomain: "product", pathPrefix: "walmart", isReleased: true },
  samsclub: { subDomain: "product", pathPrefix: "samsclub", isReleased: true },
  instacart: { subDomain: "product", pathPrefix: "instacart", isReleased: true },
  criteo: { subDomain: "product", pathPrefix: "criteo", isReleased: true },
  target: { subDomain: "product", pathPrefix: "target", isReleased: true },
  kroger: { subDomain: "product", pathPrefix: "kroger", isReleased: true },
  "kroger-old": { subDomain: "product", pathPrefix: "kroger-old", isReleased: true },
  doordash: { subDomain: "product", pathPrefix: "doordash", isReleased: true },
  citrus: { subDomain: "product", pathPrefix: "citrus", isReleased: true },
  mercado: { subDomain: "product", pathPrefix: "mercado", isReleased: true },
  chewy: { subDomain: "product", pathPrefix: "chewy", isReleased: true },
  ebay: { subDomain: "product", pathPrefix: "ebay", isReleased: true },
  bol: { subDomain: "product", pathPrefix: "bol", isReleased: true },
  bolv2: { subDomain: "product", pathPrefix: "bol-new", isReleased: true },
  commerce: { subDomain: "product", pathPrefix: "commerce", isReleased: true },
  "commerce-walmart": { subDomain: "product", pathPrefix: "commerce-walmart", isReleased: true },
  mt360: { subDomain: "dsa", pathPrefix: "" },
  retailer: { subDomain: "product", pathPrefix: "crossretailer", isReleased: true }
}

const DOMAIN_MAPPER_TEST = {
  amazon: { subDomain: "v3-domain", pathPrefix: "", isReleased: isV3Domain },
  dsp: { subDomain: "v3-domain", pathPrefix: "amazon-dsp", isReleased: isV3Domain },
  walmart: { subDomain: "v3-domain", pathPrefix: "walmart", isReleased: isV3Domain },
  samsclub: { subDomain: "v3-domain", pathPrefix: "samsclub", isReleased: isV3Domain },
  instacart: { subDomain: "v3-domain", pathPrefix: "instacart", isReleased: isV3Domain },
  criteo: { subDomain: "v3-domain", pathPrefix: "criteo", isReleased: isV3Domain },
  target: { subDomain: "v3-domain", pathPrefix: "target", isReleased: isV3Domain },
  kroger: { subDomain: "v3-domain", pathPrefix: "kroger", isReleased: isV3Domain },
  "kroger-old": { subDomain: "v3-domain", pathPrefix: "kroger-old", isReleased: isV3Domain },
  doordash: { subDomain: "v3-domain", pathPrefix: "doordash", isReleased: isV3Domain },
  citrus: { subDomain: "v3-domain", pathPrefix: "citrus", isReleased: isV3Domain },
  mercado: { subDomain: "v3-domain", pathPrefix: "mercado", isReleased: isV3Domain },
  chewy: { subDomain: "v3-domain", pathPrefix: "chewy", isReleased: isV3Domain },
  ebay: { subDomain: "v3-domain", pathPrefix: "ebay", isReleased: isV3Domain },
  bol: { subDomain: "v3-domain", pathPrefix: "bol", isReleased: isV3Domain },
  bolv2: { subDomain: "v3-domain", pathPrefix: "bol-new", isReleased: isV3Domain },
  commerce: { subDomain: "v3-domain", pathPrefix: "commerce", isReleased: isV3Domain },
  "commerce-walmart": { subDomain: "v3-domain", pathPrefix: "commerce-walmart", isReleased: isV3Domain },
  mt360: { subDomain: "dsa", pathPrefix: "", isReleased: isV3Domain },
  retailer: { subDomain: "v3-domain", pathPrefix: "crossretailer", isReleased: isV3Domain }
}

const PLATFORM_NAME_MAPPER = {
  amazon: "Amazon",
  walmart: "Walmart",
  instacart: "Instacart",
  criteo: "Criteo",
  ebay: "eBay",
  citrus: "Citrus",
  kroger: "Kroger",
  bol: "Bol",
  doordash: "Doordash",
  samsclub: "Sam's club",
  mercado: "Mercado",
  chewy: "Chewy",
  mt360: "Market Insights",
  dsp: "DSP",
  target: "Target",
  commerce: "Commerce-Amazon",
  "commerce-amazon": "Commerce-Amazon",
  "commerce-walmart": "Commerce-Walmart",
  "commerce-temu": "Commerce-Temu",
  retailer: `${tabTitle} HQ`,
  rakuten: "Market Intelligence"
}

const LOCALIZED_PLATFORMS = ["walmart", "amazon", "dsp", "commerce", "rakuten", "retailer"]

const PRODUCT_LINE_ALL = [
  "amazon",
  "amazon-dsp",
  "walmart",
  "samsclub",
  "instacart",
  "criteo",
  "citrus",
  "chewy",
  "bol",
  "kroger",
  "doordash",
  "mercado",
  "ebay",
  "target",
  "commerce",
  "commerce-walmart"
]

let PLATFORMS_ALL = []
const US_PLATFORMS_ALL = [
  "amazon",
  "commerce",
  "dsp",
  "commerce-walmart",
  "walmart",
  "criteo",
  "target",
  "instacart",
  "kroger",
  "doordash",
  "samsclub",
  "citrus",
  "mercado",
  "chewy",
  "ebay",
  "bol",
  "retailer"
]
const CN_PLATFORMS_ALL = ["amazon", "commerce", "dsp", "commerce-walmart", "walmart", "retailer"]
const EU_PLATFORMS_ALL = ["amazon", "commerce", "dsp", "criteo", "citrus", "bol", "retailer"]
PLATFORMS_ALL = US_PLATFORMS_ALL
if (VITE_APP_APIENV.toLowerCase().indexOf("cn") !== -1) {
  PLATFORMS_ALL = CN_PLATFORMS_ALL
}
if (VITE_APP_APIENV.toLowerCase().indexOf("eu") !== -1) {
  PLATFORMS_ALL = EU_PLATFORMS_ALL
}

export { DOMAIN_MAPPER_PROD, DOMAIN_MAPPER_TEST, LOCALIZED_PLATFORMS, PRODUCT_LINE_ALL, PLATFORMS_ALL, US_PLATFORMS_ALL, CN_PLATFORMS_ALL, EU_PLATFORMS_ALL, PLATFORM_NAME_MAPPER }
