import { app } from "../frame.js"
import { reactive, computed } from "vue"

export function useCustomDownload({ appStore } = {}) {
  const downloadFileMap = reactive({})
  const handleDataDownloaded = (downloadInfo) => {
    const uuid = downloadInfo.uuid
    const fileName = getFileBaseName(downloadInfo.fileName ?? "")
    const isCompleted = ["Data Download Succeed", $t("Data Download Succeed")].includes(downloadInfo.detail)
    if (fileName) {
      if (!downloadFileMap[fileName]) {
        if (!isCompleted) {
          downloadFileMap[fileName] = {
            ...downloadInfo,
            isCompleted
          }
        }
      } else if (isCompleted) {
        delete downloadFileMap[fileName]
      }
    }
  }
  app.config.globalProperties.$eventHub.on("customDataDownload", (downloadInfo) => {
    if (downloadInfo) {
      handleDataDownloaded(downloadInfo)
    }
  })
  const downloadingFileList = computed(() => Object.values(downloadFileMap).filter((item) => !item.isCompleted))
  const downloadingCount = computed(() => downloadingFileList.value.length)

  return {
    downloadingFileList,
    handleDataDownloaded,
    downloadingCount
  }
}

function getFileBaseName(fileName = "") {
  const fileNameArr = fileName.split(".")
  const fileNameArrLen = fileNameArr.length
  let fileNameStr = ""
  if (fileNameArrLen == 1) {
    return fileName
  }
  for (let i = 0; i < fileNameArrLen - 1; i++) {
    fileNameStr += fileNameArr[i] + (i == fileNameArrLen - 2 ? "" : ".")
  }
  return fileNameStr
}
