import request from "@pacvue/frame/request"
import { PacvueCreateFilterStoreKey } from "@/utils/common-util"
const VITE_APP_APIREPORT = import.meta.env.VITE_APP_APIREPORT
const VITE_APP_META = import.meta.env.VITE_APP_META
const VITE_APP_PRODUCT_3P = import.meta.env.VITE_APP_PRODUCT_3P
const VITE_APP_MENU_API = import.meta.env.VITE_APP_MENU_API
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
export function getMenus() {
  return request({
    url: `${VITE_APP_MENU_API}user/menus`,
    method: "get"
  })

  // return Promise.resolve([
  //   {
  //     alias: 'Home',
  //     children: [],
  //     icon: 'Home',
  //     menuId: 1,
  //     menuName: 'Home',
  //     parentId: 0,
  //     url: '/Home'
  //   },
  //   {
  //     alias: 'ProductCenter',
  //     children: [
  //       {
  //         alias: 'ProductDashboard',
  //         children: [],
  //         icon: '',
  //         menuId: 301,
  //         menuName: 'Product Dashboard',
  //         parentId: 3,
  //         url: '/ProductCenter/ProductDashboard'
  //       },
  //       {
  //         alias: 'ProductListing',
  //         children: [],
  //         icon: '',
  //         menuId: 302,
  //         menuName: 'Product Listing',
  //         parentId: 3,
  //         url: '/ProductCenter/ProductListing'
  //       },
  //       {
  //         alias: 'Order',
  //         children: [],
  //         icon: '',
  //         menuId: 303,
  //         menuName: 'Order',
  //         parentId: 3,
  //         url: '/ProductCenter/Order'
  //       }
  //     ],
  //     icon: 'ProductCenter',
  //     menuId: 3,
  //     menuName: 'Product Center',
  //     parentId: 0,
  //     url: '/ProductCenter'
  //   }
  // ])
}
export function getProfile(data) {
  var platform = localStorage.getItem("productline") || "amazon"
  var postline = ["amazon", "kroger"]

  return request({
    url: `${VITE_APP_META}filter/profiles`,
    method: postline.includes(platform) ? "post" : "get",
    data: {
      advertiserIds: data
    }
  })
}
export function getAmazonList(obj) {
  var url = ""
  if (obj?.isManage) {
    url = "?isManage=1"
  }
  return request({
    url: `${VITE_APP_APIREPORT}/api/User/V3/GetAmazonList${url}`,
    method: "post"
  })
}
export function getPacvueUniversityUrl() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/sso/V3/GetPacvueUniversityUrl`,
    method: "get"
  })
}
export function getZendeskUrl() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/sso/V3/GetZendeskUrl`,
    method: "get"
  })
}
export function getUserPermission(data) {
  const listStoreKey = PacvueCreateFilterStoreKey({ storeKey: "ProductDashboardChannel" })
  const dashboardStoreKey = PacvueCreateFilterStoreKey({ storeKey: "ProductListChannel" })
  return new Promise((resolve) => {
    request({
      url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getUserPermission`,
      method: "post",
      data
    }).then((res) => {
      const { type } = res ?? {}
      let listChannel = localStorage.getItem(listStoreKey)
      let dashboardChannel = localStorage.getItem(dashboardStoreKey) //1,3,4
      let channels = []
      if (type === 1 || type === 2) {
        channels =
          type === 1
            ? [
                {
                  label: $t("key846"),
                  value: "Vendor",
                  channel: 1
                }
              ]
            : [
                {
                  label: $t("key846"),
                  value: "Vendor",
                  channel: 1
                },
                {
                  label: $t("key702"),
                  value: "Seller",
                  channel: 3
                },
                {
                  label: $t("amskey2310"),
                  value: "Hybrid",
                  channel: 4
                }
              ]
      } else if (type === 3) {
        channels = [
          {
            label: $t("key702"),
            value: "Seller",
            channel: 3
          }
        ]
      }
      let config = {
        list: false,
        dashboard: false
      }
      for (let i = 0, size = channels.length; i < size; i++) {
        let { value, channel } = channels[i]
        if (listChannel == channel) {
          config.list = true
        }
        if (dashboardChannel == value) {
          config.dashboard = true
        }
      }
      if (!config.list) {
        localStorage.setItem(listStoreKey, type == 1 || type == 2 ? "Vendor" : "Seller")
      }
      if (!config.dashboard) {
        localStorage.setItem(listStoreKey, JSON.stringify(type == 1 || type == 2 ? 1 : 3))
      }
      resolve(res)
    })
  })
}
export function GetProfileBrands(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/GetProfileBrands`,
    method: "post",
    data: data
  })
}
export function CreateProfileBrands(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/CreateProfileBrands`,
    method: "post",
    data: data
  })
}
export function UpdateProfileBrands(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/UpdateProfileBrands`,
    method: "post",
    data: data
  })
}
export function DeleteProfileBrands(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/DeleteProfileBrands`,
    method: "post",
    data: data
  })
}
