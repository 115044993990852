import localforage from "localforage"
export function usePacvueLocalforage({ pacvueLocalforage, isUseMemoryCache = false } = {}) {
  pacvueLocalforage = pacvueLocalforage ?? localforage
  let pacvueLocalforageCache = {
    id: "__LocalforageCache__"
  }
  var handler = {
    async getItem(key, callback) {
      var res = null
      if (isUseMemoryCache && pacvueLocalforageCache[key]) {
        return pacvueLocalforageCache[key]
      }
      try {
        res = await pacvueLocalforage.getItem(key, callback)
      } catch (ex) {
        console.warn("PacvueLocalforage getItem Error:", ex)
        res = null
      }
      isUseMemoryCache && (pacvueLocalforageCache[key] = res)
      return res
    },
    async setItem(key, value, callback) {
      var res = null
      try {
        isUseMemoryCache && (pacvueLocalforageCache[key] = value)
        res = await pacvueLocalforage.setItem(key, value, callback)
      } catch (ex) {
        console.warn("PacvueLocalforage setItem Error:", ex)
        return Promise.reject(res)
      }
      return res
    }
  }
  return new Proxy(
    {},
    {
      get(target, property) {
        if (property == "memoryCache") {
          return pacvueLocalforageCache
        }
        return handler[property] ?? pacvueLocalforage[property]
      }
    }
  )
}
//清空indexedDB数据库
export function localForageClear() {
  if (window.indexedDB) {
    try {
      indexedDB.databases().then((databaseList) => {
        if (Array.isArray(databaseList)) {
          databaseList.forEach((item) => {
            const dataBaseName = item.name
            indexedDB.deleteDatabase(dataBaseName)
          })
        }
      })
    } catch (ex) {
      console.error("localForageClear", ex)
    }
  }
}
window.__localForageClear__ = localForageClear
