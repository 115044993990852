export function setPacvue_token(ac) {
  let json = ac.data.data.json
  json.expires_in = new Date().getTime() + json.expires_in * 1000
  json.access_token = "lx" + json.access_token
  json.refresh_token = "gx" + json.refresh_token
  window.sessionStorage.setItem("Pacvue_token", JSON.stringify(json))
  window.localStorage.setItem("Pacvue_token", JSON.stringify(json))
}
export function getPacvue_token() {
  if (!window.localStorage.getItem("Pacvue_token")) {
    return
  }
  return getUserToken()
}
export function getTokens() {
  if (!window.localStorage.getItem("Pacvue_token")) {
    return
  }
  var json = getUserToken()

  return json?.access_token.slice(2)
}
export function getRefresh_token() {
  if (!window.localStorage.getItem("Pacvue_token")) {
    return
  }
  let json = getUserToken()
  return json?.refresh_token.slice(2)
}
export function getExpires_in() {
  if (!window.localStorage.getItem("Pacvue_token")) {
    return
  }
  let json = getUserToken()
  return json?.expires_in
}
export function removePacvue_token() {
  window.localStorage.removeItem("Pacvue_token")
  window.sessionStorage.removeItem("Pacvue_token")
}

function getUserToken() {
  var cachetoken = ""
  if (window.sessionStorage.getItem("Pacvue_token")) {
    cachetoken = window.sessionStorage.getItem("Pacvue_token")
  } else {
    cachetoken = window.localStorage.getItem("Pacvue_token")
  }
  return JSON.parse(cachetoken)
}

export default { setPacvue_token, getPacvue_token, getTokens, getRefresh_token, getExpires_in, removePacvue_token }
