const state = () => {
  return {
    timezone: "",
    eventTypeOptionTask: [],
    tabType: "All"
  }
}
const actions = {
  SET_TIMEONE(val) {
    this.timezone = val
  },
  SET_eventTypeOptionTask(value) {
    this.eventTypeOptionTask = value
  },
  SET_taskCenterTabType(value) {
    this.tabType = value
  }
}

export default {
  state,
  actions
}
