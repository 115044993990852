const walmartTipsKey = [
  "CPATips",
  "CVRTips",
  "ACOSTips",
  "AdSKUACOSTips",
  "ROASTips",
  "AdSKUROASTips",
  "SalesTips",
  "Sales(Delivery)Tips",
  "Sales(Pickup)Tips",
  "OrdersTips",
  "Orders(Delivery)Tips",
  "Orders(Pickup)Tips",
  "SaleUnitsTips",
  "AdSKUSalesTips",
  "AdSKUSales(Delivery)Tips",
  "AdSKUSales(Pickup)Tips",
  "AdSKUOrdersTips",
  "AdSKUOrders(Delivery)Tips",
  "AdSKUOrders(Pickup)Tips",
  "AdSKUSaleUnitsTips",
  "SameSKUSalesTips",
  "SameSKUSaleUnitsTips",
  "OtherSalesTips",
  "OtherSales%Tips",
  "OtherSales(Delivery)Tips",
  "OtherSales(Pickup)Tips",
  "OtherOrdersTips",
  "OtherOrders(Delivery)Tips",
  "OtherOrders(Pickup)Tips",
  "OtherSaleUnitsTips",
  "RelatedClickSalesTips",
  "BrandClickSalesTips",
  "AOVTips",
  "ASPTips",
  "SaleUnits(Delivery)Tips",
  "SaleUnits(Pickup)Tips",
  "AdSKUSaleUnits(Delivery)Tips",
  "AdSKUSaleUnits(Pickup)Tips",
  "OtherSaleUnits(Delivery)Tips",
  "OtherSaleUnits(Pickup)Tips"
]

export { walmartTipsKey }
