import { onMounted, onUnmounted, watchEffect, watch } from "vue"
import { throttle } from "lodash-es"
import { dayjs } from "@pacvue/element-plus"
import { useCommonStore, useUserStore } from "@/store"
import { useRoute, useRouter } from "vue-router"
import { logoutProcessing } from "../utils/logout"
import { clearKeysWithPattern } from "../store/enumerate"

const { VITE_APP_NODE_ENV } = import.meta.env
const productLine = localStorage.getItem("productline")

const ON_MOUSE_MOVE = "mousemove"
const ON_STORAGE = "storage"
const ON_BEFORE_UNLOAD = "beforeunload"
const APP_TIMEOUT_KEY = `${productLine}-app-timeout-key`
const APP_LOGOUT_PATH = `pacvue_logout_path_`
const APP_TIMEOUT_LATEST = `pacvue_timeout_log_latest_${productLine}`
/** 默认超时时间毫秒数 */
let APP_TIMEOUT_DELAY = 60 * 60 * 1000
// let APP_TIMEOUT_DELAY = 10 * 1000
/** 不需要超时监控的路由 */
const EXCLUDED_PATH = ["/login", "/Share/Index", "/Share/SOVDashboard", "/Share/PriceTrackerDashboard", "/Share/AnalyticDashboard", "/Share/ProductDashboard"]
const getLocalValue = () => {
  const times = window.localStorage.getItem(APP_TIMEOUT_KEY)
  return times ? +times : times
}
const setLocalValue = (value) => {
  window.localStorage.setItem(APP_TIMEOUT_LATEST, dayjs(Date.now()).format("MM/DD/YYYY HH-mm-ss"))
  window.localStorage.setItem(APP_TIMEOUT_KEY, value)
}
const removeLocalValue = () => {
  window.localStorage.removeItem(APP_TIMEOUT_KEY)
}

/**
 * 页面未活动超时登出
 * 当鼠标未在页面活动超过偏好设置的时间时页面登出
 */
export const useAppTimeout = () => {
  if (VITE_APP_NODE_ENV === "development" || ["retailer"].includes(productLine)) return
  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  const commonStore = useCommonStore()
  watch(
    () => userStore.userSettings?.autoLogout,
    () => {
      if (userStore.userSettings?.autoLogout) {
        const timer = [20, 30, 60, 120, 180, 480]
        const ss = timer[userStore.userSettings?.autoLogout] ?? 30
        APP_TIMEOUT_DELAY = 60 * ss * 1000
      }
    }
  )
  /** 是否是需要排除监控的路由 */
  const isLoginPage = (path) => {
    return EXCLUDED_PATH.includes(path)
  }

  const logout = async () => {
    const times = Date.now()
    clearKeysWithPattern(/(pacvue_logout_path_)/)
    localStorage.setItem(`${APP_LOGOUT_PATH}${times}`, route.fullPath)
    localStorage.removeItem("uid")
    await logoutProcessing({ Rurl: times })
  }

  let timeout = null
  /**
   * 判断当前页面是否超时未操作
   */
  const staticWatcher = () => {
    const times = getLocalValue()
    if (!times) return
    if (Date.now() - times < APP_TIMEOUT_DELAY) {
      // 未超时则重置计时器
      timeout = window.setTimeout(staticWatcher, APP_TIMEOUT_DELAY)
    } else {
      // 超时则登出
      clearEvent()
      logout()
    }
  }

  /** 重置计时器 */
  const resetTimeout = () => {
    if (timeout) window.clearTimeout(timeout)
    timeout = window.setTimeout(staticWatcher, APP_TIMEOUT_DELAY)
  }

  /**
   * 鼠标移动事件
   * 设置当前时间戳，重置计时器
   */
  const effectPageMousemove = throttle(() => {
    setLocalValue(Date.now())
    resetTimeout()
  }, 500)

  /**
   * 监听 storage 变化
   * 重置计数器
   */
  const storageWatcher = ({ key, newValue, oldValue }) => {
    if (key !== APP_TIMEOUT_KEY) return
    if (!newValue) {
      // 如果更新的数据为空，说明是同源页面关闭了，这时不能重置计数器，而是将 oldValue 设置回 storage
      setLocalValue(oldValue)
    } else {
      resetTimeout()
    }
  }

  /** 清空绑定的事件和 storage */
  const clearEvent = () => {
    removeLocalValue()
    window.removeEventListener(ON_STORAGE, storageWatcher)
    document.body.removeEventListener(ON_MOUSE_MOVE, effectPageMousemove)
  }

  onMounted(() => {
    window.addEventListener(ON_STORAGE, storageWatcher, false)
    // 绑定页面关闭事件
    window.addEventListener(ON_BEFORE_UNLOAD, clearEvent, false)
  })
  onUnmounted(clearEvent)

  watchEffect(() => {
    if (isLoginPage(route.path)) {
      if (timeout) window.clearTimeout(timeout)
      clearEvent()
    } else {
      // 只有非 login 页面才监听是否超时未操作
      effectPageMousemove()
      document.body.removeEventListener(ON_MOUSE_MOVE, effectPageMousemove)
      document.body.addEventListener(ON_MOUSE_MOVE, effectPageMousemove, false)
    }
  })
}

// 路由捕获报错刷新
export const shouldReload = () => {
  const lastReloadTime = sessionStorage.getItem("lastReloadTime")
  const currentTime = new Date().getTime()

  // 检查是否有存储的时间戳
  if (lastReloadTime) {
    const elapsedTime = currentTime - parseInt(lastReloadTime)

    // 如果距离上次刷新时间超过30分钟（1800000毫秒），则返回true
    if (elapsedTime > 1800000) {
      return true
    } else {
      return false
    }
  } else {
    // 如果没有存储的时间戳，说明是首次加载，返回true
    return true
  }
}
