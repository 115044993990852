import { app } from "../src/frame"
// config
import { QUESTIONS_PRESETS_KB } from "../src/Copilot/config"
// store
import useCopilotStore from "../store/copilot"

const getProductLine = () => window.productline || localStorage.getItem("productline")

const judgeActiveCopilotGuidingPopByRoute = () => {
  const route = app.config.globalProperties.router.currentRoute.value
  for (const entry of QUESTIONS_PRESETS_KB.value[getProductLine()]) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      return true
    }
  }
  return false
}

const getCopilotQuestionsPresetsByRoute = () => {
  const route = app.config.globalProperties.router.currentRoute.value
  for (const entry of QUESTIONS_PRESETS_KB.value[getProductLine()]) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      return entry[1]
    }
  }
  return []
}

const generateUUID = () => {
  return "id-xxxxxxxxxxxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const initKbStreamingWebsocket = () => {
  const copilotStore = useCopilotStore()
  const ws = new WebSocket(`wss://xommhhbr24.execute-api.us-west-2.amazonaws.com/Prod?token=Bearer ${copilotStore.kbToken}`)
  // Closure
  let index = -1
  let isStreaming = true
  let fullContent = ""
  let displayContent = ""
  let displayEOF = false
  let imgEncountered = false
  let hyperlinkEncountered = false
  // 正则捕获组处理
  const regCaptureGroupsFn = (match, $1, $2) => ($2.startsWith("http") ? "" : $1)
  // listener
  ws.onopen = () => {
    console.log("Knowledge contextual conversation start.")
  }
  ws.onmessage = (e) => {
    if (!e.data) return
    index++
    const content = JSON.parse(e.data).text?.content ?? ""
    // 判断首次返回的 content 切片大小, 判定是否流式
    if (index === 0) {
      isStreaming = content.length < 20
    }
    if (isStreaming && content.includes("**Refer to")) {
      displayEOF = true
    }
    if (!displayEOF) {
      const prev = displayContent
      displayContent += content
      // 流式返回, 遇到图片 / 超链接, 实时过滤
      if (isStreaming) {
        if (imgEncountered || content.includes("![")) {
          displayContent = prev
          imgEncountered = true
        }
        if (hyperlinkEncountered || content.includes("[*")) {
          displayContent = prev
          hyperlinkEncountered = true
        }
        // 等待结束标志
        if ((imgEncountered || hyperlinkEncountered) && content.includes(")")) {
          imgEncountered = false
          hyperlinkEncountered = false
        }
      }
      // 非流式返回需要针对图片及超链接再做一次过滤
      if (!isStreaming) {
        displayContent = displayContent.replace(/\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
        displayContent = displayContent.replace(/!\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
      }
      copilotStore.setKbContent(displayContent)
    } else {
      // 中间的两个小括号分别对应 组1 组2
      displayContent = displayContent.replace(/\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
      displayContent = displayContent.replace(/!\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
      copilotStore.setKbContent(displayContent)
    }
    // [DONE] 结束标志是固定的
    if (content === "[DONE]") {
      app.config.globalProperties.$eventHub.emit("onKbStreamFinish")
      copilotStore.appendContextualConv({
        role: "AI",
        content: fullContent
      })
      fullContent = ""
      displayContent = ""
      displayEOF = false
      index = -1
      isStreaming = true
    } else {
      fullContent += content
    }
  }
  ws.onerror = () => {
    console.log("Knowledge contextual conversation error")
    app.config.globalProperties.$eventHub.emit("onKbStreamFinish")
    fullContent = ""
    displayContent = ""
    displayEOF = false
  }
  ws.onclose = () => {
    console.log("Knowledge contextual conversation disconnected")
  }
  window.copilotSocket = ws
}

export { judgeActiveCopilotGuidingPopByRoute, getCopilotQuestionsPresetsByRoute, generateUUID, initKbStreamingWebsocket }
