import request from "@pacvue/frame/request"
const productline = localStorage.getItem("productline")
const VITE_APP_NODE_ENV = import.meta.env.VITE_APP_NODE_ENV
const VITE_APP_APIENV = import.meta.env.VITE_APP_APIENV
const { VITE_APP_BillingTagUrl, VITE_APP_SHARETAGURL, VITE_APP_TAGURL, VITE_SALESOP_DATAURL, VITE_APP_BASEURL } = import.meta.env
// const BILLINGTAG_NOCE_ENV = VITE_APP_APIENV ? VITE_APP_NODE_ENV + "_" + VITE_APP_APIENV : VITE_APP_NODE_ENV
const addApiList = ["bol", "kroger", "doordash", "chewy", "ebay", "citrus", "criteo", "instacart", "target"]
let billing_tag_api = VITE_APP_SHARETAGURL
if (productline == "dsp") {
  billing_tag_api = VITE_APP_TAGURL + "api"
} else if (addApiList.indexOf(productline) != -1) {
  billing_tag_api = VITE_APP_SHARETAGURL + "api"
} else if (productline == "walmart" || productline == "samsclub" || productline == "amazon") {
  billing_tag_api = VITE_APP_SHARETAGURL.substr(0, VITE_APP_SHARETAGURL.length - 1)
}
let salesopURL = VITE_SALESOP_DATAURL
//api: amazon

const dataurl = billing_tag_api
//查询billing tag
export function getBillingTags(data = {}) {
  return request({
    url: `${dataurl}/billingTag/getBillingTags`,
    method: "POST",
    data: data
  })
}
//tag筛选项数据
export function getAllBillingTags(data = {}) {
  return request({
    url: `${dataurl}/billingTag/getAllBillingTags`,
    method: "POST",
    data: data
  })
}
export function saveAddBillingTags(data = {}) {
  return request({
    url: `${dataurl}/billingTag/addTags`,
    method: "POST",
    data: data
  })
}
export function moveCampaigns(data = {}) {
  return request({
    url: `${dataurl}/billingTag/moveCampaigns`,
    method: "POST",
    data: data
  })
}
export function addCampaigns(data = {}) {
  return request({
    url: `${dataurl}/billingTag/addCampaigns`,
    method: "POST",
    data: data
  })
}

export function deleteTags(data) {
  return request({
    url: `${dataurl}/billingTag/deleteTags`,
    method: "post",
    data: data
  })
}

export function getProfiles(data = {}) {
  return request({
    url: `${dataurl}/billingTag/getAllProfiles`,
    method: "POST",
    data: data
  })
}
export function getAllCampaigns(data = {}) {
  return request({
    url: `${dataurl}/billingTag/getAllCampaigns`,
    method: "POST",
    data: data
  })
}
export function getfilterCampaigns(data = {}) {
  return request({
    url: `${dataurl}/billingTag/filter/campaigns`,
    method: "POST",
    data: data
  })
}
export function getCampaignDetail(data = {}) {
  return request({
    url: `${dataurl}/billingTag/getCampaigns`,
    method: "POST",
    data: data
  })
}
export function saveMatchRule(data = {}) {
  return request({
    url: `${dataurl}/billingTag/saveMatchRule`,
    method: "POST",
    data: data
  })
}
export function checkTagName(data = {}) {
  return request({
    url: `${dataurl}/billingTag/checkTagName`,
    method: "POST",
    data: data
  })
}
export function getDownload(data = {}) {
  return request({
    url: `${dataurl}/billingTag/download`,
    method: "POST",
    data: data,
    responseType: "blob"
  })
}
export function editMatchRule(data) {
  return request({
    url: `${dataurl}/billingTag/editMatchRule/${data}`,
    method: "get"
  })
}

// 获取汇率
export function getCurrencyCode() {
  return request({
    url: `${salesopURL}/billingStatement/getCurrencyCode`,
    method: "get"
  })
}
//查询是否含有unassigned
export function getUnAssignedCount() {
  return request({
    url: `${dataurl}/billingTag/getUnAssignedCount`,
    method: "get"
  })
}

//修改tagname和billing curency
export function EditTag(data = {}) {
  return request({
    url: `${dataurl}/billingTag/editTag`,
    method: "POST",
    data: data
  })
}

export const getAdvertiser = function (data) {
  return request({
    url: `${VITE_APP_BASEURL}/api/DSP/GetDspUserAndProfileAdvertiser`,
    method: "post",
    data
  })
}

export function downloadDetail(data = {}) {
  return request({
    url: `${dataurl}/billingTag/downloadDetail`,
    method: "POST",
    data: data,
    responseType: "blob"
  })
}
