import request from "@pacvue/frame/request"

const { VITE_APP_BASEURL, VITE_APP_DOWNLOAD } = import.meta.env

export function getAdvertisingPerf(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetAdvertisingPerf`,
    method: "post",
    data: data
  })
}

export function getDailyPerf(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetDailyPerf`,
    method: "post",
    data: data
  })
}

export function getMonthlyPerf(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetMonthlyPerf`,
    method: "post",
    data: data
  })
}

export function getActiveEntities(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetActiveEntities`,
    method: "post",
    data: data
  })
}

export function getOutofBudgetReport(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetOutofBudgetReport`,
    method: "post",
    data: data
  })
}

export function getTop20Campaign(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetTop20Campaign`,
    method: "post",
    data: data
  })
}

export function getTo20Product(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetTo20Product`,
    method: "post",
    data: data
  })
}

export function getCountryCode(data) {
  return request({
    url: `${VITE_APP_BASEURL}MarketplaceDashboard/v3/GetCountryCode`,
    method: "post",
    data: data
  })
}

export function downloadDashboard(data) {
  return request({
    url: `${VITE_APP_DOWNLOAD}MarketplaceDashboard/v3/DownloadDashboard`,
    method: "post",
    data: data,
    responseType: "blob"
  })
}
