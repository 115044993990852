const handleEnhancedKey = (key) => {
  const productLine = localStorage.getItem("productline")
  const _regExp = new RegExp("^" + productLine + "_(.*)")
  const match = key.match(_regExp) || ""
  return match[1]
}

const clearKeysWithPattern = (regExp) => {
  const keys = []
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (regExp.test(key)) {
      keys.push(handleEnhancedKey(key))
    }
  }
  for (const key of keys) {
    localStorage.removeItem(key)
  }
}

const clearSessionWithIgnoredKeys = (ignoredKeys) => {
  ignoredKeys = ignoredKeys || ["navStartTime", "loginNavStartTime", "loginStartTime"]
  const results = ignoredKeys.reduce((prev, curr) => ({ ...prev, [curr]: sessionStorage.getItem(curr) }), {})
  sessionStorage.clear()
  for (const key of ignoredKeys) {
    results[key] && sessionStorage.setItem(key, results[key])
  }
}

export { clearKeysWithPattern, clearSessionWithIgnoredKeys }
