import request from "./request"
import authRequest from "./authRequest"
import { pacvueToken } from "@pacvue/utils"
import config from "@~/config.js"
const VITE_APP_MENU_API = import.meta.env.VITE_APP_MENU_API
const VITE_APP_APIREPORT = import.meta.env.VITE_APP_APIREPORT
const VITE_APP_PRODUCT_3P = import.meta.env.VITE_APP_PRODUCT_3P
const VITE_APP_USERURL = import.meta.env.VITE_APP_USERURL
const VITE_APP_META = import.meta.env.VITE_APP_META
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const { VITE_APP_ADMIN_MIDDLE } = import.meta.env

export function maidian(option) {
  if (!config.maidian) return
  let data = Object.assign(option, config.maidian)
  return request({
    url: `${VITE_APP_MENU_API}user/saveEventLog`,
    method: "post",
    data
  })
}
export function feedback(data) {
  return request({
    url: `https://api.pacvue.com/jira-admin/api/v1/create/issue`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data" //含有附件的表单提交的Content-Type
    },
    data: data
  })
}
export function getMenus(params, productLine) {
  return request({
    url: `${VITE_APP_MENU_API}user/menus`,
    method: "get",
    params,
    headers: { productline: productLine }
  })
}

export function getAmazonList(obj) {
  var url = ""
  if (obj?.isManage) {
    url = "?isManage=1"
  }
  return request({
    url: `${VITE_APP_APIREPORT}/api/User/V3/GetAmazonList${url}`,
    method: "post"
  })
}

export function getUserPermission(data) {
  return request({
    url: `${VITE_APP_PRODUCT_3P}productCenterDashboard/getUserPermission`,
    method: "post",
    data
  })
}
export function login(data) {
  return authRequest({
    url: "/Login",
    method: "post",
    headers: {},
    data
  })
}

export function forget(data) {
  return authRequest({
    url: "/Login/forget",
    method: "post",
    data
  })
}
export function forgetSubmit(data) {
  return authRequest({
    url: "/Login/forget/submit",
    method: "post",
    data
  })
}
export function sendEmail(data) {
  return authRequest({
    url: "/Login/sendVerificationCode",
    method: "post",
    data
  })
}
export function LoginVerify(code) {
  return authRequest({
    url: `Login/verifyV2/${code}`,
    method: "post"
  })
}

export function verifyMFA(data, targetPlatform) {
  return authRequest({
    url: "/Login/verifyMFA",
    method: "post",
    headers: {
      productline: targetPlatform
    },
    data
  })
}

export function bindMFA(data, targetPlatform) {
  return authRequest({
    url: "/Login/bindMFA",
    method: "post",
    headers: {
      productline: targetPlatform
    },
    data
  })
}

export function bindMFAToken(data) {
  return request({
    url: VITE_APP_USERURL + "User/bindMFA",
    method: "post",
    data
  })
}
export function getInfo() {
  return request({
    url: VITE_APP_USERURL + "User/userinfo",
    method: "get"
  })
}

export function refreshToken() {
  return authRequest({
    url: "/Login/refreshToken",
    method: "get",
    params: {
      refreshToken: pacvueToken.getRefresh_token()
    }
  })
}

export function logout() {
  pacvueToken.removePacvue_token()
}
export function IsAuthorizedByAmazon() {
  return request({
    url: `${VITE_APP_BASEURL}Setting/V3/IsAuthorizedByAmazon`,
    method: "GET"
  })
}
export function getSettings(userId, { productLine } = {}) {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/getUserPreferences/${userId}`,
    method: "GET",
    headers: { productline: productLine }
  })
}
export function getCrossAuthCode() {
  return request({
    url: VITE_APP_USERURL + "User/crossAuthCode",
    method: "get"
  })
}
export function oktaPkceAuthurl(data) {
  return authRequest({
    url: `${VITE_APP_BASEURL}loginoauth/v3/okta_pkce_authurl?client=${data}`,
    method: "get"
  })
}
export function oktaKenvueAuthurl(data, url) {
  return authRequest({
    url: `${VITE_APP_BASEURL}loginoauth/v3/kenvue_authurl?client=${data}&url=${url}`,
    method: "get"
  })
}
export function oktaPkce(data, url) {
  if (!url) {
    url = "pg"
  } else if (url == "irobot") {
    url = "irobot"
  } else if (url == "kenvue") {
    url = "kenvue"
  } else {
    url = "okta_pkce"
  }
  return authRequest({
    url: `${VITE_APP_BASEURL}loginoauth/v3/${url}`,
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data
  })
}
export function getSecret() {
  return request({
    url: VITE_APP_USERURL + `User/getSecret`,
    method: "get",
    isUnique: true
  })
}
export function getZendeskUrl() {
  return request({
    url: `${VITE_APP_APIREPORT}/api/sso/V3/GetZendeskUrl`,
    method: "get"
  })
}
export function setSettings(data) {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/setUserPreferences`,
    method: "post",
    data: data,
    isIgnoreRequestRegister: true
  })
}

export function setFavorites(data) {
  return request({
    url: `${VITE_APP_MENU_API}user-platform-favorites`,
    method: "POST",
    data
  })
}

export function delFavorites(data) {
  return request({
    url: `${VITE_APP_MENU_API}user-platform-favorites`,
    method: "DELETE",
    data
  })
}

export function setUserHomePage(data) {
  return request({
    url: `${VITE_APP_MENU_API}user-platform-favorites/setHomepage`,
    method: "POST",
    data
  })
}

export function activateUserBySecretKey(data) {
  return authRequest({
    url: `${VITE_APP_MENU_API}userManager/activateUser`,
    method: "POST",
    data
  })
}

export function getActivationInfo(params) {
  return authRequest({
    url: `${VITE_APP_MENU_API}userManager/getActivationInfo/${params}`,
    method: "GET"
  })
}

export function checkConflictUserPassword(data) {
  return authRequest({
    url: `${VITE_APP_MENU_API}userManager/checkConflictUserPassword`,
    method: "POST",
    data
  })
}

export function loginV2(data, targetPlatform) {
  return authRequest({
    url: "/Login/v2",
    method: "post",
    headers: {
      productline: targetPlatform
    },
    data
  })
}

export function loginOkta(data, targetPlatform) {
  return authRequest({
    url: "/Login/oktaTokenLogin",
    method: "post",
    headers: {
      productline: targetPlatform
    },
    data
  })
}

export function onceTokenLogin(data, targetPlatform) {
  return authRequest({
    url: "/Login/onceTokenLogin",
    method: "post",
    headers: {
      productline: targetPlatform
    },
    data
  })
}

export function switchUserToken(data) {
  return authRequest({
    url: "/Login/switchUserToken",
    method: "post",
    headers: {},
    data
  })
}
export function sendEmailActivation(data) {
  return request({
    url: `${VITE_APP_MENU_API}mail/sendUserActiveEmails`,
    method: "POST",
    data: data
  })
}

// 邮箱是否已验证过
export function checkEmailVerified(data) {
  return request({
    url: `${VITE_APP_MENU_API}userManager/mailVerifiedByAnyUser`,
    method: "POST",
    data: data
  })
}

// 邮箱是否重复 (与当前 Client 下的邮箱重复 / 与当前 Client 下的 username 重复)
export function checkEmailDuplicated(data) {
  return request({
    url: `${VITE_APP_MENU_API}userManager/checkMailDuplicate`,
    method: "POST",
    data: data
  })
}
