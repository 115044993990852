import Main from "@pacvue/frame/layout/Main.vue"
export default {
  router: {
    path: "/Optimization",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/AutomationAI",
        component: () => import("@campaignAI/index.vue"),
        name: "Campaign AI",
        meta: {
          alias: "Opt_AI"
        }
      },
      {
        path: "/Optimization/AutomationAI/launchAIforCampaigns",
        component: () => import("@campaignAI/launchAI.vue"),
        name: "Launch AI for campaigns in batches",
        meta: {
          noBreadcrumb: false,
          alias: "Opt_AI"
        }
      },
      {
        path: "/Optimization/AutomationAI/launchAIforTags",
        component: () => import("@campaignAI/launchAI.vue"),
        name: "Launch AI for tags in batches",
        meta: {
          noBreadcrumb: false,
          alias: "Opt_AI"
        }
      }
    ]
  }
}
