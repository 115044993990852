import axios from "axios"
import { app } from "./frame.js"
import { pacvueToken } from "@pacvue/utils"
import { refreshToken } from "./api.js"
import { useCommonStore, useUserStore } from "@/store"
import { analyticsIdentify } from "./analytics/index.js"
import { logoutProcessing } from "../utils/logout.js"
import { DOMAIN_MAPPER_PROD } from "../constants/platform.js"

const { VITE_APP_BASEURL } = import.meta.env

const instance = axios.create({
  VITE_APP_BASEURL,
  timeout: 300000
})

// 是否正在刷新的标志
let isTokenRefreshing = false
// 存储请求的数组
let subscribersArr = []
// 请求 push 到数组中
const addSubscriber = (cb) => subscribersArr.push(cb)
// 用新 token 发起请求
const invokeSubscribersWithNewToken = (token) => subscribersArr.map((cb) => cb(token))

const isTokenExpired = (tokenExpirationTS) => {
  const currTS = new Date().getTime()
  return currTS >= tokenExpirationTS
}

const CancelToken = axios.CancelToken
window.$httpArray = []

// window.$pendingRequests = new Map() // Experimental 预留
// 请求时添加取消请求标识
function addPendingRequest(config) {
  const url = [config.method, config.url].join("&")
  if (window.$pendingRequests.has(url)) {
    let cancel = window.$pendingRequests.get(url)
    cancel()
    window.$pendingRequests.delete(url)
  }
  config.cancelToken = new CancelToken((cancel) => {
    window.$pendingRequests.set(url, cancel)
  })
}
// 请求完成后删除请求
function removePendingRequest(config) {
  const url = [config.method, config.url].join("&")
  if (window.$pendingRequests.has(url)) {
    window.$pendingRequests.delete(url)
  }
}

instance.interceptors.request.use(
  async function (config) {
    const { setPacvue_token, getTokens, getRefresh_token, getExpires_in, removePacvue_token, getPacvue_token } = pacvueToken
    const router = app.config.globalProperties.router
    const commonStore = useCommonStore()
    const userStore = useUserStore()
    const platform = config.headers?.productline || window.productline || localStorage.getItem("productline")
    config.url = config.url.replace(/{{platform}}/g, platform) // 进行平台替换
    if (config.url.indexOf("mock-s") !== -1) {
      config.baseURL = "/"
    }
    if (config.url.indexOf("amazon-chart") !== -1) {
      config.baseURL = "/"
    }
    if (config.url.indexOf("exists/list") !== -1) {
      return config
    }
    // 假如需要取消请求
    if (config.cancelToken) {
      if (config._cancel && typeof config._cancel === "function") {
        window.$httpArray.push(config._cancel)
      } else {
        // 创建 cancelToken，并在构造器的入参函数中将需要取消的请求插入顶层队列
        config.cancelToken = new CancelToken(function executor(c) {
          window.$httpArray.push(c)
        })
      }
    }
    // 需要取消请求
    if (config.cancelPendingRequest) {
      // addPendingRequest(config) // Experimental 预留
    }
    config.headers["productline"] = platform
    config.headers["currency"] = userStore.userSettings?.defaultCurrency
    config.headers["timeZone"] = userStore.userSettings?.timeZone
    config.headers["dateFormat"] = userStore.userSettings?.dateFormat
    config.headers["language"] = userStore.userSettings?.language || localStorage.getItem("locale")
    // config.headers['countries'] = JSON.parse(localStorage.getItem('country'))
    // 沙箱环境 / X-Frame-Options 为 DENY 的跨域 iframe: window.location.href 为空
    const url = window.location.href
    const searchParams = new URLSearchParams(window.location.search)
    /**
     * 各类 Share Link 页面链接
     * Custom Dashboard: /Share/CustomDashboard?shareId=3eb61233587dc4b71b5e44d484fc6d1c2d820fa4bd7d7d145523421498c9ad3c&isShareLink=true&dateFormat=0&currency=US
     * AMC Report [新]: /AMC/AMCReportChart?id=7712&isShare=true&shareId=51F2BE27&AMCT=B2274D5696F00B627975724F393F0945554C837EB823DE8A
     * AMC Report [老, 没有 shareId]: /AMC/AMCReportChart?id=6126&isShare=true&AMCT=B2274D5696F00B627975724F393F0945554C837EB823DE8A56BBA6B2C9845AC1DDE24686CE8197D7CA
     * Home: /Share/Index?shareId=6da79acaa6edbc113e313e8ad56470a1&Password=E79P&platformToken=1
     * Link Check: /Share/ShareCheckIndex?target=Index&shareId=6da79acaa6edbc113e313e8ad56470a1
     */
    // 判断地址栏是否包含 share 字段
    if (url && (searchParams.has("shareId") || searchParams.has("AMCT"))) {
      if (url.indexOf("CustomDashboard") !== -1) {
        // Custom Dashboard 的 Share Link
        config.headers["Authorization"] = ""
      } else if (searchParams.has("AMCT")) {
        // AMC Report 的 Share Link (新[DSP v3.7+]、老均兼容)
        const amcShareToken = searchParams.get("AMCT")
        config.headers.ShareToken = amcShareToken
      } else {
        // Home / Home Check Share Link
        let str = localStorage.getItem("shareToken")
        if (str) {
          config.headers["Authorization"] = "Bearer " + str.slice(2)
        }
      }
    } else {
      // 正常的追加 token
      let str = getTokens()
      if (str) {
        config.headers.common["Authorization"] = "Bearer " + str
      }
    }
    // 培训视频请求无需携带 token
    if (config.url.indexOf("training") != -1) {
      config.headers["Authorization"] = undefined
    }
    app.config.globalProperties.$eventHub.emit("requestHook", config)
    // 判断 token 是否过期
    let serverTokenExpire = getExpires_in() // 服务器返回的过期时间戳
    if (serverTokenExpire && isTokenExpired(serverTokenExpire) && window.location.href.indexOf("shareId") == -1) {
      if (window.location.href && window.location.href.indexOf("share") != -1) {
        return Promise.reject("token过期")
      }
      // 判断 token 是否已经刷新过
      if (!isTokenRefreshing) {
        isTokenRefreshing = true
        analyticsIdentify()
        // 请求刷新 token
        refreshToken()
          .then((res) => {
            if (res.data.data.isError) {
              subscribersArr = []
              logoutProcessing()
            } else {
              setPacvue_token(res)
              invokeSubscribersWithNewToken(res.data.data.accessToken)
            }
          })
          .catch(() => {
            subscribersArr = []
            logoutProcessing()
          })
      }
      let retry = new Promise((resolve) => {
        addSubscriber((newToken) => {
          instance.defaults.headers.common["Authorization"] = "Bearer " + newToken
          config.headers.Authorization = "Bearer " + newToken
          resolve(config)
        })
      })
      return retry
    } else {
      const str = getTokens()
      if (str) {
        config.headers.common["Authorization"] = "Bearer " + str
      }
      return config
    }
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  function (res) {
    // 需要取消请求
    if (res.config.cancelPendingRequest) {
      // removePendingRequest(res.config) // Experimental 预留
    }
    app.config.globalProperties.$eventHub.emit("responseHook", res)
    let link = res.config.url || ""
    if ((link.indexOf("export") != -1 || link.indexOf("download") != -1 || res.config.responseType === "blob") && res.headers["content-type"]?.indexOf("json") == -1) {
      return Promise.resolve(res)
    }
    if (link.indexOf("advertising/revert") != -1) {
      return Promise.resolve(res.data)
    }
    if (link.indexOf("task/revert") != -1) {
      return Promise.resolve(res.data)
    }
    if ((res.data == null || res.data.data == null) && link == "/Report/customTablePlan") {
      return Promise.resolve("[]")
    }
    if (res.data.code && res.data.code != 200 && res.data.code != 1 && res.data.code != "ok" && res.data.code != 201) {
      if (link == "/searchTerm/AddQuery/check") {
        return Promise.reject(res)
      }
      const url = window.location.href
      if (!url.split("Advertising/")[1] || (url.split("Advertising/")[1].indexOf("CreateCampaign") == -1 && url.split("Advertising/")[1].indexOf("CreateAdGroup") == -1)) {
        let subLink = link.slice(1).replace(/\//g, "-")
        res.data.msg = res.data.msg && res.data.msg.replaceAll("\n", "</br>")
        if (
          res.data.msg == "Part of keywords already exist" ||
          res.data.msg == "Part of negative keywords already exist" ||
          res.data.msg == "All negative keywords already exist" ||
          res.data.msg == "All keywords already exist"
        ) {
          return Promise.reject(res)
        }
        return Promise.reject(res)
      } else {
        return Promise.reject(res)
      }
    } else {
      if (res.config.method == "delete" && !res.config.fullData) {
        return Promise.resolve(res.data.code)
      } else {
        if (res.config.fullData) {
          return Promise.resolve(res)
        } else if (res.data.result) {
          return Promise.resolve(res.data.result || res.data.data || res.data)
        } else if (res.data.data === undefined) {
          // 假如 res.data.data 取不到数据, 则接口本身就是未包裹一层返回的, 直接将 res.data resolve 即可
          return Promise.resolve(res.data)
        } else {
          return Promise.resolve(res.data.data)
        }
      }
    }
  },
  (err) => {
    const productLine = window.productline || localStorage.getItem("productline")
    const router = app.config.globalProperties.router
    let status = 0,
      msg = ""
    if (err && err.response) {
      status = err.response.status
      msg = err.response.data.message
    }
    // 捕捉 token 过期时, 跳转到登录页
    if (status === 401) {
      // 若外部链接指定路径跳转, 但触发了401, 应在登录页给定重定向路径 (部分复用自动登出的 Rurl 逻辑)
      const params = new URLSearchParams(window.location.search)
      if (params.has("external_link")) {
        const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
        logoutProcessing({ Rurl: window.location.pathname.replace(`/${pathPrefix}`, "/").replace("//", "/") })
      } else {
        logoutProcessing()
      }
      return Promise.reject(err)
    }
    if (status == 403) {
      // PASS
    }
    if (msg === "") {
      msg = "Network Error"
    }
    // 此处代码注释是 commerce 需要自行处理
    // if (!axios.isCancel(err)) {
    return Promise.reject(err)
    // }
  }
)

export default instance
