import { PacvueMessageBox, PacvueMessage } from "@pacvue/element-plus"
import { useRoute, useRouter } from "vue-router"
import { dayjs } from "@pacvue/element-plus"
import { app } from "@pacvue/frame"
import { getUnAssignedCount } from "./api"
import commonJS from "@pacvue/utils"
const { goUrl } = commonJS
const productLine = window.localStorage.getItem("productline")
const { $commonJS } = app.config.globalProperties
const $router = useRouter()
const handleGotoBillingTag = () => {
  const { href } = $router.resolve({
    path: "/Campaign/BillingTag"
  })
  window.open(href)
}
const isopenDialog = async () => {
  const permissionType = productLine == "dsp" ? $commonJS.getPermission("DSP_BillingTag") == 0 : $commonJS.getPermission("BillingTag") == 0
  let endDate = dayjs().endOf("month").format("YYYY-MM-DD")
  let lastFiveDayArr = [endDate]
  for (let i = 1; i <= 4; i++) {
    let item = dayjs().endOf("month").add(`-${i}`, "d").format("YYYY-MM-DD")
    lastFiveDayArr.push(item)
  }
  let curdata = dayjs().startOf("day").format("YYYY-MM-DD")
  let flag = lastFiveDayArr.indexOf(curdata) != -1
  // console.log("ddd", endDate, curdata, lastFiveDayArr, flag, permissionType)

  if (!permissionType && flag) {
    try {
      const res = await getUnAssignedCount()
      return res != "0"
    } catch {
      return false
    }
  } else {
    return false
  }
}
export const isReminderUserToBillingTag = async (to, from, next) => {
  const signFlag = localStorage.getItem("signFlagBillingTag")
  localStorage.removeItem("signFlagBillingTag")
  if ((from.path.indexOf("/login") != -1 || from.path.indexOf("/Auth") != -1 || from.path === "/") && signFlag == "true" && !["/Campaign/BillingTag", "/Auth"].includes(to.path)) {
    const currentYearMonth = dayjs().format("YYYY-MM")
    // console.log("currentYearMonth", currentYearMonth)
    if (currentYearMonth == "2024-06") return
    let flag = await isopenDialog()
    // console.log("flag", flag)
    if (flag) {
      const html = `<div class="text-[#66666C]">
        <div class="flex items-center mb-3">
          <span class="mr-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-ea1ca400=""><path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Z" fill="#FF9F43" fill-opacity=".12" data-v-3eecf858=""></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 6h-2v8.571h2V6Zm0 11.286a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" fill="#FF9F43" data-v-3eecf858=""></path></svg>
          </span>
          <span class="text-[18px] leading-[22px] font-medium"> ${$t("key2915")}</span>
        </div>
        <div class="text-sm">
          ${$t("key2917")}
        </div>
      </div>`
      PacvueMessageBox.confirm(html, $t("key2916"), {
        confirmButtonText: $t("key2918"),
        showCancelButton: $t("budget56"),
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          goUrl({
            path: "/Campaign/BillingTag"
          })
        })
        .catch(() => {})
    }
  }
}
