import { defineStore } from "pinia"
import { insightTabList } from "./insight.config"
import actions from "./actions"
import { dayjs } from "@pacvue/element-plus"
import { getStrategeDateTimePos } from "./util"
var today = dayjs().format("YYYY/MM/DD")

const useWarRoomStore = defineStore("WarRoomStore", {
  state: () => {
    return {
      allLoading: true, //所有的loading
      curToday: today, //yyyy/MM/DD
      queryDateTime: undefined,
      dateTime: today,
      stateMode: "Hourly", //统计方式
      warRoomList: [],
      activeWarRoom: {}, //当前的激活warRoom
      strategyList: [],
      activeStrategy: { StrategyId: -1, StrategyName: $t("amskey3035"), isOverReview: true },
      currentHour: 0,
      showProIndexList: ["Impression", "Click", "CTR", "Spend", "CPC", "Sales", "Conversion", "SaleUnits", "CVR", "CPA", "ROAS", "ACOS"],
      trendData: {
        hourlTrendData: [],
        totalTrendData: [],
        total: {},
        loading: true
      },
      campagignLabelStore: {},
      selectedCampLabels: [],
      holidays: [],
      isHolidayLoaded: false,
      campaginIdLabelMap: {},
      campaignLabels: [
        {
          icon: "PacvueIconLowComp",
          unActiveIcon: "PacvueIconLowCompGray",
          id: "LowComp",
          tip: $t("amskey3056")
        },
        {
          icon: "PacvueIconLowClick",
          id: "LowClick",
          unActiveIcon: "PacvueIconLowClickGray",
          tip: $t("amskey3057")
        },
        {
          icon: "PacvueIconHighClick",
          unActiveIcon: "PacvueIconHighClickGray",
          id: "HighClick",
          tip: $t("amskey3058")
        },
        {
          icon: "PacvueIconBudgetShort",
          unActiveIcon: "PacvueIconBudgetShortGray",
          id: "BudgetShort",
          tip: $t("amskey3059")
        }
      ],
      feadTodayCache: {},
      strategyPerformances: [
        {
          label: $t("amskey3053"),
          value: "targetSales",
          code: "Sales"
        },
        {
          label: $t("key528"),
          value: "targetOrders",
          code: "Orders"
        },
        {
          label: $t("ai198"),
          value: "targetSaleUnits",
          code: "SaleUnits"
        },
        {
          label: $t("key732"),
          value: "targetSpend",
          code: "Spend"
        }
      ],
      defaultTabCode: "Analysis",
      defaultStrategyTabCode: "Campaign",
      defaultActiveStrategyId: 0,
      includeZero: undefined,
      insightTabList,
      strategyCacheConfig: {} //strategy 缓存配置
    }
  },
  getters: {
    strategyPerformancesCode(state) {
      return state.strategyPerformances.map((item) => item.code)
    },
    lastUpdateTime(state) {
      // var currentHour = state.currentHour
      // return state.dateTime + " " + `${currentHour < 10 ? "0" + currentHour : currentHour}:00`
      return state.currentHour
    },
    holidayMap(state) {
      var holidays = state.holidays ?? []
      return holidays.reduce((result, item) => {
        result[item.showDate] = {
          ...item
        }
        return result
      }, {})
    },
    dateDay() {
      var dateTime = this.dateTime
      return dayjs(dateTime, "YYYY/MM/DD").format("MM/DD")
    },
    dateTimePos(state) {
      let dateTimeVal = state.dateTime
      let activeStrategyInfo = state.activeStrategy
      let TargetTimeStart = activeStrategyInfo?.TargetTimeStart // "2024/05/08",
      let TargetTimeEnd = activeStrategyInfo?.TargetTimeEnd // "2024/05/08"
      return getStrategeDateTimePos({ TargetTimeStart, TargetTimeEnd, dateTimeVal })
    },
    realYesterday(state) {
      var curTodayVal = state.curToday
      var realYeserday = dayjs().subtract(1, "day").format("YYYY-MM-DD")
      if (curTodayVal) {
        realYeserday = dayjs(curTodayVal, "YYYY/MM/DD").subtract(1, "days").format("YYYY-MM-DD")
      }
      return realYeserday
    },
    campaginIdLabelSize(state) {
      var campaginIdLabelMap = state.campaginIdLabelMap || {}
      return Object.keys(campaginIdLabelMap)?.length || 0
    },
    selectedCampLabelsCampagins(state) {
      var campaginIdLabelMap = state.campaginIdLabelMap || {}
      var campaignIds = []
      state.selectedCampLabels.forEach((campaignLabel) => {
        var itemCampaignIds = campaginIdLabelMap[campaignLabel]
        campaignIds.push(...itemCampaignIds)
      })
      return campaignIds
    },
    todayTargetInfo(state) {
      const strategyItem = state.activeStrategy
      return this.getStrategyTodayTarget({ strategyItem })
    },
    enbleStrategyPerformancesCode(state) {
      var strategyList2 = state.strategyList ?? []
      let targetPerformances = state.strategyPerformances
      let activeStrategy = state.activeStrategy
      let StrategyId = activeStrategy.StrategyId
      if (StrategyId !== -1) {
        strategyList2 = [activeStrategy]
      }
      let realStrategyPerformanceList = []
      strategyList2.forEach((strategyItem) => {
        targetPerformances.forEach(({ code }) => {
          let proName = `target${code}`
          let proVal = strategyItem?.[proName] ?? null
          if (proVal && !realStrategyPerformanceList.includes(code)) {
            realStrategyPerformanceList.push(code)
          }
        })
      })
      return realStrategyPerformanceList
    }
  },
  actions: actions
})

export default useWarRoomStore
