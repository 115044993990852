import { InitApp } from "./frame.js"
import { setSettings } from "./api.js"
import routerRebuild from "./routerRebuild.js"
export default async function (router, navGuard) {
  if (window.location.href.indexOf("Language") !== -1) {
    // 获取当前页面的 URL
    const url = new URL(window.location.href)
    // 获取 URL 中的查询参数
    const searchParams = new URLSearchParams(url.search)
    // 删除特定的查询参数
    searchParams.delete("Language")

    window.location.href.replace("Language=", "")
    // 修改偏好语言
    if (sessionStorage.getItem("setting")) {
      let setting = JSON.parse(sessionStorage.getItem("setting"))
      setSettings(setting)
    }
    // 为了同步协调菜单接口需要使用请求头中的 Language 字段
    window.isUseHeader = true
    // 替换当前页面的 URL，不包含查询参数 Language
    setTimeout(() => {
      window.history.replaceState({}, "", url.origin + url.pathname + "?" + searchParams.toString())
    }, 5000)
  }

  InitApp.moduleRouter.forEach((item) => {
    router.addRoute(routerRebuild(item))
  })
  if (navGuard) {
    navGuard(router)
    return
  }
}
