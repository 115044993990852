// 该路由配置用作新UI架构的可插拔, 老UI架构的微前端嵌入路由配置位于入口文件 pages/[module-name].js

import Main from "@pacvue/frame/layout/Main.vue"

const hasScheduler = !["mercado"].includes(localStorage.getItem("productline"))

export default {
  router: {
    path: "/Optimization/Dayparting",
    component: Main,
    meta: { auth: true, id: -1 },
    children: hasScheduler
      ? [
          {
            path: "/Optimization/Dayparting/Index",
            redirect: "/Optimization/Dayparting"
          },
          {
            path: "/Optimization/Dayparting",
            component: () => import("@dayparting/index.vue"),
            name: "Dayparting Scheduler",
            meta: {
              alias: "Op_Dayparting"
            }
          },
          {
            path: "/Optimization/Dayparting/Setting",
            component: () => import("@dayparting/pages/DaypartingSetting.vue"),
            name: "Dayparting Scheduler Setting",
            meta: {
              alias: "Op_Dayparting"
            }
          },
          {
            path: "/Optimization/Dayparting/ApplyInfo",
            component: () => import("@dayparting/pages/ApplyInfo.vue"),
            name: "Dayparting Apply Detail",
            meta: {
              alias: "Op_Dayparting"
            }
          },
          {
            path: "/Optimization/Dayparting/SchedulerLogs",
            component: () => import("@dayparting/pages/SchedulerLogs.vue"),
            name: "Dayparting Scheduler Logs",
            meta: {
              alias: "Op_Dayparting"
            }
          }
        ]
      : []
  }
}
