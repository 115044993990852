import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Message",
        component: () => import("@Message/message.vue"),
        name: "Message"
      },
      {
        path: "/Download",
        component: () => import("@Message/download.vue"),
        name: "Download"
      },
      {
        path: "/AnomalyAlert",
        component: () => import("@Message/anomalyAlert/anomalyAlert.vue"),
        name: "Anomaly Alert"
      },
      {
        path: "/AnomalyAlert/ManageAlert",
        component: () => import("@Message/anomalyAlert/manageAlert.vue"),
        name: "Manage Alert"
      }
    ]
  }
}
