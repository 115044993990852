import { useUserStore, useAppStore, useCommonStore } from "@/store"

const excludeRoute = [
  "/oauth2/redirect",
  "/Guide",
  "/Binding",
  "/Account/MyAccount",
  "/login",
  "/login/loginwithsso",
  "loginoauth/redirect",
  "/oauth2/vendorredirect",
  "/Account/Setting",
  "/bind/Setting"
]

export default {
  routerInterceptors: async (to, from, next) => {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const commonStore = useCommonStore()
    try {
      if (!to.query.shareId) {
        // case1
        if (to.path?.lastIndexOf("oauth2") == -1 || to.path?.lastIndexOf("login") == -1) {
          const SettingState = localStorage.getItem(`${userStore.user.userId}_SettingState`)
          if (appStore.account?.length === 0 && !to.meta.noRefreshToken) {
            await appStore.accountActionAll()
            await appStore.accountAction()
            if (appStore.accountAll?.length == 0 && !excludeRoute.includes(to.path)) {
              // 未授权的
              // localStorage.setItem(userStore.user.userId + "_NoneAuthorized", true)
              next("/Guide")
            } else if (!excludeRoute.includes(to.path) && SettingState && SettingState != "1") {
              // 授权的但未设置完偏好
              next("/Guide")
            }
          } else if (appStore.account?.length) {
            // 已授权
            if (!excludeRoute.includes(to.path)) {
              if (SettingState && SettingState != "1") {
                // 未设置完偏好
                next("/Guide")
              }
            } else if (to.path == "/Account/Setting") {
              const isEsaySetting = localStorage.getItem(userStore?.user?.userId + "_esaySetting")
              if (!isEsaySetting) {
                next("/bind/Setting")
              }
            }
          }
        }
        // case2
        if (commonStore.curStore.profileList.length === 0 && !to.meta.noRefreshToken) {
          await commonStore.getProfileList(appStore.chooseAccounts)
        }
        // case3
        if (commonStore.curStore.allProfileList.length === 0 && !to.meta.noRefreshToken) {
          if (to.path.toLowerCase() == "/campaign/profile") {
            await commonStore.getAllProfileList({ advertiserIds: appStore.chooseAccounts })
          }
        }
        // case4
        const isSet = localStorage.getItem(userStore?.user?.userId + "_esaySetting")
        if (
          isSet != "true" &&
          !userStore?.userSettings?.dateFormat &&
          userStore?.userSettings?.dateFormat != 0 &&
          !excludeRoute.includes(to.path) &&
          to.path != "/Account/Setting" &&
          to.path != "/bind/Setting"
        ) {
          localStorage.setItem(userStore.user.userId + "_SettingState", 0)
          // localStorage.setItem(userStore.user.userId + "_BindProfile", false)
          next("/Guide")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
