import useFrameStore from "./frameStore"
import { setFavorites, delFavorites } from "./api"
import { useUserStore } from "@/store"

// 0 invisible
// 1 visible
// 2 edit

const permission = {
  mounted(el, binding, vnode, prevVnode) {
    if (!window.permissionObj) {
      return
    }
    if (window.permissionObj[binding.value] == 0) {
      // el.parentNode.remove(el)
      vnode.el.hidden = true
    }
    // 1的状态为显示不可编辑
    if (window.permissionObj[binding.value] == 1) {
      // 添加disable的class
      if (binding.modifiers.disable) {
        el.classList.add("disable")
      } else if (binding.modifiers.color) {
        el.classList.add("text-[#66666c]")
        el.classList.add("pointer-events-none")
      } else if (binding.modifiers.prevent) {
        el.classList.add("preventEvent")
      } else {
        //直接删除元素
        vnode.el.hidden = true
      }
    }
    if (window.permissionObj[binding.value] == 0) {
      //2就是有所有权限
    }
  }
}

//  <div v-permission.disable="'admin1'">显示但是 disable</div>
//  <div v-permission="'admin'">1</div>
//  <div v-permission="'admin1'">不显示权限 invisible</div>
//  <div v-permission="'admin2'">editable</div>

const addToolTip = function (a) {
  const useFrame = useFrameStore()
  let e = arguments[arguments.length - 1]
  if (e.target.scrollHeight > e.target.clientHeight || e.target.scrollWidth > e.target.clientWidth) {
    useFrame.SET_TOOLTIPSHOW({
      virRef: e.target,
      text: a.value
    })
    window.changeVisible(e.currentTarget)
  } else {
    useFrame.SET_TOOLTIPHIDE(false)
  }
}
const tooltips = {
  mounted(el, binding, vnode, prevVnode) {
    // console.log(el, binding.value);
    el.addEventListener("mouseover", addToolTip.bind(this, binding))
  },
  unmounted(el, binding, vnode, prevVnode) {}
}
let duration = 500 // 运动持续时间，单位为毫秒

function anim(elem, rect, targetRect) {
  const startPointX = rect.left // 起始点 X 坐标
  const startPointY = rect.top // 起始点 Y 坐标
  const endPointX = targetRect.x // 结束点 X 坐标
  const endPointY = targetRect.y // 结束点 Y 坐标
  let startTime = null

  function animate(timestamp) {
    if (!startTime) startTime = timestamp
    const elapsedTime = timestamp - startTime
    let progress = elapsedTime / duration
    if (progress > 1) progress = 1
    const currentX = startPointX + (endPointX - startPointX) * progress
    const currentY = startPointY + (endPointY - startPointY) * progress
    elem.style.left = currentX + "px"
    elem.style.top = currentY + "px"
    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }
  requestAnimationFrame(animate)
}

let debFlag = null
const collectFun = async (el, binding) => {
  event.stopPropagation()
  event.preventDefault()
  if (debFlag) return
  debFlag = setTimeout(() => {
    if (debFlag) {
      clearTimeout(debFlag)
      debFlag = null
    }
  }, 800)
  if (!el.handlerParams.isFavorite) {
    let ele = el.cloneNode(true)
    let rect = el.getBoundingClientRect()
    ele.style.pointerEvents = `none`
    ele.style.position = `absolute`
    ele.style.left = `${rect.left}px`
    ele.style.top = `${rect.top}px`
    ele.style.zIndex = `3000`
    let newEle = ele.querySelectorAll("path")[0]
    newEle.setAttribute(
      "d",
      "M21.717 8.782c-.086-.342-.344-.514-.688-.6l-5.509-.77-2.496-4.963c-.258-.599-1.29-.599-1.549 0L8.98 7.327l-5.422.856c-.345 0-.603.257-.775.599-.086.342 0 .684.258.856L7 13.488l-.947 5.477a.811.811 0 0 0 .344.856c.258.171.602.257.947.086l4.906-2.568 4.906 2.568h.43c.172 0 .344-.086.517-.171a.812.812 0 0 0 .344-.856l-.947-5.477 3.96-3.851a.688.688 0 0 0 .257-.77Z"
    )
    newEle.setAttribute("fill", "var(--root-menu-highlight)")
    // keep classes starting with "el-" only
    const classes = ele.getAttribute("class").split(" ")
    const newClassName = classes.filter((cls) => cls.startsWith("el-")).join(" ")
    ele.setAttribute("class", newClassName)
    let targetRect = document.getElementById("favorite-entry").getBoundingClientRect()
    document.body.appendChild(ele)
    anim(ele, rect, targetRect)
    setTimeout(() => {
      ele.remove()
    }, duration)
    const useStore = useUserStore()
    if (sessionStorage.getItem("setting")) {
      const setting = JSON.parse(sessionStorage.getItem("setting"))
      if (!setting.userFavourite) {
        setting.userFavourite = []
      }
      setting.userFavourite.push(el.handlerParams.menu.menuId)
      useStore.SET_USERSETTINGS(setting)
    }
    await setFavorites({ menuId: el.handlerParams.menu.menuId })
    sessionStorage.removeItem("setting")
    await useStore.userSetting()
  } else {
    const useStore = useUserStore()
    if (sessionStorage.getItem("setting")) {
      const setting = JSON.parse(sessionStorage.getItem("setting"))
      setting.userFavourite = setting.userFavourite.filter((items) => {
        if (items !== el.handlerParams.menu.menuId) return items
      })
      useStore.SET_USERSETTINGS(setting)
    }
    await delFavorites({ menuId: el.handlerParams.menu.menuId })
    sessionStorage.removeItem("setting")
    await useStore.userSetting()
  }
}

const collect = {
  mounted(el, binding) {
    el.handlerParams = binding.value
    el.handler = collectFun.bind(this, el)
    el.addEventListener("click", el.handler)
  },
  updated(el, binding) {
    el.handlerParams = binding.value
  },
  unmounted(el, binding, vnode, prevVnode) {
    el.removeEventListener("click", el.handler)
  }
}

export { permission, tooltips, collect }
