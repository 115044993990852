import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/Optimization/BidExplorer",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/BidExplorer",
        component: () => import("@bidexplorerv3/index.vue"),
        name: "Bidexplorer",
        meta: {
          isShowTime: false,
          showCompareCheck: true,
          breadcrumb: [
            {
              name: "Bid Explorer"
            }
          ]
        }
      },
      {
        path: "/Optimization/Bidexplorer/addbid",
        component: () => import("@bidexplorerv3/AddBid/addbid.vue"),
        name: "BidexplorerAdd",
        meta: {
          isShowTime: false,
          showCompareCheck: true,
          breadcrumb: [
            {
              name: "Add Bid Explorer"
            }
          ]
        }
      },
      {
        path: "/Optimization/Bidexplorer/overview",
        component: () => import("@bidexplorerv3/overview/overview.vue"),
        name: "BidexplorerOverview",
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          breadcrumb: [
            {
              name: "overview"
            }
          ]
        }
      },
      {
        path: "/Optimization/Bidexplorer/overviewcpc",
        component: () => import("@bidexplorerv3/overview/overviewcpc.vue"),
        name: "BidexplorerOverviewCpc",
        meta: {
          isShowTime: false,
          showCompareCheck: false,
          breadcrumb: [
            {
              name: "overview"
            }
          ]
        }
      }
    ]
  }
}
