import { useAppStore, useAmazonStore, usePDcenterStore } from "@/store"
import { getUserPermission } from "@/api/ProductCenter/productDashboard"
// const INSTANCE = getCurrentInstance()
// const { $eventHub, proxy } = INSTANCE.appContext.config.globalProperties
import { isEmpty } from "lodash-es"
export default {
  loginInterceptors: (router) => {
    return new Promise((res) => {
      const PDcenterStore = usePDcenterStore()
      PDcenterStore.SET_CHANNELTYPE(undefined)
      sessionStorage.removeItem("channelType")

      router.push("/Home")
      res(true)
    })
  },
  routerInterceptors: (to, form) => {
    return new Promise((res) => {
      const appStore = useAppStore()
      const amazonStore = useAmazonStore()
      const PDcenterStore = usePDcenterStore()
      if (!to.query.shareId && !isEmpty(appStore.chooseAccounts) && (PDcenterStore.channelType === undefined || PDcenterStore.channelType === null)) {
        getUserPermission({
          advertiserIds: appStore.chooseAccounts,
          toMarket: "US"
        }).then((ress) => {
          console.log("lht---permission--->", res)
          const { type } = ress

          PDcenterStore.SET_CHANNELTYPE(type)
          if (amazonStore.productDashboardChannel === undefined) {
            if (type === 1 || type === 2) {
              amazonStore.productDashboardChannel = 1
            } else if (type === 3) {
              amazonStore.productDashboardChannel = 3
            }
          }

          res(true)
          // channelType
        })
      } else {
        if (amazonStore.productDashboardChannel === undefined) {
          if (PDcenterStore.channelType === 1 || PDcenterStore.channelType === 2) {
            amazonStore.productDashboardChannel = 1
          } else if (PDcenterStore.channelType === 3) {
            amazonStore.productDashboardChannel = 3
          }
        }

        res(true)
      }
    })
  }
}
