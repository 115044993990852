import Main from "@pacvue/frame/layout/Main.vue"
import config from "@~/config.js"

export default {
  routerInterceptors: config.isNewRouterAsync
    ? [
        () => ({
          syncSeq: 1,
          async then(onFulfill, onReject) {
            const signFlag = localStorage.getItem("SignFlag")
            if (signFlag == "true") {
              localStorage.setItem("signFlagBillingTag", "true")
            }
            onFulfill(true)
          }
        })
      ]
    : async () => {
        const signFlag = localStorage.getItem("SignFlag")
        if (signFlag == "true") {
          localStorage.setItem("signFlagBillingTag", "true")
        }
      },
  router: {
    //BillingTag
    path: "/Campaign/BillingTag",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Campaign/BillingTag",
        name: "BillingTag",
        component: () => import("@billingTag/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "BillingTag"
        }
      }
    ]
  }
}
